import React from 'react'
import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'

import styles from '../../styles.module.css'

export default function EtcErrorView() {
  const { t } = useTranslation()

  return (
    <div className={styles['error-etc-wrapper']}>
      <div className={styles['error-etc-div-text']}>
        <Typography variant='subtitle1'>{t('etc_fail_subitle')}</Typography>
        <Typography
          variant='body2'
          style={{ marginTop: '8px', color: '#979797' }}
        >
          {t('etc_fail_body')}
        </Typography>
      </div>
      <div className={styles['error-etc-div-report']}>
        <Typography
          variant='caption'
          style={{
            whiteSpace: 'pre-line',
            marginRight: '8px',
            color: '#979797'
          }}
        >
          {t('etc_fail_report_body')}
        </Typography>
        <Typography variant='caption'>
          <Link href='mailto:mojitok@platfarm.net'>
            {t('etc_fail_report_button')}
          </Link>
        </Typography>
      </div>
    </div>
  )
}
