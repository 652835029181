import MJTAuth from "../../Core/MJTAuth"
import MJTContext from "../../Core/MJTContext"

export interface InstantAuthUrlOption {
    page?: string,
    packId?: string,
    payment?: string,
    lang?: string,
}

export async function getInstantAuthUrl(options:InstantAuthUrlOption): Promise<string> {
    const accessToken = await MJTAuth.getInstance().getValidAccessToken()
    const baseUrl = MJTContext.getInstance().baseUrl
    const instantAuthUrl = `${baseUrl}/store?token=${accessToken}&page=${options.page}&id=${options.packId}&payment=${options.payment}&lang=${options.lang}`
    return instantAuthUrl
}
