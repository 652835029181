/**
 * SDK 데모 및 소개 페이지
 */
 import React,{useState} from 'react';
 import { makeStyles } from '@material-ui/core';
 
 import Header from '../components/SDKDemo/Header';
 import Home from '../components/SDKDemo/Home'
 import '../styles/SDKDemo.css';
 import FooterBottom from '../components/SDKDemo/FooterBottom';
 import FooterSmallScreen from '../components/SDKDemo/FooterSmallScreen';
 
 
 const useStyles = makeStyles((theme) => ({
   wallPaper:{
     background: 'linear-gradient(#D6EBFF ,#EDD6FF )',
     minHeight:'100vh',
     minWidth:'100vw',
     height:'100%',
     display:'flex',
     flexDirection:'column',
     justifyContent:'space-between',
     overflow:'auto',
     [theme.breakpoints.down('xs')]: {
       overflowX:'hidden',
    },
   },
   footerWrapper:{
     display:'block',
     [theme.breakpoints.down('xs')]: {
       display:'none'
     },
   },
   footerSmallScreenWrapper:{
     display:'none',
     [theme.breakpoints.down('xs')]: {
       display:'block',
     },
   }
 }));
 
 const SDKDemo = (props) => {
   const classes = useStyles();
   const [tab,setTab]= useState('main')
   return (
     <div className={classes.wallPaper}>
         <Header tab={tab} setTab={setTab}/>
         <Home tab={tab} setTab={setTab}/>
         <div className={classes.footerWrapper}>
           <FooterBottom />
         </div>
         <div className={classes.footerSmallScreenWrapper}>
           <FooterSmallScreen />
         </div>
     </div >
   );
 };
 
 export default SDKDemo;