import React from 'react'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

export interface TabIconInPackProps {
  url: string
  isSelected: boolean
}

export default function TabIconInPack({ url, isSelected }: TabIconInPackProps) {
  const className = isSelected
    ? 'tab-icon-in-pack-selected'
    : 'tab-icon-in-pack'
  return (
    <div className={styles['tab-icon-in-pack-wrapper']}>
      <img src={url} alt='pack-icon' className={styles[className]} />
    </div>
  )
}
