import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";

import clayIphone7 from "../../img/clay_iphone007.new.png";
import { disableScroll, enableScroll } from '../../utils/scrollEvent';
import { PHONE_VIEW_HEIGHT, PHONE_VIEW_WIDTH } from '../../constantValue';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  phoneWrapper: {
    pointerEvents:'none',

  },
  blurEffect:{  
    filter:'blur(5px) brightness(0.76) drop-shadow(-16px 8px 20px gray)',
    [theme.breakpoints.down('xs')]: {
      filter:'none'
    },
  },
  phoneScreen: {
    position: 'relative',
    zIndex: -50,
    width:PHONE_VIEW_WIDTH+3,
    height:PHONE_VIEW_HEIGHT,
  },
  phoneScreenContentWrapper: {
    minWidth: 'unset !important',
    minHeight: 'unset !important',
  },
  phoneImage: {
    width: 611,
    height:917,
    position: 'absolute',
    zIndex: 10000,
    left: -193,
    top: -124,
    pointerEvents: 'none'
  },
}));

function PhoneView(props) {
  const classes = useStyles();
  const handleClick=()=>{
    if(props.enabled===false){
      props.changePhoneView()
    }
  }
  const handleMouseOver =()=>{
    if(!isMobile && props.enabled){
      disableScroll()
      props.setPointerUpDownVisible(false)
    }
  }
  const handleMouseLeave = ()=>{
    if(props.enabled){
      enableScroll()
      props.setPointerUpDownVisible(true)
    }
  }

  return (<div
    className={classes.phoneWrapper +' '+ (!props.enabled?classes.blurEffect:'')}
  >

    <div
      className={classes.phoneScreen}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      style={{
        cursor:props.enabled?'auto':'pointer',
        pointerEvents: props.enabled
          ? 'none'
          : 'auto'
      }}
    >
      <div      
        style={{
          pointerEvents: props.enabled
            ? 'auto'
            : 'none'
        }}
        className={classes.phoneScreenContentWrapper}>
        <img
          src={`${clayIphone7}`}
          alt="iphone-img"
          className={classes.phoneImage}
        />
        {props.children}
      </div>
    </div>
  </div>);
}
export default React.memo(PhoneView)
