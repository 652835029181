import React, { Component } from 'react';
import { Table, Spin, Icon, message, Button, Radio } from 'antd';
import emojiRegex from 'emoji-regex';
import InputTextView  from '../components/InputTextView';
import EmojiListView from '../components/EmojiListView';
import StickerListView from '../components/StickerListView';
import SelectView from '../components/SelectView';
import _ from 'lodash';
import { parse } from 'json2csv';

import '../styles/SearchEmoji.css';

const changeWordMap = new Map([
  ['byebye', 'bye'], ['ambiguous', 'hmmm'], ['silent', 'silence'], ['cool', 'tada'],
  ['sleepy', 'dozing'], ['hang out / friends', 'friend'], ['Me, Raise Hand', '손들기'],
  ['Hurry Up', 'timer']
]);


class TextMatchedEmojiView extends Component {
  constructor(props){
    super(props);
    const exampleTableColumn = [
      {
        title: 'Unigrams & Bigrams',
        dataIndex: 'token',
        key: 'token',
        width: 180,
        render : (text, row)=>{           
          return {
            props: {style: this.isEmoji(text) ? { background:'#fcfcfc', fontSize:30} : { background:'#fcfcfc'}},
            children: _.isString(row.emojis[0]) || this.isEmoji(text) || text === 'Deleted Stickers'?
              <div>{text}</div> :
              <Button size="small" onClick={() => this.moveKeytokenTBC(text, row, true)}>{text}</Button>
          }
        }
      },
      {
        title: 'Matched Emojis',
        dataIndex: 'emojis',
        key: 'emojis',
        width: 250,
        render:(emojiList, row, index)=> {
          return {
          	props: {
              style: { background:'#fcfcfc'},
              colSpan: _.isString(row.emojis[0]) || this.isEmoji(row.token) || row.token === 'Deleted Stickers' ? 3 : 1
            },
          	children:
            !_.isString(emojiList[0])  ?
            <EmojiListView
              emojiList={emojiList || []}
              rowData={row}
              emojiClickFunc={this.moveEmoji}
              emojiType="emojis"
              selectedEmojiIndex={this.state.selectedEmojiIndex}
            />
            :
            <div>
              {
                emojiList.map((stickerUrl, idx) => {
                  stickerUrl = stickerUrl.replace('http://', 'https://');
                  return(
                    <StickerListView imageUrl={stickerUrl} key = {idx} imgWidth ={80}/>
                  )
                })
              }
            </div>
            /*
            <SelectedStickerListView
              stickerList ={emojiList}
              stickerClickFunc={this.moveSticker}
              imgWidth={50}
              stickerType={row.token ==='Deleted Stickers' ? 'deletedStickers' : 'stickers'}
              rowData={row}
              rowIndex={index}
            />
          */
          };
        }
      },
      {
        title: 'Deleted Emojis',
        dataIndex: 'deleteEmojis',
        key: 'deleteEmojis',
        render: (emojiList, row, index) => {
          return {
            props: {
              style: { background:'#fcfcfc'},
              colSpan: _.isString(row.emojis[0]) || this.isEmoji(row.token) || row.token === 'Deleted Stickers' ? 0 : 1
            },
            children:
              <EmojiListView
                emojiList={emojiList || []}
                rowData={row}
                emojiClickFunc={this.moveEmoji}
                emojiType="deleteEmojis"
                selectedEmojiIndex={this.state.selectedEmojiIndex}
              />
          }
        }
      },
      {
        title: 'keytokens TBC',
        dataIndex: 'TBCToken',
        key: 'TBCToken',
        width: 180,
        render : (text, row)=>{
          return {
            props: {style: { background:'#fcfcfc'}, colSpan: _.isString(row.emojis[0]) || this.isEmoji(row.token) || row.token === 'Deleted Stickers' ? 0 : 1},
            children: text ? <Button size="small" onClick={() => this.moveKeytokenTBC(text, row, false)}>{text}</Button> : null
          }
        }
      }
    ];

    this.state = {
      searchResultEmojis:[],
      exampleTableColumn:exampleTableColumn,
      defaultValue: undefined, // 이모지 검색 시 입력할 텍스트의 기본 값
      isLoading: false, //spin 로딩 여부
      sentence: '',
      language: 'en',
      e2sData:{}, //emoji to stickers api data
      selectedEmojiIndex: 0 //선택된 이모지 index
    };
  }

  componentDidMount(){
    const SERVER_URL = process.env.REACT_APP_GALAXY_SERVER_URL;
    const API_VERSION = '/0.1';
    const API_TYPE = '/emoji2stickers';

    fetch(`${SERVER_URL + API_VERSION + API_TYPE }`, {
      headers: {
        "mojitok-token":process.env.REACT_APP_GALAXY_MOJITOK_TOKEN
      }
    })
    .then(res => res.json())
    .then(resJson => {
      this.setState({e2sData : resJson.data})
    })
    .catch(err => console.log(err))

  }

  render(){
    const { searchResultEmojis, defaultValue, isLoading, exampleTableColumn } = this.state;

    return(
      <div className="searchemoji-wapper">
        <h1>Input Text</h1>
        <Radio.Group onChange={(e)=> this.setState({language: e.target.value})} value={this.state.language} style={{marginBottom:20}}>
          <Radio value="en">English</Radio>
          <Radio value="ko">Korean</Radio>
          <Radio value="es">Spanish</Radio>
          <Radio value="cn">Chinese</Radio>
          <Radio value="ja">Japanese</Radio>
          <Radio value="fr">French</Radio>
        </Radio.Group>
        <Spin spinning={isLoading} indicator={<Icon type="loading" style={{ fontSize: 50, color: '#8800ff'}} spin />}>
          <InputTextView
            onClickBtnFunc={this.handleSearchEmojis}
            placeholder="enter text"
            defaultValue={defaultValue}
            btnImg={require('../img/icon_search_btn.svg')}
          />
          {/*이모지로 스티커 검색하기 */}
          <SelectView data={this.state.e2sData} onSearchSticker={ response => this.setState({searchResultEmojis: response })}/>
          {/* 입력 결과 상태 */}
          <h1 style={{marginTop:30}}>Results</h1>
          <Table
            dataSource={searchResultEmojis}
            columns={exampleTableColumn}
            bordered={true}
            pagination={false}
            scroll={{x:true, y: true}}
            size="default"
            rowClassName={(record, rowIndex) =>{
              if(record.token.includes(' ') && record.emojis.length > 0 && record.token !=='Deleted Stickers'){
                return "emphasize-background-table"
              }
            }}
          />
          <Button onClick={() => this.downloadTable(searchResultEmojis)} style={{margin:'40px 0px'}} type="primary">REPORT</Button>
        </Spin>
      </div>
    );
  }

  /* 텍스트를 입력하면 이모지 리스트를 반환하는 함수 */
  handleSearchEmojis = async (inputSentence) => {
    if(inputSentence) {

      await this.setState({isLoading : true}); //0. 로딩중 표시 on

      const keyword = Array.from(changeWordMap.keys()).find(key => key.toLowerCase().includes(inputSentence.toLowerCase()));

      const sentence = keyword ? changeWordMap.get(keyword) : inputSentence;
      
      const lang = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(sentence) ? 'ko': this.state.language;

      //1. token to emoji api call
      const SERVER_URL = process.env.REACT_APP_TEXT_TO_EMOJI_URL;
      const API_TYPE = 'text2emoji';
      
      const postData = {
        "texts" : [sentence],
        "lang" : lang
      };

      fetch(`${SERVER_URL}/${API_TYPE}?isLastBigram=false&returnType=str&schemaVersion=0.2`,  {
          method: 'POST',
          headers: {
            "mojitok-token": process.env.REACT_APP_TEXT_TO_EMOJI_TOKEN,
            "Content-Type" : "application/json"
          },
          body : JSON.stringify(postData),
          
      })
      .then(res => res.json())
      .then(resJson => {
        let result = [];
        if(resJson.grams.length > 0){
          //현재 데모페이지에서는 한 문장으로만 보냄(배열의 첫번째 값으로만)
          resJson.grams[0].reduce(async (promise, gramObj, idx) => {

            await promise;
              
            if(gramObj.ec.length > 0){
              const changeECFormat = gramObj.ec.map(ec =>({icon : ec}))
              result.push(changeFormat(idx, gramObj.gram, changeECFormat));
              const newData = await this.getEmojis2Stickers(changeECFormat)
              result.push(...newData);
            }

          }, Promise.resolve())
          .then(() => {
            //3. 결과 데이터 테이블에 추가 및 로딩중 표시 off
              this.setState({searchResultEmojis: result, sentence, isLoading: false })
          })
        }
      })
      .catch(err => console.log(err))

    }else{
      message.warning('Please Type your message 🙏');
    }
  }

  /* emoji to stickerUrl */
  getEmojis2Stickers = (emojis) => {
    return new Promise((resolve, reject) => {
      const result = emojis.map( emoji => {
        const e2sData = this.state.e2sData[emoji.icon] || [];
        if(e2sData.length === 0){
          message.warning('해당 이모지에 매칭된 스티커가 존재하지 않습니다.')
        }
        
        const stickerUrls = e2sData.slice(0, 6).map((stickers, idx) => stickers.p); //view에서 일단 6개만 보여줌
        return {token : emoji.icon, emojis : stickerUrls, stickerInfo : e2sData.slice(0, 6) }
      });
      resolve(result);
    });

  }

  /* 이모지인지 텍스트인지 확인하는 함수*/
  isEmoji = (str) => {
    const regex = emojiRegex();

    const isEmoji = _.isArray(regex.exec(str)) ? true : false;
    return isEmoji;
  }

  /* emoji, delete emoji -> delete emoji, emoji로 이동 시키는 함수 */
  moveEmoji = async (emojiType, no, token, emoji) =>{
    const { searchResultEmojis } = this.state;

    const currentRow = _.find(searchResultEmojis, obj => {return obj.token === token && obj.no === no});

    const nextEmojis = emojiType === 'emojis' ?
        emojiFilter(currentRow.emojis, false, emoji) :
        _.concat(currentRow.emojis, emojiFilter(currentRow.deleteEmojis, true, emoji));

    const nextDeleteEmojis = emojiType === 'emojis' ?
        _.concat(currentRow.deleteEmojis, emojiFilter(currentRow.emojis, true, emoji)):
        emojiFilter(currentRow.deleteEmojis, false, emoji);

    await this.setState({
      searchResultEmojis:
        searchResultEmojis.map(
          row => row === currentRow ?
           {...row, emojis: nextEmojis, deleteEmojis:nextDeleteEmojis}
           : row
        )
    });

  }

  /* 이모지에 매칭 된 스티커 중 제외할 스티커 이동시키는 함수
   * stickerType === 'stickers' ? 'matched Stickers의 선택된 스티커를 Deleted Stickiers 로 이동' : 'Deleted Stickers의 선택된 스티커를 matchedStickers로 이동'
   */
  moveSticker = async (stickerType, currentRow, selectedSticker, stickerIndex) =>{
    const { searchResultEmojis } = this.state;

    let matchedStickersRow = [];
    let deletedStickersRow = [];

    let newRowData = searchResultEmojis;

    if(stickerType === 'stickers'){
      //matchedStickers => deletedStickers로 스티커 이동 시
      matchedStickersRow = {
        ...currentRow,
        emojis : currentRow.emojis.filter(sticker => sticker!== selectedSticker),
        stickerInfo : currentRow.stickerInfo.filter(info => info.images.w300.url!== selectedSticker)
      }

      deletedStickersRow = _.find(searchResultEmojis, (obj, idx) => {return searchResultEmojis[idx-1] === currentRow});

      deletedStickersRow = {
        ...deletedStickersRow,
        emojis : deletedStickersRow.emojis.concat(selectedSticker),
        stickerInfo : deletedStickersRow.stickerInfo.concat(currentRow.stickerInfo.filter(info => info.images.w300.url === selectedSticker))
      }

      newRowData[stickerIndex] = matchedStickersRow;
      newRowData[stickerIndex+1] = deletedStickersRow;

    }else{
      //deletedStickers => matchedStickers로 스티커 이동 시
      matchedStickersRow = _.find(searchResultEmojis, (obj, idx) => {return searchResultEmojis[idx+1] === currentRow});
      deletedStickersRow = {
        ...currentRow,
        emojis : currentRow.emojis.filter(sticker => sticker!== selectedSticker),
        stickerInfo : currentRow.stickerInfo.filter(info => info.images.w300.url!== selectedSticker)
      }

      matchedStickersRow = {
        ...matchedStickersRow,
        emojis : matchedStickersRow.emojis.concat(selectedSticker),
        stickerInfo : matchedStickersRow.stickerInfo.concat(currentRow.stickerInfo.filter(info => info.images.w300.url === selectedSticker))
      }

      newRowData[stickerIndex-1] = matchedStickersRow;
      newRowData[stickerIndex] = deletedStickersRow;

    }

    await this.setState({
      searchResultEmojis:newRowData
    });

  }

/* results table을 csv 형태로 다운로드 하는 함수 */
  downloadTable = (data) => {
    const fields = ['sentence', 'Unigrams & Bigrams', 'Matched Emojis', 'Deleted Emojis', 'keytokens TBC'];
    const opts = { fields/*, unwind:'Matched Emojis', unwindBlank: true */};

    // const json = data.map(row => row.no || row.no === 0 ? changeCSVFormat(row) : null); //이모지와 스티커만 보이는 경우 일단 csv 파일에 저장 안함.
    const json = data.map(row => changeCSVFormat(row));

    //맨처음에 문장 넣기
    json.unshift({sentence : this.state.sentence});

    try {
      const csv = parse(json, opts);

      /* 버튼 클릭 시 파일 다운로드 */
      var a = document.createElement('a');
      var blob = new Blob(["\ufeff"+csv], {'type':'text/csv'});
      a.href = window.URL.createObjectURL(blob);
      a.download = new Date();
      a.click();

    } catch(error) {
      console.log(error);
      message.error('Sorry, An error occurred while downloading.')
    }
  }

/**
 * @summary move keytokens TBC --to be confirmed--
 * @param {string} token - Unigrams & Bigrams
 * @param {object} row - Unigrams & Bigrams를 선택한 현재 row
 * @param {boolean} isTBC - keytokens TBC로 이동할 지 여부 true면 TBC column에 추가, false면 TBC column에서 삭제
 */
  moveKeytokenTBC = async (token, row, isTBC) => {
    const { searchResultEmojis } = this.state;

    const newTableData = JSON.parse(JSON.stringify(searchResultEmojis)); // 참조 없이 복사. state 값 변경되지 않도록 하기 위해

    await newTableData.map((element) => _.isEqual(element, row) ? isTBC ? _.assign(element, {TBCToken : token}) : _.unset(element, 'TBCToken' ) : element);
    await this.setState({searchResultEmojis : newTableData});
  }

}
export default TextMatchedEmojiView;

/* csv 파일 포맷으로 변경 */
function changeCSVFormat(row) {
  return {
    'Unigrams & Bigrams' : row.token,
    'Matched Emojis' : changeCSVStickerFormat(row),
    'Deleted Emojis' : row.deleteEmojis ? row.deleteEmojis.map(emoji => emoji.icon).toString().replace(/,/g,'') :null,
    'keytokens TBC' : row.TBCToken ? row.TBCToken : null
  }
}

function changeCSVStickerFormat(row){
  if(row.stickerInfo){
    return row.stickerInfo.map(info => info.id).toString()
  }else{
    return row.emojis.map(emoji => emoji.icon).toString().replace(/,/g,'')
  }
}

function changeFormat( no, word, emojis){
  return {
    no:no,
    token:word,
    emojis:emojis,
    deleteEmojis:[]
  }
}

function emojiFilter(targetArray, isEqual, selectedEmoji){
  return _.filter(targetArray, emoji => isEqual ? emoji === selectedEmoji : emoji !== selectedEmoji );
}
