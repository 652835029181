import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'inherit',
    },
    body: {
        margin: 'auto',
        textAlign: 'center'
    },
    imgCreator : {
        ...theme.customTypography.body4,
        color: theme.greyscaleColorTheme.grey2.main,
        marginTop: '8px'
    },
    title : {
        color: theme.greyscaleColorTheme.darkGrey.main,
        marginTop: '32px'
    },
    subtitle : {
        ...theme.customTypography.body3,
        color: theme.greyscaleColorTheme.grey2.main,
        marginTop: '8px'
    }

}))

function NoSearchResult() {

    const classes = useStyles()
    const { t } = useTranslation('store');

    return (
        <div className={classes.root}>
            <div className={classes.body}>
                <img src={require('../../img/ghost-rabbit.svg')} alt={"No Search Result"} />
                <Typography className={classes.imgCreator}>© kongg</Typography>
                <Typography className={classes.title} variant='body2'>{t('searching_no_result_title')}</Typography>
                <Typography className={classes.subtitle}>{t('searching_no_result_dsc')}</Typography>
            </div >
        </div >
    )
}
export default NoSearchResult