/**이모지 검색 or 찾기 UI */
import React from 'react';
import {  Select } from 'antd';

const { Option } = Select;

const SelectView = props => {
    const { data, onSearchSticker } = props;

    const findStickersByEmoji = (emoji) => {
        const response =  [{
            token: emoji,
            emojis: data[emoji].slice(0, 6).map(sticker => sticker.p),
            stickerInfo: data[emoji]
        }]

        onSearchSticker(response);
    }

    return(
        <div style={{margin: '20px 0px'}}>
            <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Select a person"
                optionFilterProp="children"
                onChange={emoji => findStickersByEmoji(emoji)}
                onSearch={emoji => findStickersByEmoji(emoji)}
                filterOption={(input, option) => option.key === input}
            >
                {
                    Object.keys(data).map(emoji => 
                        <Option value={emoji} key={emoji}>{emoji}</Option>
                    )
                }
            </Select>
        </div>
    )
};

export default SelectView;