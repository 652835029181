let isDisableScroll=false
export function disableScroll() {
    if(isDisableScroll){
        return
    }
    document.body.classList.add('stop-scrolling')
    isDisableScroll=true
}

export function enableScroll() {
    isDisableScroll=false
    document.body.classList.remove('stop-scrolling')
}