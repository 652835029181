import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Tags from './Tags/Tags';
import FindTagSticker from './Tags/FindTagSticker';


class Tag extends Component {
  render(){
    return (
      <div>
        <Switch>
          <Redirect exact from="/demo/taglist" to="/demo/taglist/tag"/>
          <Route path="/demo/taglist/tag" component={Tags}/>
          <Route path="/demo/taglist/findtagsticker" component={FindTagSticker}/>
        </Switch>
      </div>
    );
  }
}

export default Tag;
