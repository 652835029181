import React, { useState } from 'react'

import MJTSticker from '../../Core/model/outter/MJTSticker'
import MJTAnalytics  from '../../Core/MJTAnalytics';
import { EventType, SourceType } from '../../Core/model/inner/MJTAnalyticsEvent';
import { MJTOnStickerChoice, MJTViewType, MJTStickerInfo } from '../../types'
import { StickerPackIcon } from './CustomIcons'
import MJTRecentStickers from '../../Core/MJTRecentStickers'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

export interface StickersViewProps {
  query?: string
  viewType: MJTViewType
  stickers: MJTSticker[]
  packId?: string
  onStickerChoice: MJTOnStickerChoice
}

export default function StickersView(props: StickersViewProps) {
  const { query, viewType, stickers, packId, onStickerChoice } = props

  const [imgLoadErrorState, setImgLoadErrorState] = useState<Array<string>>([]);
  return (
    // TODO: (임시대응) ownership sync 시 새 팩이 추가되는 경우 에러 고치기.
    <div className={styles['sticker-view-wrapper']}>
      {stickers ? (stickers.map((sticker) => {
        // TODO: fix ownership sync issue
        if (!sticker.images) return (<div
          className='hidden'
        />)

        const stickerUrl = sticker.images.find((img) => img.spec === 'w300')?.url
        return (
          imgLoadErrorState.includes(sticker.id) ? (
            <div
              className={styles['img-sticker-fallback-wrapper']}
            >
              <StickerPackIcon />
            </div>
          ) : (
            <img
              src={stickerUrl}
              key={sticker.id}
              className={styles['img-sticker']}
              onLoad={ async () => {
                await MJTAnalytics.getInstance().addEvent(viewType, 'VIEW', [sticker.id])
              }}
              onClick={ async () => {
                await MJTAnalytics.getInstance().addEvent(viewType, 'CHOOSE', [sticker.id])

                if (viewType !== MJTViewType.RECENT) {
                  // Recent 탭이 아닌 곳에서 스티커 선택 시 Recent 에 추가한다.
                  MJTRecentStickers.getInstance().insert(sticker)
                }

                const stickerInfo: MJTStickerInfo = {
                  id: sticker.id,
                  url: stickerUrl ?? ''
                }

                onStickerChoice(stickerInfo)
              }}
              onError={ (err) => {
                const newImgLoadErrorState = [...imgLoadErrorState, sticker.id]
                setImgLoadErrorState(newImgLoadErrorState)
              }}
            />
          )
        )
      })) : (<div
        className='hidden'
      />)}
    </div>
  )
}
