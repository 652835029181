import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  GridList,
  GridListTile,
  makeStyles,
} from "@material-ui/core";
import { useHistory, useLocation } from "../../myRouter";
import CatalogueCategoryService from "../services/CatalogueCategoryService";
import { StickerPack } from "../types/ResponseSchema";
import SearchItem from "../components/search/SearchItem";
import NoSearchResult from "../components/search/NoSearchResult";
import { Status } from "../types/status";

const useStyles = makeStyles((theme) => ({
  root: { height: "calc(100% - 56px)", margin: "0px 8px 8px 8px" },
  progressBox: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 100,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.5)",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99998,
  },
  circularProgress: {
    color: theme.brandColorTheme.mojitokBlue.main,
    position: "absolute",
    top:'50px',
    zIndex: 99999,
  },
  gridList: {
    display: "inline",
    margin: "8px 16px 8px 16px",
    transform: "translateZ(0)",
  },
  searchItem: {
    margin: "8px 16px 8px 16px",
  },
}));
interface CategorySearchLocationState {
  catalogue_category: string;
  lang: string;
}

function CategorySearchResult() {
  const [searchResultData, setSearchResultData] = useState<Array<StickerPack>>(
    []
  );
  const classes = useStyles();
  const [status, setStatus] = useState(Status.READY);
  const [errorCode, setErrorCode] = useState(0);
  const location = useLocation();
  const history = useHistory();

  const onClickPack = (packId: string) => {
    return function () {
      history.push(`/pack?packId=${packId}`);
    };
  };

  useEffect(() => {
    async function searchPack(name: string, lang: string) {
      setStatus(Status.READY);
      try {
        const searchResult = (
          await CatalogueCategoryService.searchCatalogueCategory(name)
        ).data;
        setSearchResultData(searchResult);
        setStatus(Status.SUCCESS);
      } catch (err) {
        if (err.code) {
          setErrorCode(err.code);
        } else {
          setErrorCode(0);
        }
        setStatus(Status.ERROR);
      }
    }
    if (!location.state) {
      setSearchResultData([]);
      setStatus(Status.SUCCESS);
    } else {
      if (location.state?.lang?.length !== 0) {
        const catalogue_category = location.state.catalogue_category?location.state.catalogue_category:""
        const lang = location.state.lang?location.state.lang:'en'
        searchPack(catalogue_category, lang);
      }
    }
  }, [location]);

  return (
    <>
      {status === Status.READY ? (
        <div id="hello-test" className={classes.progressBox}>
          <CircularProgress className={classes.circularProgress} size={24} />
        </div>
      ) : status === Status.SUCCESS ? (
        <div className={classes.root}>
          {searchResultData.length > 0 ? (
            <GridList
              className={classes.gridList}
              spacing={16}
              cellHeight={82}
              cols={1}
            >
              {searchResultData.map((item) => (
                <GridListTile
                  key={
                    item.thumbnails.find((image) => image.spec === "w250")!!.url
                  }
                >
                  <SearchItem
                    packId={item.id}
                    thumbnail={
                      item.thumbnails.find((image) => image.spec === "w250")!!
                        .url
                    }
                    packName={
                      item.name.find(
                        (lang: { content: string; language: string }) =>
                          lang.language === "en"
                      )!!.content
                    }
                    creatorName={
                      item.creator?.nickname.find(
                        (lang: { content: string; language: string }) =>
                          lang.language === "en"
                      )!!.content
                    }
                    onClickPack={onClickPack(item.id)}
                  />
                </GridListTile>
              ))}
            </GridList>
          ) : (
            <NoSearchResult />
          )}
        </div>
      ) : (
        <div></div>
        //TODO : Error Code 에 따른 View처리
      )}
    </>
  );
}

export default CategorySearchResult;
