import {useSelector,useDispatch} from 'react-redux'
import { goBack, MyHistory, push, selectHistory,replace } from "../redux/history/historySlice"

type HistoryPushProp = string | MyHistory

const divideQuery = (url:string)=>{
    const queryIdx = url.indexOf('?')
    if(queryIdx!==-1){
        const devideQuery = url.split('?')
        const pathname = devideQuery[0]
        const search = devideQuery[1]
        return {pathname,search}
    }
    return {pathname:url}
}
const changeHistoryProp = (pushElem:HistoryPushProp)=>{
    if(typeof pushElem==='string'){
        const {pathname,search} = divideQuery(pushElem)
        return {pathname,search}
    }else{
        const {pathname,search} = divideQuery(pushElem.pathname)
        const newPushElem = {...pushElem,pathname,search}
        return newPushElem
    }
}
export const useLocation = ()=>{
    const historyList = useSelector(selectHistory)
    return historyList[historyList.length-1]
    
}
export const useHistory = ()=>{
    const historyList = useSelector(selectHistory)
    const dispatch = useDispatch()
    const wrapPush = (pushElem:HistoryPushProp)=>{
        dispatch(push(changeHistoryProp(pushElem)))
    }
    const wrapGoBack = ()=>{
        dispatch(goBack())
    }
    const wrapReplace = (pushElem:HistoryPushProp)=>{
        dispatch(replace(changeHistoryProp(pushElem)))
    }
    const wrapHistory = {
        goBack:wrapGoBack,
        push:wrapPush,
        replace:wrapReplace,
        location:historyList[historyList.length-1],
        length:historyList.length
    }

    return wrapHistory

}