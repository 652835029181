import React from 'react'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

import styles from '../../styles.module.css'

export interface NetworkErroViewProps {
  onClickRefresh?: () => void
}

export default function NetworkErrorView({
  onClickRefresh
}: NetworkErroViewProps) {
  const { t } = useTranslation()

  return (
    <div className={styles['error-view-wrapper']}>
      <div className={styles['error-view-body']}>
        <Typography variant='subtitle1'>
          {t('network_fail_subtitle')}
        </Typography>
        <Typography
          variant='body2'
          style={{ whiteSpace: 'pre-line', marginTop: '8px', color: '#979797' }}
        >
          {t('network_fail_body')}
        </Typography>
        <Button
          style={{
            borderRadius: '0px',
            marginTop: '16px',
            color: '#FFFFFF',
            padding: '4px 8px 4px 8px'
          }}
          onClick={onClickRefresh}
        >
          <Typography variant='body2'>
            {t('network_fail_refresh_button')}
          </Typography>
        </Button>
      </div>
    </div>
  )
}
