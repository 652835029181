//스티커, 팩 검색하는 discover 페이지
import React, { Component } from 'react';
import { message, Spin } from 'antd';
import queryString from 'query-string';

import StickerListView  from '../../components/StickerListView';
import SearchBar  from '../../components/SearchBar';
import callAPI from '../../utils/callAPI';
import '../../styles/Discover.css';

const exampleTexts = [
  'Hahaha lol', 'I will come ASAP', 'Woahhh that’s pretty cool',
  'Ugh…', 'Enjoy your meal', 'Hey what’s up?'
]

class Stickers extends Component {

  constructor(props){
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state={
      loading: query && query.text ? true : false,
      stickerList: [],
      inputValue: '',
      rid:undefined
    };
  }

  componentDidMount(){
    const query = queryString.parse(this.props.location.search);
    if(query && query.text)
      this.searchStickers(query.text);
  }

  componentDidUpdate(prevProps, prevState) {

    const prevQ = queryString.parse(prevProps.location.search);
    const currQ = queryString.parse(this.props.location.search);

    if(prevQ.text !== currQ.text){
      this.searchStickers(currQ.text)
    }
  }


  render() {
    const { stickerList, loading, rid, inputValue } = this.state;
    return (
      <div style={stickerList.length > 0 ? {height:'inherit'} : {minHeight:0, height:0}}>
        <Spin tip="Loading..." spinning={loading}>
        <SearchBar pushHistory={ text => this.props.history.push(text)} initialSType="stickers" initialInput={inputValue}/>
            <div style={{width:'100%'}}>
            {
                stickerList.length > 0 ?
                  stickerList.map((item, index) => {
                    const changeUrl = item.images.w300 ? item.images.w300.url.replace("http://", "https://") : item.images.w618.url.replace("http://", "https://");
                    return(
                      <StickerListView
                        imageUrl={changeUrl}
                        stickerId = {item.id}
                        key={index}
                        rid={rid}
                        />
                    );
                  })
                  :<div className="discover-initial-view">
                    <p style={{fontWeight:'bold', padding:'12px 5%', margin:0}}>SEARCH BY MESSAGES</p>
                    {
                      exampleTexts.map((text, idx) =>
                        <div
                          className="discover-sticker-initial-view-text"
                          key={idx}
                          onClick={() => this.searchStickers(text)}>
                          {text}
                        </div>
                      )
                    }
                  </div>
            }
            </div>
        </Spin>
      </div>
    );
  }

  searchStickers =  (text) => {
    this.setState({loading: true}, async() =>
      await callAPI(`/stickers/recommend?q=${encodeURI(text)}`)
      .then( res => this.setState({ stickerList:res.result, loading: false, rid: res.meta.requestId, inputValue:text }) )
      .catch( err => {
        console.log(err);
        this.setState({loading: false}, () => message.warning('Sorry, Something went wrong. Please wait a moment. 🙏'));
        message.warning('Sorry, Something went wrong. Please wait a moment. 🙏');
      })
    );
  }

}



export default Stickers;
