import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import tranEn from "./Files/en.json";
import tranKo from "./Files/ko.json";
import tranId from "./Files/id.json";

export const languages = ["en", "ko", "in", "id"] as const;

const resources = {
  en: { translation: tranEn },
  ko: { translation: tranKo },
  in: { translation: tranId },
  id: { translation: tranId },
};

const userLanguage = window.navigator.language; //IE >= 11 , Chrome O , FireFox O


i18n.addResourceBundle('en', 'store', {
  ...tranEn
});
i18n.addResourceBundle('ko', 'store', {
  ...tranKo
});
i18n.addResourceBundle('in', 'store', {
  ...tranId
});
i18n.addResourceBundle('id', 'store', {
  ...tranId
});

export default i18n;
