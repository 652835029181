import { MOJITOK_DEMO_USERID } from "../constantValue";
import crypto from 'crypto-js'
import { getAuthData } from "../StoreView/utils";

const applicationId = process.env.REACT_APP_APPLICATION_ID
const localStorageTokenKeyPrefix = 'MJTUserToken'


function getLocalStorageTokenKey() {
    const tokenKeyPostfix = crypto.enc.Hex.stringify(
      crypto.MD5(
        (applicationId ?? '') +
          (MOJITOK_DEMO_USERID ?? '')
      )
    ).slice(0, 9)
    return localStorageTokenKeyPrefix + tokenKeyPostfix
}
export function loadTokenFromLocalStorage(){
    const userTokenStr = localStorage.getItem(getLocalStorageTokenKey())
    if (!userTokenStr) {
      return undefined
    }

    const userToken = JSON.parse(userTokenStr)
    return  userToken._mojitokUserAccessToken
}
export function setTokenForStore(){
    const token = loadTokenFromLocalStorage()
    getAuthData(token,'PC','none')
}