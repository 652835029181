import React, { useEffect, useState } from "react";
import { GridList, GridListTile, makeStyles } from "@material-ui/core";
import { useHistory } from "../../myRouter";
import CategoryItem from "../components/category/CategoryItem";
import CatalogueCategoryService from "../services/CatalogueCategoryService";
import { CatalogueCategory } from "../types/ResponseSchema";
import { PHONE_VIEW_WIDTH } from "../../constantValue";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "16px",
  },
}));

function CategoryView() {
  const cateforyElemHeight = PHONE_VIEW_WIDTH*0.4

  let history = useHistory();
  const classes = useStyles();
  const [category, setCatagory] = useState<Array<CatalogueCategory>>([]);

  const onClickPack = (name: string) => {
    history.push({
      pathname: "/packs/catalogue-category",
      state: { catalogue_category: name, lang: "en" },
    });
  };

  useEffect(() => {
    async function getCategory() {
      try {
        const category = (await CatalogueCategoryService.getCatalogueCategory())
          .data;

        setCatagory(category);
      } catch (e) {
        //category 를 불러오지 못한 경우
        console.log(e);
        history.push("/");
      }
    }
    getCategory();
  }, []);

  return (
    <div className={classes.root}>
      <GridList cols={3} spacing={8}>
        {category.map((item) => (
          <GridListTile style={{ height: cateforyElemHeight }} key={item.name}>
            <CategoryItem
              name={item.name}
              bgColor={item.bgColor}
              image={item.image}
              onClickCategory={onClickPack}
            ></CategoryItem>
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}

export default CategoryView;
