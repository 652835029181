import React from "react";

export default ({ color, fill }) => (
  <svg
    color={color}
    viewBox="0 0 42 42"
    width="42" height="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M28.48 21.35C26.91 17.27 21.32 17.05 22.67 11.12C22.77 10.68 22.3 10.34 21.92 10.57C18.29 12.71 15.68 17 17.87 22.62C18.05 23.08 17.51 23.51 17.12 23.21C15.31 21.84 15.12 19.87 15.28 18.46C15.34 17.94 14.66 17.69 14.37 18.12C13.69 19.16 13 20.84 13 23.37C13.38 28.97 18.11 30.69 19.81 30.91C22.24 31.22 24.87 30.77 26.76 29.04C28.84 27.11 29.6 24.03 28.48 21.35ZM19.2 26.38C20.64 26.03 21.38 24.99 21.58 24.07C21.91 22.64 20.62 21.24 21.49 18.98C21.82 20.85 24.76 22.02 24.76 24.06C24.84 26.59 22.1 28.76 19.2 26.38Z" fill={fill} />

  </svg>
);