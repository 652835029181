import React, { EventHandler } from 'react'

import { MJTErrorType } from '../../../types'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../../styles.module.css'
import EtcErrorView from './EtcErrorView'
import InternalServiceErrorView from './InternalServiceErrorView'
import NetworkErrorView from './NetworkErrorView'
import SessionErrorView from './SessionErrorView'

export interface ErrorViewProps {
  errorType: MJTErrorType
  onClickRefresh?: () => void
}

export default function ErrorView({
  errorType,
  onClickRefresh
}: ErrorViewProps) {
  let view

  switch (errorType) {
    case MJTErrorType.ETC:
      view = <EtcErrorView />
      break
    case MJTErrorType.NETWORK:
      view = <NetworkErrorView onClickRefresh={onClickRefresh} />
      break
    case MJTErrorType.INTERNALSERVICE:
      view = <InternalServiceErrorView />
      break
    case MJTErrorType.SESSION:
      view = <SessionErrorView />
      break
  }

  return <div className={styles['error-view-wrapper']}>{view}</div>
}
