import React, { useState } from "react";
import { osName, isBrowser, osVersion } from "react-device-detect";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

import "./App.css";
import { Route, useLocation } from "../myRouter";
import history from "./utils/history";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import MainTab from "./components/tab/MainTab";
import SearchView from "./layouts/SearchView";
import SearchResult from "./layouts/SearchResult";
import PackView from "./layouts/PackView";
import ProductInfo from "./layouts/ProductInfo";
import RefundInfo from "./layouts/RefundInfo";
import CategorySearchResult from "./layouts/CategorySearchResult";
import MyPacks from "./layouts/MyPacks";




function App() {
  const matchPC = false;
  const location = useLocation();
  const [isSupport, setIsSupport] = useState<boolean>(true);
  const { i18n } = useTranslation();

  return (
    <>
      <Header
      onlyCategory={false}
        path={location.pathname}
        onClose={closeViewer}
        isSupport={isSupport}
        isPC={matchPC}
      />
      <Route exact path="/" render={() => <MainTab />} />
      <Route path="/pack" render={() => <PackView isPC={matchPC} />} />

      <Route path="/search" render={() => <SearchView isPC={matchPC} />} />
      <Route
            path="/packs/search"
            render={() => <SearchResult isPC={matchPC} />}
      />
      <Route path="/product-info" render={() => <ProductInfo />} />
      <Route path="/refund-info" render={() => <RefundInfo />} />
      <Route
            exact
            path="/packs/catalogue-category"
            render={() => <CategorySearchResult />}
      />
      <Route path="/mypacks" render={() => <MyPacks isPC={matchPC} />} />
      {
        location.pathname === "/packs/search" ||
        location.pathname === "/packs/catalogue-category" ? null : (
          <Footer isPC={matchPC} />
        )
      }
    </>
  );
}
function closeViewer() {{
    document.dispatchEvent(new Event('closestore'))
    console.log(osName);
  }
}


export default App;
