import React from 'react';
import emojione from 'emojione';
import Parser from 'html-react-parser';

const EmojiListView = (props) => {
  const { emojiList, emojiClickFunc, selectedEmojiIndex, emojiType, rowData } = props;
  const emojiIsObject = typeof emojiList[0] === 'object' ? true : false;
  return(
    <div className={emojiType ? "search-result-emoji-wapper-matched": "search-result-emoji-wapper"}>
    {
      emojiList.map((emoji, index) =>{
        return(
          <div
            key={index}
            className="search-result-emoji-item-wapper"
            onClick={
              rowData && emojiType ?
                () => emojiClickFunc(emojiType, rowData.no, rowData.token, emoji) :
                emojiClickFunc ? () => emojiClickFunc(emoji.index) : null
            }
            >
            <div className={ selectedEmojiIndex === emoji.index ? "search-result-emoji-item-focus" : emojiClickFunc ? "search-result-emoji-item" : null}>
              { Parser(emojione.toImage( emojiIsObject ? emoji.icon : emoji)) }
            </div>
          </div>
        );
      })
    }
    </div>
  );
}

export default EmojiListView
