import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'
import SyncIcon from '@material-ui/icons/Sync'
import Alert from '@material-ui/lab/Alert'

import callApi from '../utils/callAPI'
import { LanguageName } from '../../Locales/i18n'
import MJTContext from '../../Core/MJTContext'
import MJTOwnership from '../../Core/model/outter/MJTOwnership'
import { MJTViewType } from '../../types'

import styles from '../styles.module.css'

export interface SettingViewProps {
  ownershipStateHandler: [
    MJTOwnership[],
    React.Dispatch<React.SetStateAction<MJTOwnership[]>>
  ]
}

const LANGUAGE_CODES = Object.entries(LanguageName)
  .sort((left, right) => (left[1] < right[1] ? -1 : 1))
  .map((lang) => lang[0]) as Array<keyof typeof LanguageName>

const SUPPORTED_LANGUAGE_CODES: typeof LANGUAGE_CODES = ['en']

enum SettingViewType {
  MAIN = 'MAIN',
  LANGUAGE = 'LANGUAGE'
}

enum SettingSyncStatus {
  READY = 'READY',
  SYNCING = 'SYNCING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

const useSnackBarStyles = makeStyles({
  root: {
    position: 'absolute',
    marginTop: 180
  }
})

const useAlertStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
    background: '#454545',
    padding: '7px 16px 7px 16px',
    color: '#FFFFFF'
  }
})

export default function SettingView(props: SettingViewProps) {
  const defaultRtsLangs =
    (MJTContext.getInstance().rtsLangs as Array<keyof typeof LanguageName>) ||
    (['en'] as Array<keyof typeof LanguageName>)
  const [settingViewType, setSettingViewType] = useState(SettingViewType.MAIN)
  const [syncStatus, setSyncStatus] = useState(SettingSyncStatus.READY)
  const [open, setOpen] = useState(false)

  const alertClasses = useAlertStyles()
  const snackBarClasses = useSnackBarStyles()

  const { t } = useTranslation()

  // 동기화 작업 성공/실패 1초후에 다시 동기화 버튼이 활성화되어 누를 수 있다.
  useEffect (() => {
    if (syncStatus === SettingSyncStatus.SUCCESS || syncStatus === SettingSyncStatus.FAILED) {
      setTimeout(() => {
        setSyncStatus(SettingSyncStatus.READY)
      }, 1000)
    }
  }, [syncStatus])

  async function getOwnerShipPack() {
    setSyncStatus(SettingSyncStatus.SYNCING)
    try {
      const ownerships = await callApi(MJTViewType.OWNERSHIP)
      if (!ownerships) throw 'no ownership'
      props.ownershipStateHandler[1](ownerships as MJTOwnership[])
      setSyncStatus(SettingSyncStatus.SUCCESS)
    } catch (err) {
      console.log(err)
      setSyncStatus(SettingSyncStatus.FAILED)
      setOpen(true)
    }
  }

  return (
    <div className={styles['setting-tab']}>
          <div>
            <Typography
              variant='h1'
              component='h1'
              className={styles['setting-title-wrapper']}
            >
              {t('settings_title')}
            </Typography>
          </div>
          <List>
            {syncStatus === SettingSyncStatus.READY ? (
              <ListItem button onClick={getOwnerShipPack}>
                <ListItemText primary={t('settings_sync_sticker_packs_menu')} />
                <SyncIcon />
              </ListItem>
            ) : syncStatus === SettingSyncStatus.SYNCING ? (
              <ListItem button>
                <ListItemText
                  primary={t('settings_syncing_body')}
                  style={{ color: MJTContext.getInstance().mainColor }}
                />
                <SyncIcon className={styles['setting-sync-icon-active']} />
              </ListItem>
            ) : syncStatus === SettingSyncStatus.SUCCESS ? (
              <ListItem>
                <ListItemText
                  primary={t('settings_sync_complete_body')}
                  style={{ color: '#979797' }}
                />
                <SyncIcon />
              </ListItem>
            ) : (
              <ListItem button>
                <ListItemText primary={t('settings_sync_sticker_packs_menu')} />
                <SyncIcon />
              </ListItem>
            )}
            <Divider style={{ margin: '0px 8px' }} />
          </List>
          <ListItem>
            <Typography
              variant='body2'
              className={styles['setting-sync-description']}
            >
              {t('settings_show_downloaded_sticker_packs_caption')}
            </Typography>
          </ListItem>

          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => setOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            classes={snackBarClasses}
          >
            <Alert
              icon={false}
              classes={alertClasses}
              onClose={() => setOpen(false)}
            >
              {t('settings_sync_failed_body')}
            </Alert>
          </Snackbar>
    </div>
  )
}
