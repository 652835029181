//스티커, 팩 검색하는 discover 페이지
import React, { Component } from 'react';
import {  message, Spin } from 'antd';
import queryString from 'query-string';
import SearchBar  from '../../components/SearchBar';
import PkgListView  from '../../components/PkgListView';
import callAPI from '../../utils/callAPI';
import '../../styles/Discover.css';

const exampleTags = [
  {text : 'Lovely', color : '#ffda45' },
  {text : 'Cat', color : '#ffa577' },
  {text : 'Funny', color : '#ff8599' },
  {text : 'Cute', color : '#65eec4' },
  {text : 'Couple', color : '#5cc5ff' },
  {text : 'Lazy', color : '#ca6fff' },
  {text : 'Hilarious', color :'#ffda45' }
];

class Packages extends Component {

  constructor(props){
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state={
      loading: query && query.text ? true : false,
      pkgList:[],
      inputValue : '',
      rid: undefined
    };
  }

  componentDidMount(){
    const query = queryString.parse(this.props.location.search);
    if(query && query.text)
      this.searchPkgs(query.text);
  }

  componentDidUpdate(prevProps, prevState) {

    const prevQ = queryString.parse(prevProps.location.search);
    const currQ = queryString.parse(this.props.location.search);

    if(prevQ.text !== currQ.text){
      this.searchPkgs(currQ.text)
    }
  }

  render() {
    const { pkgList, loading, rid, inputValue } = this.state;

    return (
      <div style={pkgList.length > 0 ? {height:'inherit'} : {minHeight:0, height:0}}>
        <Spin tip="Loading..." spinning={loading}>
          <div style={{width:'100%', height:'inherit'}}>
          { /* 스티커 검색 결과 stickerListView형태로 불러오기 */
              pkgList.length > 0 ?
                  <PkgListView
                    pkgList = {pkgList}
                    rid = {rid}
                    pushHistory={ text => this.props.history.push(text)}
                    searchText={inputValue}
                  />
                :<div>
                  <SearchBar pushHistory={ text => this.props.history.push(text)} initialSType="packages"/>
                  <div className="discover-initial-view">
                    <p style={{fontWeight:'bold', padding:'12px 5%', margin:0}}>POPULAR TAGS</p>
                    {
                      exampleTags.map((tag, idx) =>
                        <div
                          style={{color: tag.color}}
                          className="discover-pkg-initial-view-text"
                          key={idx}
                          onClick={() => this.searchPkgs(tag.text)}>
                          # {tag.text}
                        </div>
                      )
                    }
                  </div>
                </div>

          }
          </div>
        </Spin>
      </div>
    );
  }

  searchPkgs = (text) => {
    text.length > 2 ?
      this.setState({loading: true}, async() =>
      await callAPI(`/collections?q=${encodeURI(text)}&include=stickers`)
      .then(res => {
        if(res.result.length === 0 ){
          message.warning('Sorry... not found. 😭');
          this.setState({
            loading: false,
            inputValue: text
          })
        } else {
          this.setState({
            pkgList:res.result,
            loading: false,
            rid: res.meta.requestId,
            inputValue: text
          })
        }
      })
      .catch( err => {
        console.log(err);
        this.setState({loading: false});
        message.warning('Sorry, Something went wrong. Please wait a moment. 🙏');
      })
    )
    : message.warning('Enter at least 3 characters. 🙏');
  }

}



export default Packages;
