export default class DLog {
  private static instance: DLog;
  private constructor() { }
  public static getInstance(): DLog {
    if (!DLog.instance) {
      DLog.instance = new DLog();
    }
    return DLog.instance;
  }

  private static readonly TAG = '[Mojitok🙂]';
  private _debugLogMode: boolean = false;

  get debugLogMode(): boolean {
    return this._debugLogMode;
  }

  set debugLogMode(value: boolean) {
    this._debugLogMode = value;
  }

  /**
   * console.log 에 해당. Debug 메시지를 제대로 보여주기 위해 내부적으로는 console.log 를 사용한다.
   * @param message 로그 메시지
   */
  public d(message: string) {
    if (this.debugLogMode) {
      console.log(DLog.TAG, this.buildLogMsg(message));
    }
  }

  /**
   * console.error 에 해당
   * @param message 로그 메시지
   */
  public e(message: string): void;
  public e(message?: string, e?: Error): void;
  public e(message?: string, e?: Error): void {
    if (this.debugLogMode) {
      if (message && e) {
        console.error(DLog.TAG, this.buildLogMsg(message), e.name, e.message);
      } else if (e) {
        console.error(DLog.TAG, e.name, e.message);
      } else if (message) {
        console.error(DLog.TAG, this.buildLogMsg(message));
      }
    }
  }

  /**
   * console.info 에 해당
   * @param message 로그 메시지
   */
  public i(message: string) {
    if (this.debugLogMode) {
      console.info(DLog.TAG, this.buildLogMsg(message));
    }
  }

  /**
   * console.warn 에 해당
   * @param message 로그 메시지
   */
  public w(message: string) {
    if (this.debugLogMode) {
      console.warn(DLog.TAG, this.buildLogMsg(message));
    }
  }

  /**
   * console.log (verbose) 에 해당
   * @param message 로그 메시지
   */
    public v(message: string) {
      if (this.debugLogMode) {
        console.log(DLog.TAG, this.buildLogMsg(message));
      }
    }

  /**
   * 로그 메시지 prefix 생성
   * 그 외 모듈 및 메소드 이름 / 라인 넘버 표시
   * @param message 로그 메시지
   * @returns prefix 가 포함된 로그 메시지
   */
  private buildLogMsg(message: string): string {
    const stackTraces = (new Error()).stack?.split(' at').map(d => d.trim());

    if (stackTraces) {
      const methodLineInfo = stackTraces.slice(1).filter(d => !d.startsWith('DLog'));
      return `(${methodLineInfo[0]})\n\t${message.split('\n').join('\n\t')}`;
    } else {
      return `${message.split('\n').join('\n\t')}`;
    }
  }
}
