export interface IMJTUserToken {
  _mojitokUserAccessToken: string;
  _mojitokUserRefreshToken: string;
}

export default class MJTUserToken {
  private _mojitokUserAccessToken: string;
  private _mojitokUserRefreshToken: string;

  get mojitokUserAccessToken() { return this._mojitokUserAccessToken; }
  get mojitokUserRefreshToken() { return this._mojitokUserRefreshToken; }

  constructor(mojitokUserAccessToken: string, mojitokUserRefreshToken: string) {
    this._mojitokUserAccessToken = mojitokUserAccessToken;
    this._mojitokUserRefreshToken = mojitokUserRefreshToken;
  }

  toJSON(): IMJTUserToken {
    return {
      _mojitokUserAccessToken: this._mojitokUserAccessToken,
      _mojitokUserRefreshToken: this._mojitokUserRefreshToken
    }
  }
}
