import React from 'react'
import Divider from '@material-ui/core/Divider'

import StickersView from './StickersView'
import Title from './Title'
import EmotionTagView from './EmotionTagView'
import Search from './Search'
import PackInfo from './PackInfo'
import Recent from './Recent'

import { MJTOnStickerChoice, MJTViewType } from '../../types'
import MJTTag2Stickers from '../../Core/model/outter/MJTTag2Stickers'
import MJTStickerPack from '../../Core/model/outter/MJTStickerPack'
import MJTSticker from '../../Core/model/outter/MJTSticker'
import MJTOwnership from '../../Core/model/outter/MJTOwnership'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

export interface DataViewProps {
  viewType: MJTViewType
  data?:
    | MJTSticker[]
    | MJTStickerPack
    | string[]
    | MJTTag2Stickers[]
    | MJTOwnership[]
  onStickerChoice: MJTOnStickerChoice
}

export default function DataView({
  viewType,
  data,
  onStickerChoice
}: DataViewProps) {
  let view

  switch (viewType) {
    case MJTViewType.EMOTION_TAG:
      view = (
        <EmotionTagView
          emotionTags={data as MJTTag2Stickers[]}
          onStickerChoice={onStickerChoice}
        />
      )
      break
    case MJTViewType.SEARCH:
      view = (
        <Search keywords={data as string[]} onStickerChoice={onStickerChoice} />
      )
      break
    case MJTViewType.TRENDING:
      view = (
        <StickersView
          viewType={viewType}
          stickers={data as MJTSticker[]}
          onStickerChoice={onStickerChoice}
        />
      )
      break
    case MJTViewType.DOWNLOADED_PACK:
      view = (
        <div>
          <StickersView
            viewType={viewType}
            stickers={(data as MJTStickerPack).stickers!}
            onStickerChoice={onStickerChoice}
          />
          <div>
            <Divider style={{ margin: '0px 8px' }} />
            <PackInfo stickerPack={data as MJTStickerPack} isPurchased={true} />
          </div>
        </div>
      )
      break
    case MJTViewType.RECENT:
      view = (
        <Recent
          onStickerChoice={onStickerChoice}
        />
      )
      break
    default:
      // TODO: 에러 페이지 처리
      throw new Error(`Invalid view type(${viewType})`)
  }

  return (
    <div className={styles['data-view-wrapper']}>
      <Title viewType={viewType} />
      {view}
    </div>
  )
}
