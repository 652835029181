import React from "react";

export default ({ color, fill }) => (
  <svg
    color={color}
    viewBox="0 0 42 42"
    width="42" height="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.99 11C15.47 11 11 15.48 11 21C11 26.52 15.47 31 20.99 31C26.52 31 31 26.52 31 21C31 15.48 26.52 11 20.99 11ZM17.5 17C18.33 17 19 17.67 19 18.5C19 19.33 18.33 20 17.5 20C16.67 20 16 19.33 16 18.5C16 17.67 16.67 17 17.5 17ZM25.71 23.72C24.8 25.67 23.04 27 21 27C18.96 27 17.2 25.67 16.29 23.72C16.13 23.39 16.37 23 16.74 23H25.26C25.63 23 25.87 23.39 25.71 23.72ZM24.5 20C23.67 20 23 19.33 23 18.5C23 17.67 23.67 17 24.5 17C25.33 17 26 17.67 26 18.5C26 19.33 25.33 20 24.5 20Z" fill={fill} />

  </svg>
);