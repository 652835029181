/**
 * UIKit SDK 데모 페이지
 */
 import React, { Component } from 'react';
 import { MJTMainView, MJTRTSView, Mojitok } from '@mojitok/mojitok-uikit';
 import { Input } from 'antd';
 import '@mojitok/mojitok-uikit/dist/index.css';
 import {isMobile} from 'react-device-detect';

 import '../../styles/SDKDemoSampleApp.css';
 import '../../styles/HoverEffect.css';
 import {addCallbackWhenWindowOpenStore} from'../../utils/windowWrapper'
 import StoreView from '../../StoreView';
 import { Provider } from 'react-redux';
 import { store } from '../../redux/store';
 
import HoverEffect from './HoverEffect';
import { setTokenForStore } from '../../utils/auth';
import bindDragEvent from '../../utils/bindDragEvent';
import HoverEffectSmallView from './HoverEffectSmallView';
import { XS } from '../../constantValue';
 

 const bottomBoxLength = 8
 class MJTMainViewWrapper extends Component{
   constructor(props){
     super(props);
 
   }
   shouldComponentUpdate(nextProps, nextState){
     return false
   }
   render(){
     return <MJTMainView width="100%" height="230px" onStickerChoice={this.props.handleStickerChoice} />
   }
 }
 
 class SDKView extends Component {
   componentDidMount(){ 
    const checkClickZookizInsta = 
      (e)=>{
        console.log(e.target.tagName)
        e.stopPropagation()
        if(e.target.tagName==='A'){
          window.open('https://bit.ly/zkz2glx')
        }

      }
      const checkClickMiMiInsta = 
      (e)=>{
        console.log(e.target.tagName)
        e.stopPropagation()
        if(e.target.tagName==='A'){
          window.open('https://bit.ly/quan1glx')
        }

      }
    
    setInterval(()=>{
      console.log('1000')
      document.querySelectorAll('h3').forEach((el)=>{
        if(el.innerText ==='ZOOKIZ'){
          if(!el.parentNode.parentNode.getAttribute('listener')){
            el.parentNode.parentNode.addEventListener('click',checkClickZookizInsta)
            el.parentNode.parentNode.setAttribute('listener', 'true');
          }
        }
      })
      document.querySelectorAll('h3').forEach((el)=>{
        if(el.innerText ==='Hip MiMi&NeKo 03'){
          if(!el.parentNode.parentNode.getAttribute('listener')){
            el.parentNode.parentNode.addEventListener('click',checkClickMiMiInsta)
            el.parentNode.parentNode.setAttribute('listener', 'true');
          }
        }
      })
    },1000)
     addCallbackWhenWindowOpenStore(this.openStore.bind(this))
     const intervalId = setInterval(()=>{

       let buttonWrapper = document.querySelector('.mjt-main-view-wrapper header  div')
       if(!buttonWrapper){
         return 
       }
       // 왼쪽 버튼과 오른쪽 버튼을 없앰
       buttonWrapper.children[0].style.display='none'
       buttonWrapper.children[buttonWrapper.childElementCount-1].style.display='none'
 
       const iconPackButtonContainer = buttonWrapper.children[2]
       //main view 의 버튼리스트
       const iconPackButtonList = iconPackButtonContainer.querySelectorAll('button')
       if(iconPackButtonContainer && iconPackButtonList.length===bottomBoxLength){
         this.setState({iconPackButtonList:iconPackButtonList})
         clearInterval(intervalId)
         iconPackButtonContainer.addEventListener('mouseleave',()=>{
           this.hideHoverEffect()
         })
         const findButtonTagFromPath = (path)=>{
          const l = path.length
          for(let i=0;i<l;++i){
           if(path[i].tagName==='BUTTON'){
             return path[i]
           }
          }
        }
        let preClickDom = null
        const storeButton =iconPackButtonList[iconPackButtonList.length-1]

         iconPackButtonContainer.addEventListener('click',(e)=>{
           //모바일 기기면 전에 클릭한 버튼과 지금 클릭한 버튼이 같으면 uikit의 mainview
           //로 전파되는 이벤트를 막아서 아이콘 탭이 변경 안되게함
          if(isMobile){
            const path = e.path || (e.composedPath && e.composedPath());
            const currentDom = findButtonTagFromPath(path)
            // 전에 클릭한 버튼이 없을때 
            if(!preClickDom){
              //sdk에 들어가는 이벤트 막음
              e.stopPropagation()
              preClickDom = currentDom
              return
            }
            //전에 클릭한 버튼이랑 지금 누른 버튼이 다를시 
            if(currentDom!==preClickDom){
              e.stopPropagation()
              preClickDom = currentDom
              return
            //전에 클릭한 버튼이랑 지금 누른 버튼이 같고 store버튼 일시
            }else if(currentDom===storeButton){
              preClickDom = null
              return
            }
            preClickDom = currentDom
          }
        })
         iconPackButtonList.forEach((bottomButton,i)=>{
           // 마우스가 버튼으로 over되면 hover 효과 줌
            bottomButton.addEventListener('mouseover',(e)=>{
              if(isMobile){
                return
              }
              if(this.state.currentTabIdx===i){
                return 
              }
              this.showHoverEffect(i)
            })
            bottomButton.addEventListener('mousedown',(e)=>{
              if(isMobile){
                if(this.state.currentTabIdx===i){
                  this.hideHoverEffect()
                  }else{
                    //모바일이 일시 전에 클릭한 버튼과 지금 클릭한 버튼이 다르면
                    this.showHoverEffect(i)
                  }
              }
            })
            bottomButton.addEventListener('click',(e)=>{
            if(!isMobile){
              this.hideHoverEffect()
            }
            })
         })
       }
     },100)

     //StoreView 컴포넌트에서 닫음 버튼이 눌렸을때 현재 컴포넌트의 closeStore를 호출함
     document.addEventListener('closestore',()=>{
         this.closeStore()
     })

     //rts 가 드레그로 좌우로 움직이게 함
    const rtsViewElement = document.querySelector('.mjt-rts-view-wrapper div div')
    bindDragEvent(rtsViewElement)

    // rts 뷰에 변화가 일어나는 것을 감지해서 변화가 일어났으면
    // (rts 뷰에서 이미지가 클릭되었거나 rts뷰가 로딩 되었을때)
    //rtsViewVisible 변수에 저장함
    // => 화면에 나타나는 이모티콘을 위아래로 내리기 위해 사용
    // display none 을 이용해서 rts 가 클릭되었으면 현재 화면에 나타난 이모티콘을
    // 내리기 위해 사용
    function mutationCallback(){
      const rtsviewLength = rtsViewElement.children.length
      if(rtsviewLength){
        if(this.state.rtsViewVisible===false){
          this.setState({rtsViewVisible:true})
        }
      }else{
        if(this.state.rtsViewVisible===true){
          this.setState({rtsViewVisible:false})
        }
      }
    } 
    const observer = new MutationObserver(mutationCallback.bind(this));
     observer.observe(rtsViewElement,{childList:true, subtree:true })
    
   }
   shouldComponentUpdate(nextProps, nextState){
     if(this.state===nextState){
       return false
     }
     return true
 
   }
   constructor(props) {
     super(props);
     this.state = {
       text: '',
       chosenStickerUrl: '',
       currentTabIdx:-1,
       isStoreOpen:false,
       storeUrl:'',
       curretTabEl:null,
       rtsViewVisible:false,
       iconPackButtonList:null,
     };
     Mojitok.setup(process.env.REACT_APP_APPLICATION_ID);
     Mojitok.setUILang('en')
     Mojitok.setStoreGeometry(0, 0, 360, 640);
     Mojitok.login(process.env.REACT_APP_APPLICATION_TOKEN,
       'mojitok-sdk-demo-page',
       (e) => {
         if (e == null) {
           //TODO: 인증 성공 시 처리
           setTokenForStore()
           props.setLoginSuccess(true)
         } else {
           //TODO: 인증 실패 시 처리
           alert('Sorry, there was a problem connecting.');
         }
       }
     );
   }
   closeStore(){
     this.setState({isStoreOpen:false})
     
   }
   openStore(){
     this.setState({isStoreOpen:true})
     this.hideHoverEffect()
   }
   handleStickerChoiceInRts= (mjtChoiceMeta) => {
    this.setState({ chosenStickerUrl: mjtChoiceMeta.url,rtsViewVisible:false });
  };
   handleStickerChoice = (mjtChoiceMeta) => {
     this.setState({ chosenStickerUrl: mjtChoiceMeta.url });
   };
   showHoverEffect(tagIndex){
    this.setState({currentTabIdx:tagIndex})
  }
  hideHoverEffect(){
    this.setState({currentTabIdx:-1})
  }
   render() {
     const { text, chosenStickerUrl } = this.state;
     const { mockType } = this.props;
     return (
 
       <div className="uikit-inner-wrapper mjt2" style={{ position: "absolute",width:'100%',height:'100%' }}>
        
        {this.state.isStoreOpen &&
            <div style={{
              borderRadius:'28px',
              backgroundColor:'white',
              zIndex:99999,
              position:'absolute',
              height:'100%',
              overflowY:'scroll',
              width:'100%'
              }}>
              <div style={{position:'relative',width:'100%'}}>
                <Provider store={store}>
                  <StoreView />
                </Provider>
              </div>
            </div>
        }
         <>
        <div className={`not-small-view hover-${this.state.currentTabIdx} `}>
          <HoverEffect iconPackButtonList={this.state.iconPackButtonList}/>
        </div>
        <div className={`only-small-view hover-${this.state.currentTabIdx} `}>
          <HoverEffectSmallView iconPackButtonList={this.state.iconPackButtonList}/>
        </div>
         <div className="uikit-app-bar-wrapper">
         </div>
         <img className="example-text-message" src={require('../../img/sdk_demo/message_mock_v2.png')} alt="text-message" />
         {
           chosenStickerUrl ?
             <img src={chosenStickerUrl} className="chosen-sticker" alt="chosen-sticker" /> : null
         }
         <div className="mjt-rts-view-wrapper" style={this.state.rtsViewVisible?{}:{display:'none'}}>
           <MJTRTSView
             text={text}
             width="inherit"
             height="96px"
             delay={1000}
             onStickerChoice={this.handleStickerChoiceInRts}
           />
         </div>
         <div className="uikit-input-wrapper">
           <Input
             placeholder="Messaging....."
             className="uikit-input"
             value={text}
             onChange={(e) => this.setState({ text: e.target.value})}
             suffix={
               <img src={require('../../img/sdk_demo/input_send_btn-blue.svg')} className="uikit-input-send-btn" alt="mock-send-btn" />
             }
           />
         </div>
         <div
          onScrollCapture={(e)=>{
            // emotiontag 에서 tab들이 scroll 될때
            // 밑줄 부분인 span 태그가 left속성들을 변경해서
            // 정상작동 되지 않아서 scroll 이벤트가 전파되지 않도록 함
            e.stopPropagation()
          }}   
          className="mjt-main-view-wrapper" 
          style={{
           top: '10%',
           pointerEvents:''
         }}>
           <MJTMainViewWrapper handleStickerChoice={this.handleStickerChoice.bind(this)}/>
         </div>
         <div className={`mjt-main-view-wrapper-after ${mockType}`} />
         </>
         
       </div>
 
     );
   }
 }
 
 export default SDKView;