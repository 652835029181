export default async function callGalaxyAPI(apiType, apiVersion){

  const SERVER_URL = process.env.REACT_APP_GALAXY_SERVER_URL;
  const API_VERSION = apiVersion ? apiVersion : '/2.0';
  const API_TYPE = apiType;

  let response = await fetch(`${SERVER_URL + API_VERSION + API_TYPE }`, {
    headers: {
      "mojitok-token":process.env.REACT_APP_GALAXY_MOJITOK_TOKEN
    }
  });

  const responseJson = await response.json();

  return responseJson;

}
