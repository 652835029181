import MJTContent from '../../Core/MJTContent'
import MJTContext from '../../Core/MJTContext'
import MJTSticker from '../../Core/model/outter/MJTSticker'

/**
 * Search 기능
 * @param text
 * @returns
 */
export async function searchStickers(text: string) {
  let stickers: MJTSticker[] = []
  const language = MJTContext.getInstance().searchLang

  try {
    const searchedData = await MJTContent.getInstance().getSearchResultStickers(
      text.toLowerCase(),
      language
    )

    if (!searchedData) {
      throw 'stickers not exists.'
    }

    stickers = searchedData
  } catch (err) {
    console.log(err)
    // TODO: 검색 실패시 에러처리
  }

  return stickers
}
