import React from 'react'
import { Typography,makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    titleWrapper: {
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      height:'100%',
      marginLeft:'12.5vw',
      marginRight:'4rem',
      [theme.breakpoints.down('xs')]: {
          margin:'0 16px'
      },
    },
    title: {
      fontFamily: 'Gmarket Sans TTF',
      fontWeight: 700,
      fontSize: '60px',
      lineHeight: '80px',
      margin: '0 0 40px 0',
      color:'#233653',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
        lineHeight: '30px',
        margin: '0 0',
      },
    },
    secondTitle:{
        fontFamily: 'Gmarket Sans TTF',
        fontWeight: 400,
        fontSize: '24px',
        lineHeight: '40.8px',
        color:'#233653',
        marginTop:'12px',
        maxWidth:'694px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            lineHeight: '23.7px',
            margin: '0 0',
        },
    },
    description:{
        fontFamily: 'Gmarket Sans TTF',
        fontWeight: 'normal',
        fontSize: '18px',
        margin: '2% 0%',
        lineHeight: '28.8px',
        marginTop:'38px',
        color: '#919BA9',
        maxWidth:'616px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            lineHeight: '22.4px',
            marginTop:'14px',
        },
    },
    contectButton:{
        display: 'flex',
        maxWidth:'323px',
        height:'80px',
        justifyContent:'center',
        alignItems:'center',
        /* mojitok Blue l 200 */
        background: '#3278FF',
        borderRadius: "8px",
        marginTop:'78px',
        border:'none',
        cursor:'pointer',
        [theme.breakpoints.down('xs')]: {
            marginTop:'36px',
            maxWidth:'none',
            height:'54px'
        },
    },
    contentText:{
        fontFamily: 'Gmarket Sans TTF',
        fontSize:'18px',
        fontWeight:'bold',
        lineHeight:'180%',
        fontStyle:'normal',
        letterSpacing:'0.04em',
        color:'white'
    }
}));
  

export default function TextContent({tab}){
    const classes = useStyles()
    return <div className={classes.titleWrapper}>
        {tab==='main'?
            <>
                <div className={classes.title}>mojitok Sticker <span style={{color:'#3278FF'}}>Store</span></div> 
                <div className={classes.secondTitle}>
                    The quick and easy way to introduce stickers to your platform.
                </div>
                <Typography className={classes.description}>
                Launch a fully functional sticker store without the lengthy development process. Increase user engagement by giving your users access to an ever-growing selection of animated stickers from around the world. The complete sticker experience includes features such as: real-time suggestions, curation, search, and trending stickers.
                </Typography>
            </>
            :
            <>
                <div className={classes.title}>mojitok Sticker <span style={{color:'#3278FF'}}>Stream</span></div> 
                <div className={classes.secondTitle}>
                The smoothest way to expand your sticker collection.

                </div>
                <Typography className={classes.description}>
                Connect to mojitok’s bank of stickers to grow your library overnight. Enhance your performance by giving your users more content to choose from.
                </Typography>
            </>
        }
        <button onClick={(e)=>{
                window.open('https://mojitok.com/#emailContact')
        }} className={classes.contectButton}>
            <div className={classes.contentText}>CONTACT US</div>
        </button>
    </div>
}