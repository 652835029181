import { MJTPaymentPlatform } from '../types'
import MJTAuth, { MJTReplaceTokenListener } from './MJTAuth'
import MJTContext, { UI_LANGUAGE_CODE } from './MJTContext'
import MJTSearch from './MJTSearch'
import { SDK_VERSION } from './MJTAnalytics'
import _DLog from './DLog'

const DLog = _DLog.getInstance()

export type MJTOnConnected = (err: Error | undefined) => any

export default class Mojitok {
  constructor() {}

  /**
   * @param applicationId
   * @param enableDebugMode (default : false)
   */
  static setup(applicationId: string, enableDebugMode: boolean=false): void {
    MJTContext.getInstance().applicationId = applicationId;
    MJTSearch.getInstance();
    DLog.debugLogMode = enableDebugMode;
    DLog.d(
      `SDK_Version: ${SDK_VERSION}\nApplcationId: ${applicationId}`
    )
  }

  static setMainColor(mainColor: string) {
    MJTContext.getInstance().mainColor = mainColor
  }

  static setPaymentPlatform(paymentPlatform: MJTPaymentPlatform) {
    MJTContext.getInstance().paymentPlatform = paymentPlatform
  }

  private static async _connect(
    mojitokApplicationToken: string,
    onConnected: MJTOnConnected,
    replaceTokenListener: MJTReplaceTokenListener = () => { }
  ) {
    MJTContext.getInstance().applicationToken = mojitokApplicationToken;
    MJTAuth.getInstance().replaceTokenListener = replaceTokenListener;

    try {
      await MJTAuth.getInstance().getValidAccessToken()
    } catch (err) {
      onConnected(err)
      return
    }

    onConnected(undefined)
  }

  static async clearUserData() {
    if (MJTContext.getInstance().applicationId) {
      // application 정보가 설정된 경우 -> setting 되었다고 볼 수 있다.
      MJTContext.getInstance().userId = '';
      MJTAuth.getInstance().clearUserToken();
    } else {
      DLog.e('Initialize the Mojitok SDK by running Setup first');
    }
  }

  static async login(
    mojitokApplicationToken: string,
    userId: string,
    onConnected: MJTOnConnected,
    replaceTokenListener: MJTReplaceTokenListener = () => { }
  ) {
    if (MJTContext.getInstance().applicationId) {
      if (userId !== MJTContext.getInstance().userId) {
        Mojitok.clearUserData();
        MJTContext.getInstance().userId = userId;
      }
      await Mojitok._connect(mojitokApplicationToken, onConnected, replaceTokenListener);
    } else {
      DLog.e('Initialize the Mojitok SDK by running Setup first');
    }
  }

  /**
   * Logout Explicitly delete user's data
   */
  static async logout() {
    await Mojitok.clearUserData();
  }

  static setUILang(langCode: UI_LANGUAGE_CODE){
    MJTContext.getInstance().uiLang = langCode
  }

  static setStoreGeometry(
    left: number,
    top: number,
    width: number,
    height: number
  ) {
    const paramArray = [
      `left=${left}`,
      `top=${top}`,
      `width=${width}`,
      `height=${height}`
    ]
    const paramString = paramArray.join(',')
    MJTContext.getInstance().storeSetting = paramString
  }
}
