import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import { TrendingIcon } from './CustomIcons'
import { MJTViewType } from '../../types'

import { useTranslation } from 'react-i18next';
// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

export interface TitleProps {
  viewType: MJTViewType
}

export default function Title({ viewType }: TitleProps) {
  const [title, setTitle] = useState<string | undefined>()
  const [icon, setIcon] = useState<JSX.Element | undefined>()
  const { t } = useTranslation();

  useEffect(() => {
    if (viewType === MJTViewType.TRENDING) {
      setTitle(t('trending_subtitle'))
      setIcon(<TrendingIcon style={{ width: 30, height: 30 }} />)
    } else {
      setTitle(undefined)
      setIcon(undefined)
    }
  }, [])

  return title ? (
    <Typography variant='h3' className={styles['mainview-title-wrapper']}>
      {icon}
      {title}
    </Typography>
  ) : null
}
