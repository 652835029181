import jsonStream from 'jsonstream'
import stos from 'string-to-stream'
import MJTSearch from '../MJTSearch'
import { idb } from '../res/idbv23'
import _DLog from '../DLog'

const DLog = _DLog.getInstance()

const ecIdxToEmojiCategoryData: Array<{ ec_index: number, emoji_category: string }> = []
const emojiToEcIdxData: Array<{ emoji_str: string, ec_index: number }> = []
const tokenToEmojiEnData: Array<{ ec_index: number, keytoken: string }> = []
const tokenToEmojiKoData: Array<{ ec_index: number, keytoken: string }> = []

//JSON 파싱
async function loadDBFromJson() {
  try {
    await getDBNameFromJson()
    await getDBVersionFromJson()
    await getTokenToEmojiEnFromJson()
    await getTokenToEmojiKoFromJson()
    await getEmojiToEcIdxFromJson()
    await getEcIdxToEmojiCategoryFromJson()
  }
  catch (e) {
    throw e
  }
}

async function getDBNameFromJson() {
  const dbNameStream = jsonStream.parse('dbName')
  const dbStringStream = stos(idb)
  dbStringStream.pipe(dbNameStream)

  dbNameStream.on('data', (data) => {
    MJTSearch.getInstance().dbName = data
  })

  return new Promise<void>((resolve, reject) => {
    dbNameStream.on('end', () => {
      resolve()
    })
    dbNameStream.on('error', () => {
      reject(new Error('DB Name parse error'))
    })
  })
}

async function getDBVersionFromJson() {
  const dbVersionStream = jsonStream.parse('version')
  const dbStringStream = stos(idb)
  dbStringStream.pipe(dbVersionStream)

  dbVersionStream.on('data', (data) => {
    MJTSearch.getInstance().dbVersion = data
  })

  return new Promise<void>((resolve, reject) => {
    dbVersionStream.on('end', () => {
      resolve()
    })
    dbVersionStream.on('error', () => {
      reject(new Error('DB Version parse error'))
    })
  })
}

async function getTokenToEmojiEnFromJson() {
  const tokenToEmojiEnStream = jsonStream.parse('data.TOKEN_TO_EMOJI_EN.*')
  const dbStringStream = stos(idb)
  dbStringStream.pipe(tokenToEmojiEnStream)

  tokenToEmojiEnStream.on('data', (data) => {
    tokenToEmojiEnData.push({
      ["ec_index"]: data.ec_index,
      ["keytoken"]: data.keytoken
    })
  })

  return new Promise<void>((resolve, reject) => {
    tokenToEmojiEnStream.on('end', () => {
      resolve()
    })
    tokenToEmojiEnStream.on('error', () => {
      reject(new Error('TokenToEmojiEn parse error'))
    })
  })
}

async function getTokenToEmojiKoFromJson() {
  const tokenToEmojiKoStream = jsonStream.parse('data.TOKEN_TO_EMOJI_KO.*')
  const dbStringStream = stos(idb)
  dbStringStream.pipe(tokenToEmojiKoStream)

  tokenToEmojiKoStream.on('data', (data) => {
    tokenToEmojiKoData.push({
      ["ec_index"]: data.ec_index,
      ["keytoken"]: data.keytoken
    })
  })

  return new Promise<void>((resolve, reject) => {
    tokenToEmojiKoStream.on('end', () => {
      resolve()
    })
    tokenToEmojiKoStream.on('error', () => {
      reject(new Error('TokenToEmojiKo parse error'))
    })
  })
}
async function getEmojiToEcIdxFromJson() {
  const emojiToEcIdxStream = jsonStream.parse('data.EMOJI_TO_EC_IDX.*')
  const dbStringStream = stos(idb)
  dbStringStream.pipe(emojiToEcIdxStream)

  emojiToEcIdxStream.on('data', (data) => {
    emojiToEcIdxData.push({
      ["emoji_str"]: data.emoji_str,
      ["ec_index"]: data.ec_index
    })
  })

  return new Promise<void>((resolve, reject) => {
    emojiToEcIdxStream.on('end', () => {
      resolve()
    })
    emojiToEcIdxStream.on('error', () => {
      reject(new Error('EmojiToEcIdx parse error'))
    })
  })
}

async function getEcIdxToEmojiCategoryFromJson() {
  const ecIdxToEmojiCategoryStream = jsonStream.parse('data.EC_IDX_TO_EMOJICATEGORY.*')
  const dbStringStream = stos(idb)
  dbStringStream.pipe(ecIdxToEmojiCategoryStream)

  ecIdxToEmojiCategoryStream.on('data', (data) => {
    ecIdxToEmojiCategoryData.push({
      ["ec_index"]: data.ec_index,
      ["emoji_category"]: data.emoji_category
    })
  })

  return new Promise<void>((resolve, reject) => {
    ecIdxToEmojiCategoryStream.on('end', () => {
      resolve()
    })
    ecIdxToEmojiCategoryStream.on('error', () => {
      reject(new Error('EcIdxToEmojiCategory parse error'))
    })
  })
}

//indexed db 초기화
export async function initDB() {

  try {
    await loadDBFromJson()
  }
  catch (e) {
    throw e
  }

  const request = indexedDB.open(MJTSearch.getInstance().dbName, MJTSearch.getInstance().dbVersion)

  request.onupgradeneeded = (e) => {
    const db = request.result

    const ecIdxToEmojiObjStore = db.createObjectStore('EC_IDX_TO_EMOJICATEGORY', { autoIncrement: true })
    ecIdxToEmojiObjStore.createIndex('ec_index', 'ec_index')
    ecIdxToEmojiObjStore.createIndex('emoji_category', 'emoji_category')

    const emojiToEcIdxObjStore = db.createObjectStore('EMOJI_TO_EC_IDX', { autoIncrement: true })
    emojiToEcIdxObjStore.createIndex('emoji_str', 'emoji_str')
    emojiToEcIdxObjStore.createIndex('ec_index', 'ec_index')

    const tokenToEmojiEnObjStore = db.createObjectStore('TOKEN_TO_EMOJI_EN', { autoIncrement: true })
    tokenToEmojiEnObjStore.createIndex('ec_index', 'ec_index')
    tokenToEmojiEnObjStore.createIndex('keytoken', 'keytoken')

    const tokenToEmojiKoObjStore = db.createObjectStore('TOKEN_TO_EMOJI_KO', { autoIncrement: true })
    tokenToEmojiKoObjStore.createIndex('ec_index', 'ec_index')
    tokenToEmojiKoObjStore.createIndex('keytoken', 'keytoken')

    ecIdxToEmojiCategoryData.forEach((item) => {
      ecIdxToEmojiObjStore.put({
        ec_index: item.ec_index,
        emoji_category: item.emoji_category,
      })
    })

    emojiToEcIdxData.forEach((item) => {
      emojiToEcIdxObjStore.put({
        emoji_str: item.emoji_str,
        ec_index: item.ec_index,
      })
    })

    tokenToEmojiEnData.forEach((item) => {
      tokenToEmojiEnObjStore.put({
        ec_index: item.ec_index,
        keytoken: item.keytoken,
      })
    })

    tokenToEmojiKoData.forEach((item) => {
      tokenToEmojiKoObjStore.put({
        ec_index: item.ec_index,
        keytoken: item.keytoken,
      })
    })
  }

  request.onsuccess = () => {
    DLog.i('MJT DB Ready')
    MJTSearch.getInstance().isDBReady = true
  }

  request.onerror = () => {
    DLog.e('DB init error')
    const request = indexedDB.deleteDatabase(MJTSearch.getInstance().dbName)
  }
}
