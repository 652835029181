import React, { useCallback, useEffect, useRef } from "react"

export default function (){
    const ref = useRef<HTMLDivElement|null>(null)

    useEffect(()=>{
        if(!ref.current)return 
        const el = ref.current
        let mouseIsDown= false
        let preScrollLeft = 0
        let preMouseX = 0
        const mouseupHandler = ()=>{
            mouseIsDown=false

        }
        el.addEventListener('mousedown',(e:MouseEvent)=>{
            e.preventDefault()
            if(ref.current && e.button===0){
                preScrollLeft = ref.current.scrollLeft
                mouseIsDown=true
                preMouseX = e.x
            }
        })
        document.addEventListener('mouseup',mouseupHandler)
        const mouseMoveHandler = (e:MouseEvent)=>{
            if(ref.current && mouseIsDown){
                const mouseMoveX = e.x - preMouseX
                ref.current.scrollLeft = preScrollLeft - mouseMoveX
            }
        }
        document.addEventListener('mousemove',mouseMoveHandler)
        return ()=>{
            document.removeEventListener('mouseup',mouseupHandler)
            document.removeEventListener('mousemove',mouseMoveHandler)
        }
    },[ref])
    return ref
}