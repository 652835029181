import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Demo from './layout/Demo';
import PartnerProvideFunc from './components/PartnerProvideFunc';
import UIKitView from './components/UIKitView';
import TextMatchedEmojiView from './layout/TextMatchedEmojiView';
import UIKitDemo from './layout/UIKitDemo';
import Galaxy from './layout/Galaxy/Galaxy';
import SDKDemo from './layout/SDKDemo';
import {windowOpenWrap} from './utils/windowWrapper'


class App extends Component {
  componentDidMount(){
    windowOpenWrap()
  }
  render() {
    return (
      <div>
      <Router>
          <Switch>
                <Route exact path="/" component={SDKDemo}/>
                <Route path="/sdk-demo-2020" component={UIKitDemo}/>
                <Route path="/uikit" component={UIKitView}/>
                <Route path="/api" component={PartnerProvideFunc}/>
                <Route path="/demo" component={Demo} />
                <Route path="/texttoemoji" component={TextMatchedEmojiView} />
                <Route path="/galaxy" component={Galaxy} />
                <Route component={PartnerProvideFunc}/>
          </Switch>
      </Router>
      </div>

    );
  }
}

export default App;
