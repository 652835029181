import crypto from 'crypto-js';
import MJTSticker from '../Core/model/outter/MJTSticker';
import MJTContext from './MJTContext';

export default class MJTRecentStickers {
  private static instance: MJTRecentStickers;
  /**
   * Recent 에 저장할 스티커 수
   */
  private static maxRecentStickersCount = 12;
  /**
   * LocalStorage 에서 Recent 데이터의 키 prefix
   */
  private static recentKeyPrefix = 'MJTRecent';

  private constructor() { }

  public static getInstance(): MJTRecentStickers {
    if (!MJTRecentStickers.instance) {
      MJTRecentStickers.instance = new MJTRecentStickers();
    }

    return MJTRecentStickers.instance;
  }

  public get(): Array<MJTSticker> {
    return this.loadRecentFromLocalStorage();
  }

  public insert(mjtSticker: MJTSticker) {
    // RECENT 는 Array 의 제일 앞에 붙인다.
    // RECENT 스티커 전부 가져오기
    const previousRecentStickers = this.loadRecentFromLocalStorage();

    // 중복 확인하기
    const duplicatedStickerIdx = previousRecentStickers.findIndex(stk => stk.id === mjtSticker.id);

    if (duplicatedStickerIdx < 0) {
      // 중복이 존재하지 않는 경우
      this.saveRecentToLocalStorage([
        mjtSticker,
        ...previousRecentStickers.slice(0, MJTRecentStickers.maxRecentStickersCount - 1)
      ]);
    } else {
      // 중복이 존재하는 경우
      this.saveRecentToLocalStorage([
        mjtSticker,
        ...previousRecentStickers.slice(0, duplicatedStickerIdx),
        ...previousRecentStickers.slice(duplicatedStickerIdx+1, MJTRecentStickers.maxRecentStickersCount)
      ]);
    }
  }

  /**
   * Recent 기록을 지운다.
   *
   * @주의 localStorage 에 저장된 모든 recent 기록을 지우는 것이 아니라 로그인된 계정의 recent 기록만을 지운다.
   */
  public clear() {
    // 빈 Array 를 저장한다.
    this.saveRecentToLocalStorage(<Array<MJTSticker>>[]);
  }

  private getLocalStorageRecentKey(): string {
    // Recent 를 저장하는 key 는 application 과 user identifier 에 의해 나뉜다.
    const recentKeyPostfix = crypto.enc.Hex.stringify(
      crypto.MD5(
        (MJTContext.getInstance().applicationId ?? '')
        + (MJTContext.getInstance().userId ?? '')
      )
    ).slice(0, 9);

    return MJTRecentStickers.recentKeyPrefix + recentKeyPostfix;
  }

  private saveRecentToLocalStorage(recents: Array<MJTSticker>) {
    localStorage.setItem(this.getLocalStorageRecentKey(), JSON.stringify(recents));
  }

  /**
   * 모든 Recent 기록을 지운다. 모든 계정의 정보를 지운다.
   *
   * TODO: 언제 다른 계정의 Recent 기록 모두 지울지 확인하기
   */
  private clearAllRecentLocalStorage() {
    const localStorageRecentKeys: Array<string> = [];

    // Recent 관련 localStorage 의 키 찾기
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(MJTRecentStickers.recentKeyPrefix)) {
        localStorageRecentKeys.push(key);
      }
    });

    // Recent 기록 모두 삭제
    localStorageRecentKeys.forEach(key => {
      localStorage.removeItem(key)
    })

  }

  private loadRecentFromLocalStorage(): Array<MJTSticker>{
    let localStorageItem: Array<MJTSticker>;
    try {
      const localStorageItemStr = localStorage.getItem(this.getLocalStorageRecentKey());

      if (localStorageItemStr) {
        localStorageItem = JSON.parse(localStorageItemStr);
      } else {
        // 로그인이 전환되거나, 새로 로그인하는등의 이유로 Recent 가 비어있는 경우 -> 새로만듬
        localStorageItem = [];
        localStorage.setItem(this.getLocalStorageRecentKey(), JSON.stringify(localStorageItem));
      }
    } catch (err) {
      // 스토리지의 Recent 스키마가 잘못되어 JSON.parse 가 안되는 경우 -> 새로만듬
      // TODO: Runtime 에서 MJTSticker[] 스키마 확인
      localStorageItem = [];
      localStorage.setItem(this.getLocalStorageRecentKey(), JSON.stringify(localStorageItem));
    }

    return localStorageItem;
  }
}
