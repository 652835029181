import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import styles from '../../styles.module.css'

export default function InternalServiceErrorView() {
  const { t } = useTranslation()

  return (
    <div className={styles['error-view-wrapper']}>
      <div className={styles['error-view-body']}>
        <Typography variant='subtitle1'>
          {t('service_fail_subtitle')}
        </Typography>
        <Typography
          variant='body2'
          style={{ color: '#979797', marginTop: '8px' }}
        >
          {t('service_fail_body')}
        </Typography>
      </div>
    </div>
  )
}
