import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Stickers from './Discover/Stickers';
import Packages from './Discover/Packages';


class Discover extends Component {
  render(){
    return (
      <Switch>
        <Redirect exact from="/demo/discover" to="/demo/discover/stickers"/>
        <Route path="/demo/discover/stickers" component={Stickers}/>
        <Route path="/demo/discover/packages" component={Packages}/>
      </Switch>
    );
  }
}

export default Discover;
