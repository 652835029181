import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "24px 16px 24px 16px",
  },
  productInformationTitle: {
    marginTop: "16px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
    margin: "0px",
  },
  information: {
    color: theme.greyscaleColorTheme.grey2.main,
    ...theme.customTypography.body4,
  },
  informationLine: {
    marginTop: "16px",
    display: "flex",
  },
  bulletImg: {
    width: "3px",
    height: "3px",
    marginRight: "8px",
    marginTop: "9px",
  },
}));

function RefundInfo() {
  const classes = useStyles();
  const { t } = useTranslation('store');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const refundLimitText = t("product_info_termination_and_refund_dsc_02");

  const refundLimitTextForNewLine = refundLimitText
    .split("\n")
    .map((text) => <div>{text}</div>);

  const refudnInfomationTexts = [
    t("product_info_termination_and_refund_dsc_01"),
    refundLimitTextForNewLine,
    t("product_info_termination_and_refund_dsc_03"),
    t("product_info_termination_and_refund_dsc_04"),
  ];

  const refundInformations = refudnInfomationTexts.map((text) => (
    <div className={classes.informationLine}>
      <img
        className={classes.bulletImg}
        src={require(`../img/pc/grey_bullet.svg`)}
        alt="*"
      />
      <Typography className={classes.information}>{text}</Typography>
    </div>
  ));

  return (
    <div className={classes.root}>
      <Typography
        className={classes.productInformationTitle}
        variant="subtitle2"
        noWrap
      >
        {t("product_info_termination_and_refund")}
      </Typography>
      {refundInformations}
    </div>
  );
}
export default RefundInfo;
