
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import theme from "./theme";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import { getAuthData, onPurchaseResult } from "./utils";
import "./Locales/i18n"; // 추가
import { TabIndexProvider, useTabIndexContext, useTabIndexDispatch } from "./TabIndexContext";
import { BrowserRouter } from "../myRouter";
import ContentApp from "./ContentApp";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace:'normal',
    "& img": {
      'user-drag': "none"
    }
  },
}))

const StoreView = ({startTab}:{startTab:number})=>{
  const classes = useStyles()
  return(<BrowserRouter>
    <TabIndexProvider firstIndex={startTab}>
      <div className={classes.root}>
        {startTab===1?<ContentApp/>:<App/>}
      </div>
    </TabIndexProvider>
  </BrowserRouter>)}

export default   React.memo(StoreView)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
