import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import CreatorPack from "./CreatorPack";

import useDragAndWheel from "../../Hook/useDragAndWheel";
import { useHistory } from "../../../myRouter";

export interface CreatorPackListProps {
  packIdList: Array<string>;
  selectedPackId: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  packViewStickers: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    marginBottom:'24px',
    paddingLeft:'24px',
  },
}));

function CreatorPackList(props: CreatorPackListProps) {
  const history = useHistory()
  const classes = useStyles();
  const [packIdList, setPackIdList] = useState<Array<string>>([]);

  const dragRef = useDragAndWheel()
  const onClickPack = (packId: string) => {
    return function () {
      history.push(`/pack?packId=${packId}`);
    };
  };

  useEffect(() => {
    const removeSelfpackIdList = props.packIdList;
    const filteredList = removeSelfpackIdList.filter(function (
      value,
      index,
      removeSelfpackIdList
    ) {
      return value !== props.selectedPackId;
    });
    setPackIdList(filteredList);
  }, [props.selectedPackId]);

  return (
    <div className={classes.root}>
      <div ref={dragRef} className={classes.packViewStickers}>
        {packIdList.map((packId, index) => {
          if (index === 0) {
            return (
              <div key={packId}>
                <CreatorPack
                  onClickPack={onClickPack(packId)}
                  packId={packId}
                />
              </div>
            );
          } else if (index === packIdList.length - 1) {
            return (
              <div key={packId} style={{paddingRight:'24px'}}>
                <CreatorPack
                  onClickPack={onClickPack(packId)}
                  packId={packId}
                />
              </div>
            );
          } else {
            return (
              <div key={packId}>
                <CreatorPack
                  onClickPack={onClickPack(packId)}
                  packId={packId}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default CreatorPackList;
