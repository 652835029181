import React from "react";
import { makeStyles, Typography, Divider, Button } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useHistory } from "../../../myRouter";

export interface ProductInformationProps {
  isPC: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "960px",
    margin: "auto",
    marginBottom: "56px",
  },
  productInformationTitle: {
    marginTop: "56px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
    margin: "0px",
  },
  information: {
    color: theme.greyscaleColorTheme.grey2.main,
    ...theme.customTypography.body3,
  },
  refundInformationTitle: {
    marginTop: "56px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  informationLine: {
    marginTop: "8px",
    display: "flex",
  },
  bulletImg: {
    width: "3px",
    height: "3px",
    marginRight: "8px",
    marginTop: "9px",
  },
  infoButtonBox: {
    marginBottom: "48px",
  },
  productInfoButton: {
    width: "100%",
    height: "56px",
    padding: "0px 22px 0px 24px",
    textTransform: "none",
    justifyContent: "space-between",
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.customTypography.body4,
  },
  refundInfoButton: {
    width: "100%",
    height: "56px",
    padding: "0px 22px 0px 24px",
    textTransform: "none",
    justifyContent: "space-between",
    textAlign: "start",
    color: theme.greyscaleColorTheme.darkGrey.main,
    ...theme.customTypography.body4,
  },
}));

function ProductInformation(props: ProductInformationProps) {
  const history = useHistory()
  const classes = useStyles();
  const { t } = useTranslation('store');

  const productInformationTexts = t("product_info_dsc").split("\n");

  const productInformation = productInformationTexts.map((text) => (
    <div className={classes.informationLine}>
      <img
        className={classes.bulletImg}
        src={require(`../../img/pc/grey_bullet.svg`)}
        alt="*"
      />
      <Typography className={classes.information}>{text}</Typography>
    </div>
  ));

  const refundLimitText = t("product_info_termination_and_refund_dsc_02");

  const refundLimitTextForNewLine = refundLimitText
    .split("\n")
    .map((text) => <div>{text}</div>);

  const refundInformationTexts = [
    t("product_info_termination_and_refund_dsc_01"),
    refundLimitTextForNewLine,
    t("product_info_termination_and_refund_dsc_03"),
    t("product_info_termination_and_refund_dsc_04"),
  ];

  const refundInformation = refundInformationTexts.map((text) => (
    <div className={classes.informationLine}>
      <img
        className={classes.bulletImg}
        src={require(`../../img/pc/grey_bullet.svg`)}
        alt="*"
      />
      <Typography className={classes.information}>{text}</Typography>
    </div>
  ));

  const onClickProductInfo = () => {
    history.push("/product-info");
  };
  const onClickRefundInfo = () => {
    history.push("/refund-info");
  };

  return (
    <div>
      {props.isPC ? (
        <div className={classes.root}>
          <Typography
            className={classes.productInformationTitle}
            variant="h6"
            noWrap
          >
            {t("product_info_title")}
          </Typography>
          <Divider className={classes.divider} />
          {productInformation}
          <Typography
            className={classes.productInformationTitle}
            variant="h6"
            noWrap
          >
            {t("product_info_termination_and_refund")}
          </Typography>
          <Divider className={classes.divider} />
          {refundInformation}
        </div>
      ) : (
        <div className={classes.infoButtonBox}>
          <Button
            className={classes.productInfoButton}
            onClick={onClickProductInfo}
          >
            {t("product_info_title")}
            <img src={require(`../../img/right_arrow.svg`)} />
          </Button>
          <Divider className={classes.divider} />
          <Button
            className={classes.refundInfoButton}
            onClick={onClickRefundInfo}
          >
            {t("product_info_termination_and_refund")}
            <img src={require(`../../img/right_arrow.svg`)} />
          </Button>
        </div>
      )}
    </div>
  );
}

export default ProductInformation;
