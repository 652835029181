/**
 * UIKit 데모 페이지
 */
import React, { Component } from 'react';
import { MJTMainView, MJTRTSView, Mojitok } from '@mojitok/mojitok-uikit';
import { Input } from 'antd';
import '@mojitok/mojitok-uikit/dist/index.css';
import '../styles/UIKit.css';


class UIKitView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      chosenStickerUrl: ''
    };

    Mojitok.setup(process.env.REACT_APP_APPLICATION_ID);
    Mojitok.setStoreGeometry(0, 0, 360, 640);
    Mojitok.login(process.env.REACT_APP_APPLICATION_TOKEN,
      'mojitok-sdk-demo-page',
      (e) => {
        if (e == null) {
          //TODO: 인증 성공 시 처리
        } else {
          //TODO: 인증 실패 시 처리
          alert('Sorry, there was a problem connecting.');
        }
      }
    );

  }


  handleStickerChoice = (mjtChoiceMeta) => {
    this.setState({ chosenStickerUrl: mjtChoiceMeta.url });
  };

  render() {
    const { text, chosenStickerUrl } = this.state;
    const { mockType } = this.props;
    return (
      <div className="uikit-inner-wrapper" style={{ position: "absolute" }}>
        <img className="example-text-message" src={require('../img/sdk_demo/message_mock.png')} alt="text-message" />
        {
          chosenStickerUrl ?
            <img src={chosenStickerUrl} className="chosen-sticker" alt="chosen-sticker" /> : null
        }
        <div className="mjt-rts-view-wrapper">
          <MJTRTSView
            text={text}
            width="inherit"
            height="64px"
            delay={1000}
            onStickerChoice={this.handleStickerChoice}
          />
        </div>
        <div className="uikit-input-wrapper">
          <img src={require('../img/sdk_demo/input_plus_btn.svg')} className="uikit-input-plus-btn" alt="mock-plus-btn" />
          <Input
            placeholder="Enter Text"
            className="uikit-input"
            value={text}
            onChange={(e) => this.setState({ text: e.target.value })}
            suffix={
              <img src={require('../img/sdk_demo/input_send_btn.svg')} className="uikit-input-send-btn" alt="mock-send-btn" />
            }
          />
        </div>
        <div className="mjt-main-view-wrapper" style={{
          top: '10%'
        }}>
          <MJTMainView width="100%" height="230px" onStickerChoice={this.handleStickerChoice} />
        </div>
        <div className={`mjt-main-view-wrapper-after ${mockType}`} />
      </div>
    );
  }
}

export default UIKitView;