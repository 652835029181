//pkgUI 보여주는 컴포넌트
import React from 'react';
import { Input, message, Radio } from 'antd';
import '../styles/Discover.css';

class SearchBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      inputValue : props.initialInput ? props.initialInput : '',
      searchType : props.initialSType
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      inputValue: nextProps.initialInput
    });
  }

  render(){
    return(
      <div className="discover-box">
        <div className="discover-search-box">
          <Input
            className="discover-search-input"
            placeholder="You can find stickers and packages"
            value={this.state.inputValue? this.state.inputValue: ''}
            onChange={this.handleInput}
            onPressEnter={this.searchDataByType}
            />
          <button className="discover-search-btn" onClick={this.searchDataByType}>
            <img src={require('../img/discover-icon-search.svg')} alt="search_icon" className="discover-search-btn-icon"/>
          </button>
        </div>
        <div className="search-type-btn-wapper">
          <Radio.Group defaultValue={this.props.initialSType} size="small" buttonStyle="solid" style={{width:'100%'}} onChange={this.changeSType}>
            <Radio.Button value="stickers" className="search-type-btn">Stickers</Radio.Button>
            <Radio.Button value="packages" className="search-type-btn">Packages</Radio.Button>
          </Radio.Group>
        </div>
      </div>
    );
  }

  handleInput = (e) => {
    this.setState({inputValue: e.target.value});
  };

  changeSType = (e) => {
    this.setState({searchType : e.target.value}, () => {
      e.target.value === 'stickers' ?
      this.props.pushHistory('/demo/discover/stickers')
      :this.props.pushHistory('/demo/discover/packages')
    })
  }

  //sticker, pacakge 검색 함수
  searchDataByType = async() => {

    const text = this.state.inputValue; // user가 검색하기 위해 입력한 값
    const type = this.state.searchType;

    text ?
      type === 'stickers' ?
        this.props.pushHistory(`/demo/discover/stickers?text=${text}`)
      : this.props.pushHistory(`/demo/discover/packages?text=${text}`)
    : message.warning('Please Type your message 🙏');
  }

}

export default SearchBar
