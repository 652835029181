import React, { useState, useEffect, useCallback } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'

import DataView from './DataView'
import SettingView from './SettingView'
import callApi from '../utils/callAPI'
import { MJTErrorType, MJTOnStickerChoice, MJTViewType } from '../../types'

import MJTStickerPack from '../../Core/model/outter/MJTStickerPack'
import MJTSticker from '../../Core/model/outter/MJTSticker'
import MJTTag2Stickers from '../../Core/model/outter/MJTTag2Stickers'
import MJTOwnership from '../../Core/model/outter/MJTOwnership'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'
import MJTException from '../../Core/model/error/MJTException'
import ErrorView from './error/ErrorView'
import MJTContext from '../../Core/MJTContext'
import { useSessionDispatch } from '../SessionStateContext'
import MJTAuth from '../../Core/MJTAuth'
import { convertErrorCodeToMJTErrorType } from '../utils/convertErrorCodeToErrorType'

export interface TabPanelProps {
  value: number
  index: number
  viewType: MJTViewType
  packData: MJTStickerPack | undefined
  onStickerChoice: MJTOnStickerChoice
  ownershipStateHandler: [
    MJTOwnership[],
    React.Dispatch<React.SetStateAction<MJTOwnership[]>>
  ]
}

function TabPanel(props: TabPanelProps) {
  const { value, viewType, packData, onStickerChoice, index } = props
  const [data, setData] = useState<
    | MJTStickerPack
    | MJTSticker[]
    | MJTTag2Stickers[]
    | string[]
    | MJTOwnership[]
    | undefined
  >()
  const [status, setStatus] = useState('READY') //상태 추가
  const [errorCode, setErrorCode] = useState(null)
  const dispatch = useSessionDispatch()

  useEffect(() => {
    if (
      viewType !== MJTViewType.SETTING &&
      viewType !== MJTViewType.STORE
    ) {
      callData()
    }
  }, [value])

  const callData = useCallback(() => {
    //api call
    async function getAPIData() {
      setStatus('READY')
      try {
        const data = await callApi(viewType)
        if (!data) throw new MJTException(4011, 'No data')
        setData(data)
        setStatus('SUCCESS')
      } catch (err) {
        setErrorCode(err.code)
        if (convertErrorCodeToMJTErrorType(errorCode) === MJTErrorType.SESSION) {
          dispatch({
            type: 'SESSION_EXPIRED',
            isSessionExpired: true
          })

        } else if (errorCode != null) {
        }
        setStatus('ERROR')
      }
    }

    if (value === index) {
      if (viewType === MJTViewType.STORE || viewType === MJTViewType.RECENT) {
        //store, recent
        setStatus('SUCCESS')
      } else if (viewType === MJTViewType.DOWNLOADED_PACK) {
        setData(packData)
        setStatus('SUCCESS')
      } else {
        getAPIData()
      }
    }
  }, [value])

  return (
    <div
      className={styles['tabpanel']}
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
    >
      {viewType === MJTViewType.SETTING ? (
        <SettingView ownershipStateHandler={props.ownershipStateHandler} />
      ) : status === 'READY' ? (
        <div className={styles['ready']}>
          <CircularProgress />
        </div>
      ) : status === 'SUCCESS' ? (
        <DataView
          viewType={viewType}
          data={data}
          onStickerChoice={onStickerChoice}
        />
      ) : (
        <ErrorView
          errorType={convertErrorCodeToMJTErrorType(errorCode)}
          onClickRefresh={() => callData()}
        />
      )}
    </div>
  )
}

export default TabPanel
