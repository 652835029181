import React,{useCallback, useEffect, useLayoutEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core';

import PhoneView from './PhoneView';
import SDKView from './SDKView';
import '../../styles/AnimationField.css'
import StoreViewInPhone from './StoreViewInPhone';
import imageSlideBtn from "../../img/Arrow.png";
import imageReverseSlideBtn from "../../img/ArrowReverse.png";
import pointer from "../../img/pointer.png";
import { PHONE_VIEW_HEIGHT, PHONE_VIEW_TOP, PHONE_VIEW_WIDTH, shrinkRatio, XS } from '../../constantValue';
import checkUnderXS from '../../utils/checkUnderXS';

const translateYvalue = 320 - 320*shrinkRatio
const heightOnMobile = 800*shrinkRatio 

const useStyles = makeStyles((theme) => ({
    container:{
        position:'relative',
        top:'calc(50% - 320px)',
        width:'100%',
        height:'800px',
        [theme.breakpoints.down('xs')]: {
            height:heightOnMobile,
            top:'0px',
            transform: `translateY(-${(640/2)*(1-shrinkRatio)}px)`
        },
        "&.right-view":{
            left:'0px',
            [theme.breakpoints.down('xs')]: {
                left:'-50%',
            },
        }
    },
    phoneViewWrapper:{
        position:'absolute',
        // transition:'left 1s,top 1s',
    },
    storeWrapper:{
        borderRadius:'28px',
        backgroundColor:'white',
        zIndex:500001,
        overflowY:'scroll',
        width:PHONE_VIEW_WIDTH,
        height:PHONE_VIEW_HEIGHT,
    },
    sdkViewWrapper:{
        borderRadius:'28px',
        backgroundColor:'white',
        zIndex:500001,
        position:'absolute',
        width:'100%',
        height:'100%',
        overflowY:'scroll',
        width:'100%'
    },
    changeBtnWrapper:{
        position:'absolute',
        top:'300px',
        zIndex:2,
        width:'77px',
        height:'77px',
        left:'500px',
        [theme.breakpoints.down(1600)]: {
            left:'400px'
        },
        [theme.breakpoints.down('xs')]: {
            left:'calc(50% - 60px)',
            width:'50px',
            height:'50px',
            zIndex:3,
        },
    }, 
    changeBtnReverseWrapper:{
        display:'none',
        top:'300px',
        zIndex:2,
        left:'calc(50% + 10px)',
        width:'50px',
        height:'50px',
        [theme.breakpoints.down('xs')]: {
            position:'absolute',
            display:'block',
            zIndex:3
        },
    }, 
    changeBtnContainer:{
        position:'relative',
        width:'100%',
        height:'100%',
        cursor:'pointer',
    }, 
    imgSlideBtnWrapper:{
        width:'100%',
        height:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',

    },
    svgLine:{
        stroke:'#3278FF',
        strokeWidth:2
    },
    pointerAnimationContainer:{
        left:'0px',
        position:'absolute',
        top:'650px',
        zIndex:100,
        display:'flex',
        width:'360px',
        height:'48px',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.down('xs')]: {
            transform: `translateY(-${(640/2)*(1-shrinkRatio)}px)`,
            // display:'none'
            height:'37px',
            width:310,

        },
    },

    pointerAnimationTextBox:{
        border:'1.8px solid black',
        borderRadius:'15px',
        backgroundColor:'white',
        fontFamily: 'Gmarket Sans TTF',
        fontStyle:'normal',
        fontWeight:'500',
        fontSize:'16px',
        lineHeight:'32px',
        marginLeft:'8px',
        textAlign:'center',
        width:'102px',
        height:'34px',
        display:'block',
        [theme.breakpoints.down('xs')]: {
            fontSize:'12px',
            width:'169px',
            height:'48px',
            display:'none',
        },
    },
    pointerAnimationTextBox2:{
        border:'1.8px solid black',
        borderRadius:'15px',
        backgroundColor:'white',
        fontFamily: 'Gmarket Sans TTF',
        fontWeight:'400',
        lineHeight:'17.82px',
        marginLeft:'8px',
        textAlign:'center',
        fontSize:'12px',
        minWidth:'169px',
        minHeight:'48px',
        display:'none',
        paddingTop:'5px',
        '& span':{
            fontWeight:'700',
        },
        [theme.breakpoints.down('xs')]: {
            display:'block',
        },
    }
}))


export default function AnimationField({tab,setTab}){
    const classes = useStyles()
    const [isLeftViewOnMobile,setisLeftViewOnMobile] = useState(true)
    const [isChanged,setIsChanged] = useState(false)
    const [loginSuccess,setLoginSuccess] = useState(false)
    const [pointerUpDownVisible,setPointerUpDownVisible] = useState(true)

    const changePhoneView = ()=>{
        if(document.body.clientWidth<XS){
            setisLeftViewOnMobile(!isLeftViewOnMobile)
        }else{
            setTab(tab==='main'?'cate':'main')
            !isChanged && setIsChanged(true)
        }
    }
    useLayoutEffect(()=>{
        if(!isChanged  && tab==='cate'){
            setIsChanged(true)
        }
    },[tab])
    const handleAnimationEnd = (e)=>{
        e.target.classList.remove('side_to_main','main_to_side')
    }
    const maintabClass = isChanged?`${classes.phoneViewWrapper} ${tab==='main'?'side_to_main':'main_to_side'}`:classes.phoneViewWrapper
    const cateTabClass = isChanged?`${classes.phoneViewWrapper} ${tab==='cate'?'side_to_main':'main_to_side'}`:classes.phoneViewWrapper

    return <div className={classes.container+ (isLeftViewOnMobile?'':' right-view')}>
                    {pointerUpDownVisible &&
                <div style={isLeftViewOnMobile?{}:{right:'0px',left:'auto'}} className={`${classes.pointerAnimationContainer} pointer_up_down`}>
                    <img height='100%' src={pointer}/>
                    <div className={classes.pointerAnimationTextBox}>try demo</div>
                    <div className={classes.pointerAnimationTextBox2}>
                        <p>
                        <b>single-tap</b>: description<br></br>
                        <b>double-tap</b>: activate
                        </p>
                    </div>
                </div>
                
            }
            <div onAnimationEnd={handleAnimationEnd}   className={maintabClass+ " " + (tab==='main'?'main-view':'side-view')}>
                <PhoneView setPointerUpDownVisible={setPointerUpDownVisible} enabled={checkUnderXS() || tab==='main'} changePhoneView={changePhoneView}>
                    <SDKView setLoginSuccess={setLoginSuccess}/>
                </PhoneView>
            </div>
            {loginSuccess && <div onAnimationEnd={handleAnimationEnd} className={cateTabClass+ " " + (tab==='cate'?'main-view':'side-view')}>
                <PhoneView setPointerUpDownVisible={setPointerUpDownVisible} enabled={checkUnderXS() || tab==='cate'} changePhoneView={changePhoneView}>
                    <StoreViewInPhone/>
                </PhoneView>
            </div>}
            <div onClick={changePhoneView} className={classes.changeBtnWrapper}>
                <div className={classes.changeBtnContainer}>
                    <img style={{position:'absolute',width:'100%',height:'100%'}} src={`${imageSlideBtn}`}/>
                </div>
            </div>
            <div onClick={changePhoneView} className={classes.changeBtnReverseWrapper}>
                <div className={classes.changeBtnContainer}>
                    <img style={{position:'absolute',width:'100%',height:'100%'}} src={imageReverseSlideBtn}/>
                </div>
            </div>

    </div>
}
