import React, { Component } from 'react';
import { Spin } from 'antd';
import StickerListView  from '../../components/StickerListView';
import callAPI from '../../utils/callAPI';
import { Link } from 'react-router-dom';

class Tags extends Component {

  constructor(props){
    super(props);
    this.state={
      loading : true,
      findTagStickerList:[]
    };
  }
  async componentDidMount(){
    const tagName= this.props.location.state;
    const findTagStickerList=await callAPI(`/tags/${tagName}?include=stickers`);

    if(findTagStickerList.meta && findTagStickerList.meta.message === "OK") {
      this.setState({findTagStickerList:findTagStickerList.result.stickers, loading: false});
    }
    return Promise.resolve();
  }

  render() {
    return (
      <Spin tip="Loading..." spinning={this.state.loading}>
        <Link to={{pathname:"/demo/taglist"}}>
          <img src={require('../../img/icon-back.svg')} alt="back-taglist" />
        </Link>
        <div style={{width:'90%', margin:'5%', marginTop:'0.7%', minHeight:200 }}>
          {
              this.state.findTagStickerList.map((item, index)=>{
                const changeUrl = item.images.w618.url.replace("http://", "https://");
                return(
                  <StickerListView
                    imageUrl={changeUrl}
                    stickerId = {item.id}
                    key={index}
                    />
                );
              })
          }
        </div>
      </Spin>
    );
  }

}//tag component end

export default Tags;
