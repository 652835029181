import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "16px",
    marginTop: "24px",
    marginRight: "16px",
  },
  productInformationTitle: {
    marginBottom: "16px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  divider: {
    backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
    height: "0.5px",
    margin: "0px",
  },
  information: {
    color: theme.greyscaleColorTheme.grey2.main,
    ...theme.customTypography.body4,
  },
  informationLine: {
    marginTop: "8px",
    display: "flex",
  },
  bulletImg: {
    width: "3px",
    height: "3px",
    marginRight: "8px",
    marginTop: "9px",
  },
}));

function ProductInfo() {
  const classes = useStyles();
  const { t } = useTranslation('store');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const productInfomationTexts = t("product_info_dsc").split("\n");

  const productInformations = productInfomationTexts.map((text) => (
    <div className={classes.informationLine}>
      <img
        className={classes.bulletImg}
        src={require(`../img/pc/grey_bullet.svg`)}
        alt="*"
      />
      <Typography className={classes.information}>{text}</Typography>
    </div>
  ));

  return (
    <div className={classes.root}>
      <Typography
        className={classes.productInformationTitle}
        variant="subtitle2"
        noWrap
      >
        {t("product_info_title")}
      </Typography>
      {productInformations}
    </div>
  );
}
export default ProductInfo;
