import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
// import type { RootState } from './store'

// Define a type for the slice state
export interface MyLocation{

}
export interface MyHistory{
  pathname:string,
  state?:{query?:string,lang?:string,catalogue_category?:string}
  search?:string
}
interface HistoryState {
  historyList: MyHistory[]
}

// Define the initial state using that type
const initialState: HistoryState = {
    historyList: [{pathname:"/"}],
}
export const historySlice = createSlice({
  name: 'history',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    goBack:(state)=>{
        if(state.historyList.length){
            state.historyList.pop()
        }
    },
    push:(state,action:PayloadAction<MyHistory>)=>{
      const historyList = state.historyList
      const preHistory = historyList[historyList.length-1]
      if(historyList[historyList.length-1].pathname!==action.payload.pathname
        || preHistory.search!==action.payload.search ){
        state.historyList.push(action.payload)
      }
    },
    replace:(state,action:PayloadAction<MyHistory>)=>{
      state.historyList.pop()
      state.historyList.push(action.payload)
    },
  },
})

export const { goBack,push,replace } = historySlice.actions

export const selectHistory = (state:RootState)=> state.history.historyList
// // Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: any) => state.counter.value

export default historySlice.reducer