//props으로 받은 패키지 리스트를 보여주는 view
import React, { Component } from 'react';
import PkgStickerView from './PkgStickerView';
import SearchBar  from './SearchBar';
import PkgView from './PkgView';


class PkgListView extends Component{

  constructor(props){
    super(props);
    this.state={
      showPkgSticker :false,
      selectedPkg : undefined
    };
  }

  render(){
    const { rid, pkgList, pushHistory, searchText } = this.props;
    const { showPkgSticker, selectedPkg } = this.state;
    if(showPkgSticker && selectedPkg){
      return(
        <PkgStickerView
          pkgInfo={pkgList.find(pkgInfo => pkgInfo.id === selectedPkg)}
          rid={rid}
          isBack={()=>this.setState({showPkgSticker : false, selectedPkg:null})}
          initialInput={searchText}
        />
      )

    }else {
      return(
        <div>
          <SearchBar pushHistory={pushHistory} initialSType="packages" initialInput={searchText}/>
          {
            pkgList.map((pkgInfo, idx) =>
                <PkgView
                  pkgInfo ={pkgInfo}
                  key={idx}
                  showPkgStickerFunc = {()=> this.setState({showPkgSticker : true, selectedPkg:pkgInfo.id})}
                />
            )
          }
        </div>
      );
    }
  }

}

export default PkgListView
