import React from "react";
import { Theme, withStyles, createStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Home from '../../layouts/Home'
import CategoryView from "../../layouts/CategoryView";
import { useTabIndexContext, useTabIndexDispatch } from "../../TabIndexContext";
import { useTranslation } from "react-i18next";
import MyTabs from "../myTab/MyTabs";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`main-tabpanel-${index}`}
      aria-labelledby={`main-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.greyscaleColorTheme.darkGrey.main,
      flexGrow: 1,
    },
  })
)((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "1200px",
      margin: "auto",
      textTransform: "none",
      flexGrow: 1,
      color: theme.greyscaleColorTheme.grey2.main,
      ...theme.customTypography.body3,
      borderBottom: "0.5px solid #E5E5E5",
      "&$selected": {
        color: theme.greyscaleColorTheme.darkGrey.main,
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

function a11yProps(index: any) {
  return {
    id: `main-tab-${index}`,
    "aria-controls": `main-tabpanel-${index}`,
  };
}

export default function MainTab() {
  const dispatch = useTabIndexDispatch();
  const contextIndex = useTabIndexContext();
  const { t } = useTranslation('store');

  function tabIndexDispatch(index: number) {
    switch (index) {
      case 0:
        dispatch({
          type: "HOME",
          index: 0,
        });
        break;
      case 1:
        dispatch({
          type: "CATEGORY",
          index: 1,
        });
        break;
    }
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    tabIndexDispatch(newValue);
  };
  
  return (
    <div>
      <div>
        <MyTabs value={contextIndex.index} onChange={handleChange}/>
        {/* <StyledTabs value={contextIndex.index} onChange={handleChange}>
          <StyledTab
            label={t("main_tab_home")}
            aria-label="home"
            {...a11yProps(0)}
          />
          <StyledTab
            label={t("main_tab_category")}
            aria-label="Category"
            {...a11yProps(1)}
          />
        </StyledTabs> */}
      </div>
      <TabPanel value={contextIndex.index} index={0}>
        <Home></Home>
      </TabPanel>
      <TabPanel value={contextIndex.index} index={1}>
        <CategoryView />
      </TabPanel>
    </div>
  );
}
