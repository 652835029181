import { MJTErrorType } from '../../types'

export function convertErrorCodeToMJTErrorType(
  errorCode: number | null
): MJTErrorType {
  let errorType = MJTErrorType.ETC

  switch (errorCode) {
    case 4008:
      errorType = MJTErrorType.NETWORK
      break
    case 5000:
      errorType = MJTErrorType.INTERNALSERVICE
      break
    case 9000:
      errorType = MJTErrorType.ETC
      break
    case 4001:
    case 4003:
      errorType = MJTErrorType.SESSION
      break
    default:
      errorType = MJTErrorType.ETC
      break
  }

  return errorType
}
