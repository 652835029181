import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'

import MJTContext from '../../Core/MJTContext'
import MJTStickerPack from '../../Core/model/outter/MJTStickerPack'
import generateSubdomain from '../../Core/utils/generateSubdomain'
import { useTranslation } from 'react-i18next';

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

const applicationId = MJTContext.getInstance().applicationId
const STORE_URL = `https://${generateSubdomain(applicationId)}.sticker-store.mojitok.com`

export interface PackInfoProps {
  stickerPack: MJTStickerPack
  isPurchased: boolean
}

export default function PackInfo({ stickerPack, isPurchased }: PackInfoProps) {
  const { thumbnails, creator, name } = stickerPack
  const language = navigator.language === 'ko' ? 'ko' : 'en'
  const { t } = useTranslation();

  // TODO: (임시대응) ownership sync 시 새 팩이 추가되는 경우 에러 고치기.
  if (!(thumbnails && creator && name)) return (
    <div
      className='hidden'
    />
  );

  const creatorName = creator?.nickname.find(
    (nickname) => nickname.language === language
  )?.content
  const packName = name.find((nameData) => nameData.language === language)?.content
  const creatorOutlink = creator?.SNSs[0]?.url ?? 'https://instagram.com/stickerfarm_mojitok'

  return (
    <div className={styles['packinfo-wrapper']}>
      <div className={styles['packinfo-data-wrapper']}>
        <img src={thumbnails[0].url} alt='thumbnail' />
        <div className={styles['packinfo-text-wrapper']}>
          <Typography variant='h3' className={styles['packinfo-text']}>
            {packName}
          </Typography>
          <Typography
            variant='body2'
            className={styles['packinfo-creator-wrapper']}
          >
            <span className={styles['packinfo-creator-name']}>
              {creatorName}
            </span>
            <Link
              underline='none'
              className={styles['packinfo-creator-outlink']}
              onClick={() => window.open(creatorOutlink, '_blank')}
            >
              {t('sticker_pack_info_learn_more_button')}
            </Link>
          </Typography>
        </div>
      </div>
      {isPurchased ? null : (
        <div className={styles['packinfo-purchase-btn-wrapper']}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => window.open(STORE_URL, '_blank')}
          >
            {t('sticker_pack_info_purchase_button')}
          </Button>
        </div>
      )}
    </div>
  )
}
