import React from 'react';
import { makeStyles } from '@material-ui/core';
import AnimationField from './AnimationField';
import TextContent from './TextContent';

const useStyles = makeStyles((theme) => ({
    container:{
        display:'flex',
        width:'100%',
        margin:'50px 0',
        [theme.breakpoints.down('xs')]: {
            width:'100%',
            flexWrap:'wrap',
            flexDirection:'column-reverse'
        },
    },
    textSection:{
        width:'50%',
        [theme.breakpoints.down('xs')]: {
            width:'100%',
        },

    },
    aniSection:{
        width:'50%',
        [theme.breakpoints.down('xs')]: {
            width:'200%',
        },
    }
}))
export default function Home({tab,setTab}){
    const classes = useStyles()
    return <div className={classes.container}>
        <section className={classes.textSection}><TextContent tab={tab} /></section>
        <section className={classes.aniSection}><AnimationField tab={tab} setTab={setTab}/></section>
    </div>
}