import React, { useReducer, useContext, createContext, Dispatch } from 'react'

type State = {
  isSessionExpired: boolean
}

type Action =
  | { type: 'SESSION_EXPIRED'; isSessionExpired: boolean }
  | { type: 'SESSION_VALID'; isSessionExpired: boolean }

type SampleDispatch = Dispatch<Action>

const SessionStateContext = createContext<State | null>(null)
const SessionDispatchContext = createContext<SampleDispatch | null>(null)

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SESSION_VALID':
      return {
        ...state,
        isSessionExpired: false
      }
    case 'SESSION_EXPIRED':
      return {
        ...state,
        isSessionExpired: true
      }
    default:
      throw new Error('Unhandled action')
  }
}

export function SessionProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    isSessionExpired: false
  })

  return (
    <SessionStateContext.Provider value={state}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  )
}

export function useSessionState() {
  const state = useContext(SessionStateContext)
  if (!state) throw new Error('Cannot find SessionProvider')
  return state
}

export function useSessionDispatch() {
  const dispatch = useContext(SessionDispatchContext)
  if (!dispatch) throw new Error('Cannot find SessionProvider')
  return dispatch
}
