import React from 'react';
let key =0
const getKey = ()=>{
  key++
  return key
}
export default function HighlightableText({ content, highlight }) {
  const highlightIndex = highlight ? content.indexOf(highlight) : -1;
  const highlightIndexEnd = highlightIndex + highlight.length - 1;
  const taggedText = content.split('').map(char => {
    return {
      char,
      type: 'plain',
    };
  });
  if (highlightIndex >= 0) {
    for (let hIndex = highlightIndex; hIndex <= highlightIndexEnd; hIndex++) {
      taggedText[ hIndex ].type = 'highlight';
    }
  }
  const rootElementChildren = [];
  let pChildBuffer = [];
  let preState =""
  let textBuffer = ""
  for (const cData of taggedText) {
    if (cData.char === '\n') {
      if(textBuffer.length>0){
        pChildBuffer.push(React.createElement('span', {
          style: preState === 'highlight' ? {
            color: '#3278FF',
          } : null
        }, [
        textBuffer
        ]));
        textBuffer = cData.char
        preState = ""
      }
      rootElementChildren.push(React.createElement('p', {
        key:getKey(),
        style: {
          fontWeight: 300,
          margin: 0
        }
      }, pChildBuffer));
      pChildBuffer = [];
    } else {
        if(preState===""||preState ===cData.type){
          textBuffer += cData.char
          preState = cData.type
        }
        else {
          pChildBuffer.push(React.createElement('span', {
        key:getKey(),

            style: preState === 'highlight' ? {
              color: '#3278FF',
            } : null
          }, [
          textBuffer
          ]));
          textBuffer = cData.char
          preState = cData.type
        }
    }
  }
  if(textBuffer.length>0){
    pChildBuffer.push(React.createElement('span', {
      key:getKey(),

      style: preState === 'highlight' ? {
        color: '#3278FF',
      } : null
    }, [
    textBuffer
    ]));
  }
  rootElementChildren.push(React.createElement('p', {
    key:getKey(),

    style: {
      fontWeight: 300,
      margin: 0
    }
  }, pChildBuffer));
  const rootElement = React.createElement('div', {}, [
    rootElementChildren
  ]);
  return rootElement;
}
