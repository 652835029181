/**
 * 언어 이름 - 코드 매핑
 */
export const LanguageCode = {
  KOREAN: 'ko',
  ENGLISH: 'en',
  INDONESIAN: 'id',
  ARABIC: 'ar',
  CHINESE: 'zh',
  FRENCH: 'fr',
  GERMAN: 'de',
  ITALIAN: 'it',
  JAPANESE: 'ja'
} as const

/**
 * 언어 코드 - 이름 매핑
 */
export const LanguageName = {
  ko: 'Korean',
  en: 'English',
  id: 'Indonesian',
  ar: 'Arabic',
  zh: 'Chinese',
  fr: 'French',
  de: 'German',
  it: 'Italian',
  ja: 'Japanese'
} as const
