import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import callApi from '../utils/callAPI'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import TabPanel from './TabPanel'
import {
  EmotionIcon,
  RecentIcon,
  SearchIcon,
  SettingIcon,
  StoreIcon,
  TrendingIcon
} from './CustomIcons'
import TabIconInPack from './TabIconInPack'
import { MJTOnStickerChoice, MJTViewType } from '../../types'

import MJTContent from '../../Core/MJTContent'
import generateSubdomain from '../../Core/utils/generateSubdomain'
import MJTContext from '../../Core/MJTContext'
import MJTStickerPack from '../../Core/model/outter/MJTStickerPack'
import MJTOwnership from '../../Core/model/outter/MJTOwnership'
import { getInstantAuthUrl } from '../utils/getInstantAuthUrl'
import SessionErrorView from './error/SessionErrorView'
import { CircularProgress } from '@material-ui/core'
import styles from '../styles.module.css'
import { useSessionState } from '../SessionStateContext'
import ErrorView from './error/ErrorView'
import { convertErrorCodeToMJTErrorType } from '../utils/convertErrorCodeToErrorType'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative'
  },
  appBar: {
    top: 'auto',
    bottom: 0
  }
}))

const StyledTabs = withStyles({
  root: {
    height: '42px',
    alignItems: 'center',
    background: '#fff',
    minHeight: 42,
    borderTop: '0.5px solid #E5E5E5'
  },
  flexContainer: {
    justifyContent: 'space-between'
  },
  scrollButtons: {
    width: '16px'
  }
})(Tabs)

const useTabStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    color: '#979797',
    padding: '6px 0px'
  }
}))

interface TabInfo {
  viewType: MJTViewType
  icon?: JSX.Element
  stickerPack?: MJTStickerPack
  thumbnailUrl?: string
}

const basicTabInfos: TabInfo[] = [
  {
    viewType: MJTViewType.RECENT,
    icon: <RecentIcon />
  },
  {
    viewType: MJTViewType.TRENDING,
    icon: <TrendingIcon />
  },
  {
    viewType: MJTViewType.EMOTION_TAG,
    icon: <EmotionIcon />
  },
  {
    viewType: MJTViewType.SEARCH,
    icon: <SearchIcon />
  },
  {
    viewType: MJTViewType.SETTING,
    icon: <SettingIcon />
  },
  {
    viewType: MJTViewType.STORE,
    icon: <StoreIcon />
  }
]

export interface MainViewProps {
  onStickerChoice: MJTOnStickerChoice
}

export default function MainView({ onStickerChoice }: MainViewProps) {
  const classes = useStyles()
  const tabClasses = useTabStyles()
  const [value, setValue] = useState(0)
  const [status, setStatus] = useState('READY') //상태 추가
  const [errorCode, setErrorCode] = useState(null)
  const [ownerships, setOwnerships] = useState<MJTOwnership[]>([])
  const [tabInfos, setTabInfos] = useState<TabInfo[]>([])
  const isInitialMount = useRef(true)
  const isInitialMountSessionChecker = useRef(true)
  const isSessionValid = useSessionState()

  async function getOwnerShipPack() {
    setStatus('READY')
    try {
      const ownerships = await callApi(MJTViewType.OWNERSHIP)
      if (!ownerships) throw 'no ownership'
      setOwnerships(ownerships as MJTOwnership[])
      setStatus('SUCCESS')
    } catch (err) {
      console.log(err)
      if (err.code) {
        setErrorCode(err.code)
      } else {
        setErrorCode(null)
      }
      setStatus('ERROR')
    }
  }

  useEffect(() => {
    getOwnerShipPack()
    return () => {}
  }, [])

  useEffect(() => {
    if (isInitialMountSessionChecker.current) {
      isInitialMountSessionChecker.current = false
    } else {
      setErrorCode(4001 as any)
      setStatus('ERROR')
    }
  }, [isSessionValid])

  const messageListener = (e: MessageEvent) => {
    const applicationId = MJTContext.getInstance().applicationId
    const storeUrl = `https://${generateSubdomain(
      applicationId
    )}.sticker-store.mojitok.com`
    if (e.origin === storeUrl) {
      if (e.data === 'paymentSuccess') {
        getOwnerShipPack()
      }
    }
  }
  useEffect(() => {
    window.addEventListener('message', messageListener)
    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [])

  function getPreloadPacks(props: TabInfo[]) {
    const preloadPack = MJTContent.getInstance()
      .getPreloadPacks()
      .map((data) => {
        const thumbnailUrl = data.thumbnails.find(
          (thumbnail) => thumbnail.spec === 'w250'
        )?.url

        const tabInfo: TabInfo = {
          viewType: MJTViewType.DOWNLOADED_PACK,
          stickerPack: data,
          thumbnailUrl
        }

        return tabInfo
      })

    const newTabInfos = [...props]
    const addPosition = newTabInfos.findIndex(
      (data) => data.viewType === MJTViewType.TRENDING
    )
    newTabInfos.splice(addPosition, 0, ...preloadPack)
    setTabInfos(newTabInfos)

    // 설정 UI 일 경우 Position 을 기억해 둔다.
    if (
      value ===
      tabInfos.findIndex((data) => data.viewType === MJTViewType.SETTING)
    ) {
      setValue(
        newTabInfos.findIndex((data) => data.viewType === MJTViewType.SETTING)
      )
    } else {
      setValue(addPosition)
    }
  }

  useEffect(() => {
    const ownedPack = ownerships.map((owenership) => {
      const tabInfo: TabInfo = {
        viewType: MJTViewType.DOWNLOADED_PACK,
        stickerPack: owenership.stickerPack,
        thumbnailUrl: owenership.stickerPack?.thumbnails[0].url
      }
      return tabInfo
    })

    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const newTabInfos = [...basicTabInfos]
      const addPosition = basicTabInfos.findIndex(
        (data) => data.viewType === MJTViewType.TRENDING
      )
      newTabInfos.splice(addPosition, 0, ...ownedPack)
      getPreloadPacks(newTabInfos)
    }
  }, [ownerships])

  const handleChange = async (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    if (tabInfos[newValue].viewType === MJTViewType.STORE) {
      const paymentPlatform = MJTContext.getInstance().paymentPlatform
      const uiLanguage = MJTContext.getInstance().uiLang
      const params = MJTContext.getInstance().storeSetting
      const storeUrl = await getInstantAuthUrl({
        page: 'home',
        packId: undefined,
        payment: paymentPlatform,
        lang: uiLanguage
      })
      window.open(storeUrl, '_blank', params)
    } else {
      setValue(newValue)
    }
  }

  return status === 'READY' ? (
    <div className={styles['ready']}>
      <CircularProgress />
    </div>
  ) : status === 'ERROR' ? (
    <ErrorView
      errorType={convertErrorCodeToMJTErrorType(errorCode)}
      onClickRefresh={() => getOwnerShipPack()}
    />
  ) : (
    <div className={classes.root}>
      {tabInfos.map((tabInfo, idx) => (
        <TabPanel
          value={value}
          index={idx}
          key={idx}
          viewType={tabInfo.viewType}
          packData={tabInfo.stickerPack}
          onStickerChoice={onStickerChoice}
          ownershipStateHandler={[ownerships, setOwnerships]}
        />
      ))}
      <AppBar position='absolute' color='default' className={classes.appBar}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='on'
          indicatorColor='primary'
          textColor='primary'
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {tabInfos.map((tabInfo, idx) => (
            <Tab
              className={tabClasses.root}
              key={idx}
              icon={
                tabInfo.icon ? (
                  tabInfo.icon
                ) : (
                  <TabIconInPack
                    url={tabInfo.thumbnailUrl!}
                    isSelected={value === idx}
                  />
                )
              }
            />
          ))}
        </StyledTabs>
      </AppBar>
    </div>
  )
}
