import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Tags from './Tags/Tags';
import FindTagSticker from './Tags/FindTagSticker';
import Popular from './Popular';

class Galaxy extends Component {
  render(){
    return (
      <div>
      {/*
      <div style={{background: '#dddddd'}}>
        <Link to={'/galaxy/taglist'}> go taglist </Link>
        <Link to={'/galaxy/popular'}> go popular </Link>
      </div>
      */}
        <Switch>
          <Redirect exact from="/galaxy" to="/galaxy/taglist"/>
          <Route path="/galaxy/taglist" component={Tags}/>
          <Route path="/galaxy/tags/:name" component={FindTagSticker}/>
          <Route path="/galaxy/popular" component={Popular}/>
        </Switch>
      </div>
    );
  }
}

export default Galaxy;
