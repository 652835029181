export interface MJTStickerInfo {
  url: string,
  id: string
}

export type MJTOnStickerChoice = (
  stickerInfo?: MJTStickerInfo | undefined
) => void

export enum MJTViewType {
  SPOTLIGHT = 'SPOTLIGHT',
  DOWNLOADED_PACK = 'DOWNLOADED_PACK',
  TRENDING = 'TRENDING',
  EMOTION_TAG = 'EMOTION_TAG',
  RTS = 'RTS',
  SEARCH = 'SEARCH',
  RECENT = 'RECENT',
  STORE = 'STORE',
  SETTING = 'SETTING',
  OWNERSHIP = 'OWNERSHIP'
}

export enum MJTPaymentPlatform {
  EXIMBAY = 'EXIMBAY',
  BOOTPAY = 'BOOTPAY',
  NONE = 'NONE'
}

export enum MJTErrorType {
  NETWORK = 'NETWORK_ERROR',
  INTERNALSERVICE = 'INTERNAL_SERVICE_ERROR',
  ETC = 'ETC_ERROR',
  SESSION = 'SESSION_ERROR'
}
