import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import MJTContext, { UI_LANGUAGE_CODE } from '../Core/MJTContext'
import { LanguageCode, LanguageName } from '../Core/types/language'

import tranEn from './Files/en.json'
import tranKo from './Files/ko.json'
import tranId from './Files/id.json'


/**
 * 언어별 번역
 */
const resources: Record<UI_LANGUAGE_CODE, { translation: any }> = {
  en: { translation: tranEn },
  ko: { translation: tranKo },
  id: { translation: tranId }
}

const userLanguage = window.navigator.language  //IE >= 11 , Chrome O , FireFox O

i18n.use(initReactI18next).init({
  resources,
  lng: MJTContext.getInstance().uiLang,
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  }
})

export { LanguageCode, LanguageName }
export default i18n
