import React, { Component } from 'react';
import { Spin } from 'antd';
import StickerListView  from '../components/StickerListView';
import callAPI from '../utils/callAPI';

import '../styles/Trending.css';

class Trending extends Component {
  constructor(props){
    super(props);
    this.state={
      loading: true,
      trendingList:[]
    };
  }
  async componentDidMount(){
    //trending api 가져오기
    const trendingList=await callAPI('/trending');

    if( !trendingList.error && trendingList.meta.message === "OK") {
      const stickerList = trendingList.result.reduce((prev, curr) =>  prev.stickers.concat(curr.stickers));

      this.setState({
        trendingList:stickerList,
        loading: false,
        rid: trendingList.meta.requestId
      })
    }
    return Promise.resolve();
  }
  render() {

    return (
    <Spin tip="Loading..." spinning={this.state.loading}>
      <div style={{minHeight:200}}>
        {
          this.state.trendingList.length > 0 ?
            this.state.trendingList.map((item, index)=>{
              const changeUrl = item.images.w300 ? item.images.w300.url.replace("http://", "https://") : item.images.w618.url.replace("http://", "https://");
              return (
                <StickerListView
                  imageUrl={changeUrl}
                  stickerId = {item.id}
                  key={index}
                  rid={this.state.rid}
                  className="trending-sticker-box"
                  />
              )
            })
            :null
        }
      </div>
    </Spin>
    );
  }
}


export default Trending;
