import React, { useEffect, useState } from 'react'
import { searchStickerByText } from './utils/searchStickerByText'
import { MJTOnStickerChoice, MJTViewType, MJTStickerInfo } from '../types'
import MJTSticker from '../Core/model/outter/MJTSticker'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from './styles.module.css'

export interface MJTRTSViewProps {
  text: string
  width: string | number
  height: string | number
  onStickerChoice: MJTOnStickerChoice
}

export default function MJTRTSView(props: MJTRTSViewProps) {
  const { text, width, height, onStickerChoice } = props
  const [stickers, setStickers] = useState<MJTSticker[]>([])

  useEffect(() => {
    async function callT2S(text: string) {
      const searchedStickers = await searchStickerByText(text)
      if (searchedStickers) {
        setStickers(searchedStickers)
      }
    }

    callT2S(text)
  }, [text])

  return (
    <div>
      <div
        style={{ width, height, opacity: stickers && stickers.length > 0 ? 1 : 0 }}
        className={styles['rts-wrapper']}
      >
        {stickers && stickers.length > 0 &&
          stickers.map((sticker) => (
            <img
              src={sticker.images.find((img) => img.spec === 'w300')?.url}
              key={sticker.id}
              className={styles['rts-sticker']}
              onClick={() => {
                const url = sticker.images.find((img) => img.spec === 'w300')?.url

                const stickerInfo: MJTStickerInfo = {
                  id: sticker.id,
                  url: url ?? ''
                }

                onStickerChoice(stickerInfo)
              }}
            />
          ))}
      </div>
    </div>
  )
}
