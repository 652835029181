//props으로 받은 패키지의 정보와 스티커를 보여주는 view
import React from 'react';
import StickerListView from './StickerListView';


const  PkgStickerView = (props) => {

  const { thumbnail, name, creator, stickers } = props.pkgInfo;
  const rid = props.rid;

  return(
    <div style={{height: 'inherit'}}>
      <img src={require('../img/icon-back.svg')} onClick={props.isBack} alt="back-taglist" style={{margin:5}}/>
      <div style={{display:'flex', alignItems: 'center', marginLeft: '4.8%', marginBottom:18}}>
        <img src={thumbnail} alt="thumbnail" style={{minWidth:120, width:'28.5%', marginRight:15}}/>
        <div>
          <p style={{color: '#000000', marginTop:11, marginBottom:0, fontSize:16}}>{name}</p>
          <p style={{color: '#bababa', fontSize:14}}>{creator}</p>
        </div>
      </div>
      <div style={{backgroundColor: '#f3f3fa' , minHeight: '100%'}}>
      {
        stickers.map((sticker, idx) => {
          const changeUrl = sticker.images.w300 ? sticker.images.w300.url.replace("http://", "https://") : sticker.images.w618.url.replace("http://", "https://");
          return(
            <StickerListView
              imageUrl={changeUrl}
              stickerId = {sticker.id}
              key={idx}
              rid={rid}
              />
          )
        })
      }
      </div>

    </div>
  );

}

export default PkgStickerView
