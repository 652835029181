import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";

import MyPackTable, { MyPack } from "../components/mypack/MyPackTable";
import MyPackEmpty from "../components/mypack/MyPackEmpty";
import { getOwnerships } from "../utils/ownershipInSessionStorage";

export interface MyPacksProps {
  isPC: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  myPack: {
    display: "flex",
    width: "100%",
  },
  myPackTable: {
    display: "flex",
    width: "100%",
  },
  myPageTitle: {
    marginTop: "48px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  myPackTitle: {
    marginTop: "56px",
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
}));

function MyPacks({ isPC }: MyPacksProps) {
  const classes = useStyles();
  const [packs, setPacks] = useState<MyPack[]>([]);
  const { t } = useTranslation('store');

  useEffect(() => {
    try {
      const ownerships = getOwnerships();

      const packs = ownerships.map(
        (ownership): MyPack => {
          const pack = ownership.stickerPack!!;

          let thumbnail: string;
          try {
            thumbnail = pack.thumbnails!!.find(
              (image) => image.spec === "w250"
            )!!.url;
          } catch (err) {
            console.log(`Failed to get thumbnail from ownerships`);
            thumbnail = "";
          }

          return {
            id: pack.id,
            imageUrl: thumbnail,
            creatorNickname: pack.creator!!.nickname.find(
              (name) => name.language === "en"
            )!!.content,
            packName: pack.name.find((name) => name.language === "en")!!
              .content,
            dueDate: ownership.dueDate
              ? moment(ownership.dueDate).toDate()
              : undefined,
          };
        }
      );

      setPacks(packs);
    } catch (err) {
      console.log(
        "Failed to get pack information from sessionStorage, err: ",
        err
      );
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.myPack}>
        {isPC && (
          <>
            <Typography className={classes.myPageTitle} variant="h2">
              {t("nav_drawer_menu_my_page")}
            </Typography>
            <Typography className={classes.myPackTitle} variant="h6">
              {t("nav_drawer_menu_my_stickers")}
            </Typography>
          </>
        )}
        {packs.length > 0 ? (
          <div className={classes.myPackTable}>
            <MyPackTable packs={packs} />
          </div>
        ) : (
          <div className={classes.myPackTable}>
            <MyPackEmpty />
          </div>
        )}
      </div>
    </div>
  );
}

export default MyPacks;
