
export default async function callAPI(apiType){

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const API_VERSION = '/2.0';
  const API_TYPE = apiType;

  let response = await fetch(`${SERVER_URL + API_VERSION + API_TYPE }`, {
    headers: {
      "mojitok-token":process.env.REACT_APP_MOJITOK_TOKEN
    }
  });

  const responseJson = await response.json();

  return responseJson;

}
