import _ from 'lodash';
import Response from '../model/outter/Response';

export interface HttpOptions {
  body?: Object,
  cacheMode?: RequestCache,
}

export default async function request(url: string,
  method: HTTP_METHOD = HTTP_METHOD.GET,
  headers: Headers = new Headers(),
  options?: HttpOptions): Promise<Response> {

  headers.append('Content-Type', 'application/json');
  try {
    const res = await (options?.body ? fetch(url, { method, headers, cache: options?.cacheMode, body: JSON.stringify(options?.body) })
      : fetch(url, { method, headers, cache: options?.cacheMode }));

    return new Response(res.status, await res.json())
  } catch (err) {
    throw err;
  }
}

export enum HTTP_METHOD {
  POST = "POST",
  GET = "GET"
}