import React from "react";

let id=0

export default ({ color, fill }) =>{ 
  id += 1
  return (<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id={`mask${id}`} masktype="alpha" maskUnits="userSpaceOnUse" x="12" y="12" width="18" height="18">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M24.502 23H23.708L23.432 22.726C24.407 21.589 25 20.115 25 18.5C25 14.91 22.09 12 18.5 12C14.91 12 12 14.91 12 18.5C12 22.09 14.91 25 18.5 25C20.115 25 21.588 24.408 22.725 23.434L23.001 23.708V24.5L27.999 29.491L29.49 28L24.502 23ZM18.5 23C16.014 23 14 20.986 14 18.5C14 16.015 16.014 14 18.5 14C20.985 14 23 16.015 23 18.5C23 20.986 20.985 23 18.5 23Z"
        fill="white" />
    </mask>
    <g mask={`url(#mask${id})`}>
      <rect x="9" y="9" width="24" height="24" fill={fill} />
    </g>
  </svg>
);}