import React from 'react'
import { makeStyles } from '@material-ui/core';

import EmotionIcon from './icons/EmotionIcon';
import RecentIcon from './icons/RecentIcon';
import SearchIcon from './icons/SearchIcon';
import SettingIcon from './icons/SettingIcon';
import StoreIcon from './icons/StoreIcon';
import TrendingIcon from './icons/TrendingIcon';
import HighlightableText from './HighlightText';


const descriptionSettings = [
  {
    title: 'Recent',
    content: 'Stickers most recently sent by the user (12)',
    highlight: '_-_-_-',
    svgIcon: RecentIcon,
  },
  {
    title: 'Default Preloaded Packs',
    content: 'Packs provided to all users by default. Number of packs (2 by default) and the selection of packs may be discussed with mojitok. ',
    highlight: 'all users',
  },
  {
    title: 'Default Preloaded Packs',
    content: 'Packs provided to all users by default. Number of packs (2 by default) and the selection of packs may be discussed with mojitok. ',
    highlight: 'all users',
  },
  {
    title: 'Trending',
    content: 'Recommended popular stickers for users to send. 16 individual stickers are displayed.',
    highlight: 'Recommended popular stickers',
    svgIcon: TrendingIcon,
  },
  {
    title: 'Emotion Tag',
    content: 'A menu of emotion, greeting, or situation tags that are frequently used. For each tag, 40 individual stickers are displayed and the selection is updated weekly. ',
    highlight: 'A menu of emotion, greeting, or situation tags',
    svgIcon: EmotionIcon,
  },
  {
    title: 'Search',
    content: 'A list of relevant stickers that match the keyword or sentence the user types into the search bar. ',
    highlight: 'stickers that match the keyword or sentence',
    svgIcon: SearchIcon,
  },
  {
    title: 'Settings',
    content: 'User may reorder packs and choose to either activate or archive each pack. ',
    highlight: '_-_-_-',
    svgIcon: SettingIcon,
  },
  {
    title: 'Store',
    content: 'Users can search for sticker packs, download free packs, and purchase premium packs in the store. ',
    highlight: '_-_-_-',
    svgIcon: StoreIcon,
  },

];

const useStyles = makeStyles((theme) => ({
  container:{
    position: 'absolute',
    zIndex: 10000,
    pointerEvents: 'none',
    flexDirection: 'column-reverse',
    display:'flex',
  },
  expendIconContainer:{
    position: 'relative',
    width: 42,
    height: 42,
    borderRadius: 21,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 1px 2px 2px rgba(187, 187, 187, 0.25)',
    transform:'scale(1.571)'
  },
  iconContainer:{
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  line:{
    width: 1,
    height: 40,
    backgroundColor: '#3278FF',
  },
  textContainer:{
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid rgb(50,120,255)',
    borderRadius: '8px',
    width: 420,
    padding: '24px',
    boxShadow:'0px 3px 3px rgba(150,150,150,0.3)',
    fontFamily: 'Gmarket Sans TTF',
    fontSize: 18,
    lineHeight: '160%'
  }
}))
  const fixedWidth = 508
const circleR = 6
const hoverEffectWidth = 37.71
const hoverEffectHeight = 37.71
const Icon = ()=>{
    
}
const HoverEffectSmallView = ({ iconPackButtonList }) => {
    const classes = useStyles()
    if(!iconPackButtonList){
      return <></>
    }

    const list = Array.from(iconPackButtonList)
    return (<>

    {list.map((el,i)=>{
      const iconImg = el.querySelector('img')
        const iconLeft = i*(360/list.length)
      return (
        <div
          key={i}
          className={`hover-elem hover-elem-${i} ${classes.container}`}
          style={{
            top:0,
            left:0,
            width: 420,
            height:640,
          }}
        >
          <div style={{
              left:iconLeft
          }} className={classes.expendIconContainer}>
            <div className={classes.iconContainer}>
                {iconImg ?     
                    <img
                        width={24}
                        height={24}
                        src={iconImg.src}
                        alt={iconImg.alt}
                    ></img> 
                    :
                    <>
                    {
                        descriptionSettings[i].svgIcon({fill:'#3268FF'})
                    }
                    </>
                }
            </div>
            
            <svg
              style={{
                position: 'absolute',
                top: '0px',
                transform: `translateY(-${circleR}px)`,
              }}
              width={2 * circleR}
              height={2 * circleR}
              fill='#3278FF'
              viewBox={`0 0 100 100`}
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx={50} cy={50} r={50} />
            </svg>
      
          </div>
    
          <div style={{
              marginLeft:iconLeft+21
          }}  className={classes.line}></div>
          <div className={classes.textContainer}>          
            <b>
              {descriptionSettings[i].title}
            </b>
            <HighlightableText
              content={descriptionSettings[i].content}
              highlight={descriptionSettings[i].highlight}
            />
            {descriptionSettings[i].title==='Store'&& <div style={{
              fontWeight:700,
              marginTop:'12px',
              color:'#3278FF'
            }}>
                Double-tap to view the store
            </div>}
            </div>
          </div>
      )

    })
    }</>
    )
  }

  export default React.memo(HoverEffectSmallView)