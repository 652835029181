import { MJTPaymentPlatform } from '../types'
import generateSubdomain from './utils/generateSubdomain'

// 언어 설정들
import type { ElementType } from './types'
import { LanguageCode } from './types/language'

/** UI 언어 코드 목록 */
export const UI_LANGUAGE_CODES = [
  LanguageCode.ENGLISH,
  LanguageCode.KOREAN,
  LanguageCode.INDONESIAN
] as const
export type UI_LANGUAGE_CODE = ElementType<typeof UI_LANGUAGE_CODES>
/** 검색 언어 코드 목록 */
export const SEARCH_LANGUAGE_CODES = [LanguageCode.ENGLISH] as const
export type SEARCH_LANGUAGE_CODE = ElementType<typeof SEARCH_LANGUAGE_CODES>
/** RTS 언어 코드 목록 */
export const RTS_LANGUAGE_CODES = [LanguageCode.ENGLISH] as const
export type RTS_LANGUAGE_CODE = ElementType<typeof RTS_LANGUAGE_CODES>

export default class MJTContext {
  private static instance: MJTContext
  private _applicationId: string = ''
  private _applicationToken: string = ''
  private _userId: string = ''
  private _rtsLangs: Array<RTS_LANGUAGE_CODE> = ['en']
  private _searchLang: SEARCH_LANGUAGE_CODE = 'en'
  private subDomain: string = ''
  private _paymentPlatform: string = MJTPaymentPlatform.NONE
  private _mainColor: string = '#3278FF'
  private _uiLang: UI_LANGUAGE_CODE = 'en'
  private _storeSetting: string = ''

  get paymentPlatform(): string {
    return this._paymentPlatform
  }
  set paymentPlatform(paymentPlatform: string) {
    this._paymentPlatform = paymentPlatform
  }

  get mainColor(): string {
    return this._mainColor
  }
  set mainColor(color: string) {
    this._mainColor = color
  }

  get applicationId(): string {
    return this._applicationId
  }
  set applicationId(applicationId: string) {
    this._applicationId = applicationId
    this.subDomain = generateSubdomain(this._applicationId)
  }

  get userId(): string {
    return this._userId
  }
  set userId(userId: string) {
    this._userId = userId
  }

  get applicationToken(): string {
    return this._applicationToken
  }
  set applicationToken(token: string) {
    this._applicationToken = token
  }

  get baseUrl(): string {
    return `https://${this.subDomain}.cloud.mojitok-api.com`
  }

  get rtsLangs(): Array<RTS_LANGUAGE_CODE> {
    return this._rtsLangs
  }
  set rtsLangs(langs: Array<RTS_LANGUAGE_CODE>) {
    this._rtsLangs = langs
  }
  get searchLang(): SEARCH_LANGUAGE_CODE {
    return this._searchLang
  }
  set searchLang(lang: SEARCH_LANGUAGE_CODE) {
    this._searchLang = lang
  }
  set uiLang(lang: UI_LANGUAGE_CODE) {
    this._uiLang = lang
  }
  get uiLang(): UI_LANGUAGE_CODE {
    return this._uiLang
  }
  set storeSetting(storeSetting: string) {
    this._storeSetting = storeSetting
  }
  get storeSetting() {
    return this._storeSetting
  }

  private constructor() {}

  public static getInstance(): MJTContext {
    if (!MJTContext.instance) {
      MJTContext.instance = new MJTContext()
    }

    return MJTContext.instance
  }
}
