import request, { HttpOptions, HTTP_METHOD } from './utils/https';
import Response from './model/outter/Response';
import MJTContext, { RTS_LANGUAGE_CODE, SEARCH_LANGUAGE_CODE } from './MJTContext';
import MJTAuth from './MJTAuth';
import MJTTag2Stickers from './model/outter/MJTTag2Stickers';
import MJTSticker from './model/outter/MJTSticker';
import MJTStickerPack from './model/outter/MJTStickerPack';
import MJTOwnership from './model/outter/MJTOwnership';
import preload from './res/preload';
import MJTException from './model/error/MJTException';
import MJTSearch from './MJTSearch';
import _Dlog from './DLog';
import { LanguageName } from './types/language';

const DLog = _Dlog.getInstance();

const SUPPORTED_LANGS: Array<keyof typeof LanguageName> = ['en', 'ko'];
const noCacheOptions: HttpOptions = {
  cacheMode: 'no-cache'
}

export default class MJTContent {
  private static instance: MJTContent;

  private constructor() { }

  public static getInstance(): MJTContent {
    if (!MJTContent.instance) {
      MJTContent.instance = new MJTContent();
    }

    return MJTContent.instance;
  }

  /**
  * @deprecated MJTSearch의 text2emojis로 대체되었습니다.
  */
  private async text2Emojis(input: string, lang: string): Promise<string[]> {
    let emojiCategories: string[] = [];
    let response: Response;

    try {
      const url: string = `${MJTContext.getInstance().baseUrl}/text2emojis?q=${input}&lang=${lang}`;
      response = await request(url, HTTP_METHOD.GET);
      const { status, payload } = response;

      emojiCategories = payload.data as string[];
    } catch (e) {
      DLog.e('m ERROR:', e);
      return []
    }

    return emojiCategories
  }

  private async text2Stickers(
    input: string,
    lang: keyof typeof LanguageName
  ): Promise<MJTSticker[]> {
    if (!MJTSearch.getInstance().isDBReady) {
      DLog.i('DB not ready')
      return []
    }
    const t2eResults: string[] = await MJTSearch.getInstance().text2emojis(
      input,
      lang
    )

    if (!SUPPORTED_LANGS.includes(lang)) {
      return []
    }

    let mjtTag2Sticker: MJTTag2Stickers | undefined;

    if (t2eResults.length > 0) {
      const mjtTag2Stickers = await this.getEmojiTag2Stickers();
      mjtTag2Sticker = mjtTag2Stickers.find((t2s: MJTTag2Stickers) => t2s.tag === t2eResults[0]);
    } else {
      return []
    }

    if (!mjtTag2Sticker) {
      return [];
    }

    return mjtTag2Sticker.stickers;
  }

  /**
   * TODO: RTS 와 Search 로직 분리
   * @param input
   * @param lang
   * @returns
   */
  async getRTSStickers(
    input: string,
    lang: RTS_LANGUAGE_CODE
  ): Promise<MJTSticker[]> {
    return await this.text2Stickers(input, lang)
  }

  /**
   * TODO: RTS 와 Search 로직 분리
   * @param input
   * @param lang
   * @returns
   */
  async getSearchResultStickers(
    input: string,
    lang: SEARCH_LANGUAGE_CODE
  ): Promise<MJTSticker[]> {
    return await this.text2Stickers(input, lang)
  }

  getPreloadPacks(): MJTStickerPack[] { return preload.data; }

  async request(url: string, options?: HttpOptions): Promise<any> {
    let response: Response
    try {
      const headers = new Headers();
      const accessToken = await MJTAuth.getInstance().getValidAccessToken();
      headers.append("Mojitok-User-Access-Token", accessToken);
      response = await request(url, HTTP_METHOD.GET, headers, options);
    }
    catch (e) {
      if (window.navigator.onLine === false) {
        throw new MJTException(4008, "Network connection failed.")
      }
      if(e instanceof MJTException){
        throw e
      }else{
        throw new MJTException(4100, e.message ? e.message : "Failed to Call API");
      }
    }
    const { status, payload } = response;

    switch (true) {
      case (status == 200):
        return payload.data
      case (status >= 400 && status <= 499):
        throw new MJTException(4101, payload.data.message)
      case (status >= 500 && status <= 599):
        throw new MJTException(5000, payload.data.message)
      default:
        throw new MJTException(9000, payload.data.message)
    }
  }

  async getOwnership(): Promise<MJTOwnership[]> {
    return await this.request(`${MJTContext.getInstance().baseUrl}/me/ownership?include=STICKER_PACK`, noCacheOptions);
  }

  async getTrendingStickers(): Promise<MJTSticker[]> {
    return await this.request(`${MJTContext.getInstance().baseUrl}/stickers/trending?include=STICKER_PACK&include=CREATOR`);
  }

  async getTop20KeywordTags(): Promise<string[]> {
    return await this.request(`${MJTContext.getInstance().baseUrl}/tags/keyword?type=TOP_20`);
  }

  async getEmotionTag2Stickers(): Promise<MJTTag2Stickers[]> {
    return await this.request(`${MJTContext.getInstance().baseUrl}/tag2stickers/emotion?include=STICKER_PACK&include=CREATOR`);
  }

  async getEmojiTag2Stickers(): Promise<MJTTag2Stickers[]> {
    return await this.request(`${MJTContext.getInstance().baseUrl}/tag2stickers/emoji?include=STICKER_PACK&include=CREATOR`);
  }
}
