import React, { useState } from 'react'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

import StickersView from './StickersView'
import { searchStickers } from '../utils/searchStickers'
import { MJTOnStickerChoice, MJTViewType } from '../../types'
import MJTSticker from '../../Core/model/outter/MJTSticker'

import { useTranslation } from 'react-i18next';
// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

const chipColors = [
  '#FFC453',
  '#FF766D',
  '#56DFBE',
  '#5690FF',
  '#FF86C0',
  '#DA71FF'
]

const useInputStyles = makeStyles((theme) => ({
  root: {
    margin: '0px 8px',
    width: '-webkit-fill-available'
  },
  input: {
    fontSize: '12px',
    lineHeight: '18px'
  },
  underline: {
    borderBottom: '2px solid #000000'
  }
}))

const useSnackBarStyles = makeStyles({
  root: {
    position: 'absolute',
    marginTop: 180
  }
})

const useAlertStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
    background: '#454545',
    padding: '7px 16px 7px 16px',
    color: '#FFFFFF'
  }
})

export interface SearchProps {
  keywords: string[]
  onStickerChoice: MJTOnStickerChoice
}

export default function Search({ keywords, onStickerChoice }: SearchProps) {
  const [text, setText] = useState('')
  const [stickers, setStickers] = useState<MJTSticker[]>([])
  const [open, setOpen] = useState(false)
  const inputClasses = useInputStyles()
  const snackBarkClasses = useSnackBarStyles()
  const alertClasses = useAlertStyles()
  const keywordsToUse = keywords.slice(0, 10)
  const { t } = useTranslation();

  const handlePressEnter = async (word?: string) => {
    const searchedStickers = await searchStickers(word ? word : text)
    setStickers(searchedStickers)
  }

  return (
    <div className={styles['search-wrapper']}>
      <Input
        classes={inputClasses}
        placeholder={t('search_sticker_hint_body')}
        inputProps={{ 'aria-label': 'description' }}
        onChange={(e) => {
          setText(e.target.value)
          if (!text && stickers) {
            setStickers([])
          }
        }}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            if (!text) {
              setOpen(true)
            } else {
              handlePressEnter()
            }
          }
        }}
        endAdornment={
          <InputAdornment
            position='end'
            onClick={
              () => {
                if (!text) {
                  setOpen(true)
                } else {
                  handlePressEnter()
                }
            }}
            className={styles['search-keyword-input-button']}
          >
            <SearchIcon style={{ fontSize: 18 }} />
          </InputAdornment>
        }
        value={text}
      />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={snackBarkClasses}
      >
        <Alert
          icon={false}
          classes={alertClasses}
          onClose={() => setOpen(false)}
        >
          {t('search_sticker_try_keyword_body')}
        </Alert>
      </Snackbar>
      {text && stickers && stickers.length > 0 ? (
        <StickersView
          stickers={stickers}
          onStickerChoice={onStickerChoice}
          viewType={MJTViewType.SEARCH}
          query={text}
        />
      ) : text && stickers && stickers.length === 0 ? (
        <div className={styles['search-keyword-no-stickers-wrapper']}>
          <Typography variant='body2'>
            {t('search_sticker_no_matches_body')}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography
            variant='h1'
            component='h1'
            className={styles['search-keyword-title']}
          >
            {t('search_sticker_subtitle')}
          </Typography>
              {keywordsToUse.map((word, idx) => (
                <Chip
                  key={word}
                  label={'# ' + word}
                  style={{
                    backgroundColor: chipColors[idx % 6],
                    color: '#fff',
                    margin: 4
                  }}
                  onClick={() => {
                    setText(word)
                    handlePressEnter(word)
                  }}
                />
              ))}
            </div>
          )}
    </div>
  )
}
