
export default function(el){
    if(!el){
        return 
    }
    let mouseIsDown= false
    let preScrollLeft = 0
    let preMouseX = 0
    const mouseupHandler = ()=>{
        mouseIsDown=false
    }
    
    el.addEventListener('mousedown',(e)=>{
        e.preventDefault()
        if(e.button===0){
            preScrollLeft = el.scrollLeft
            mouseIsDown=true
            preMouseX = e.x
        }
    })
    document.addEventListener('mouseup',mouseupHandler)
    const mouseMoveHandler = (e)=>{
        if( mouseIsDown){
            const mouseMoveX = e.x - preMouseX
            el.scrollLeft = preScrollLeft - mouseMoveX
        }
    }
    document.addEventListener('mousemove',mouseMoveHandler)
    return ()=>{
        document.removeEventListener('mouseup',mouseupHandler)
    }
}