import React, { useEffect,useRef } from 'react'
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(()=>({
    wrapAbsolute:{
        pointerEvents:'none',
        width:'100%',
        height:'100%',
        position:'absolute',
        left:'0px',
        top:'0px'
    }
}))
export default function WrapAbsolute({children}:{children:JSX.Element|JSX.Element[]}){
    const ref= useRef<HTMLDivElement|null>(null)
    useEffect(()=>{
        // if(ref.current){
        //     const resize_ob = new ResizeObserver(function(entries) {
        //         // since we are observing only a single element, so we access the first element in entries array
        //         let rect = entries[0].contentRect;
            
        //         // current width & height
        //         let width = rect.width;
        //         let height = rect.height;
            
        //         console.log('Current Width : ' + width);
        //         console.log('Current Height : ' + height);
        //     });
        //     resize_ob.observe(ref.current);
        // }
    },[])
    const classes = useStyles()
    return <div ref={ref} className={classes.wrapAbsolute}>{children}</div>
}