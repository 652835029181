import { createMuiTheme } from '@material-ui/core/styles'
import { Shadows } from '@material-ui/core/styles/shadows'
import { capitalize } from 'lodash'

const shadows: Shadows = [
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none'
]

const theme = createMuiTheme({
  shadows,
  palette: {
    primary: {
      light: '#3278FF',
      main: '#3278FF'
    },
    secondary: {
      light: '#979797',
      main: '#979797'
    },
    error: {
      light: '#F46D42',
      main: '#F46D42'
    }
  },
  typography: {
    fontFamily: 'Noto Sans KR',
    h1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.25px',
      textTransform : 'capitalize'
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '-0.25px'
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.1px'
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px'
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '24px'
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '22px'
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      width: 'fit-content'
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: '-0.25px',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: '-0.25px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: '-0.25px',
    }
  },
  overrides: {
    MuiInput: {
      // input 의 underline 효과 제외
      underline: {
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        }
      }
    },
    MuiChip: {
      label: {
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '18px'
      }
    },
    MuiButton: {
      root: {
        padding: '3px 8px'
      },
      contained: {
        boxShadow: 'none'
      }
    }
  }
})

export default theme
