import { initDB } from "./search/initDB";
import { normalizeText } from "./search/text-utils";
import text2emojis, { LANG } from "./search/text2emojis";
import { LanguageName } from './types/language';
import _DLog from './DLog';

const DLog = _DLog.getInstance();

export default class MJTSearch {
  private static instance: MJTSearch

  private _dbName: string = ""
  private _dbVersion: number = 1
  private _isDBReady: boolean = false

  get dbName(): string { return this._dbName }
  set dbName(dbName: string) { this._dbName = dbName }

  get dbVersion(): number { return this._dbVersion }
  set dbVersion(dbVersion: number) { this._dbVersion = dbVersion }

  get isDBReady(): boolean { return this._isDBReady }
  set isDBReady(isDBReady: boolean) { this._isDBReady = isDBReady }

  async text2emojis(input: string, lang: keyof typeof LanguageName) {
    const normalizeInput = normalizeText(input)
    return await text2emojis(normalizeInput, lang.toUpperCase() as LANG)
  }

  private constructor() {
    try {
      initDB()
    }
    catch (e) {
      DLog.e('', e);
    }
  }

  public static getInstance(): MJTSearch {
    if (!MJTSearch.instance) {
      MJTSearch.instance = new MJTSearch();
    }

    return MJTSearch.instance;
  }
}
