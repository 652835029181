import { createMuiTheme, Theme } from '@material-ui/core'
import MJTContext from '../../Core/MJTContext'
import MJTException from '../../Core/model/error/MJTException'

import theme from '../theme'

function getMJTStyleTheme(): Theme {
  const mainColor = MJTContext.getInstance().mainColor
  let mjtColorTheme = theme
  try {
    mjtColorTheme = createMuiTheme({
      ...theme,
      palette: {
        primary: {
          light: mainColor,
          main: mainColor
        }
      },
      overrides: {
        MuiButton: {
          root: {
            backgroundColor: mainColor
          }
        }
      }
    })
  } catch (e) {
    console.log(e.message)
  }

  return createMuiTheme(mjtColorTheme)
}

export default getMJTStyleTheme
