import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../../styles/Tag.css';

const tagInfo=[
                ["LOVING", 'img_in_love.svg', "#ff5c86" ],
                ["HAPPY", 'img_happy.svg', "#ffc672" ],
                ["THANKS", 'img_thanks.svg', "#d377ff" ],
                ["SAD", 'img_sad.svg', "#3bc5ff" ],
                ["EXCITED", 'img_excited.svg', "#7ed321" ],
                ["SORRY", 'img_sorry.svg', "#6ab7ff" ],
                ["SHY", 'img_shy.svg', "#ff98ad"],
                ["LOL", 'img_laugh.svg', "#0050ad" ],
                ["WOW", 'img_wow.svg', "#9013fe" ],
                ["OK", 'img_ok.svg', "#647aff" ],
                ["EMBARRASSED", 'img_embarrassed.svg', "#3eb4ca" ],
                ["HI", 'img_hello.svg', "#50e3c2" ],
                ["GOOD", 'img_very_good.svg', "#1d90ff" ],
                ["SLEEPY", 'img_goodnight.svg', "#4881ff" ],
                ["BIRTHDAY", 'img_hbd.svg', "#ff8346" ],
                ["ANGRY", 'img_angry.svg', "#ff5656" ],
                ["SCARED", 'img_scared.svg', "#ba6a3b" ],
                ["NO", 'img_no.svg', "#ff3d3d" ],
                ["HUNGRY", 'img_hungry.svg', "#9b9b9b" ],
                ["CURIOUS", 'img_curious.svg', "#8b572a" ]
              ];

class Tag extends Component {

  render() {
    return (
      <div className="tag-box">
        <p>II-1. List of Tags API</p>
        <div>
        {
          tagInfo.map((tagInfo, index) =>
            <Link to={`/galaxy/tags/${tagInfo[0]}`} key={index}>
              <div className="tag-btn" style={{ backgroundColor:"#ffffff", border:"2px solid #1d90ff"}}>
                <img src={require(`../../../img/tag_emoji/${tagInfo[1]}`)} alt="tag emotion" className="tag-btn-img"/>
                  <span className="tag-btn-name"># {tagInfo[0]}</span>
              </div>
            </Link>
          )
        }
        </div>
      </div>
    );
  }

}//tag component end

export default Tag;
