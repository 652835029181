import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { styled } from '@material-ui/core/styles'
import { ReactComponent as EmotionSvg } from '../img/Tab/Icon/emotion.svg'
import { ReactComponent as RecentSvg } from '../img/Tab/Icon/recent.svg'
import { ReactComponent as SearchSvg } from '../img/Tab/Icon/search.svg'
import { ReactComponent as SettingSvg } from '../img/Tab/Icon/setting.svg'
import { ReactComponent as StoreSvg } from '../img/Tab/Icon/store.svg'
import { ReactComponent as TrendingSvg } from '../img/Tab/Icon/trending.svg'
import { ReactComponent as StickerPackSvg } from '../img/Tab/Icon/stickerpack.svg'

const MySvgIcon = styled(({ ...parentProps }) => <SvgIcon {...parentProps} />)({
  width: 42,
  height: 42
})

export interface CustomIconProps {
  style?: React.CSSProperties
}

export const EmotionIcon = (props: CustomIconProps) => (
  <MySvgIcon component={EmotionSvg} viewBox='0 0 42 42' {...props} />
)

export const RecentIcon = (props: CustomIconProps) => (
  <MySvgIcon component={RecentSvg} viewBox='0 0 42 42' {...props} />
)

export const SearchIcon = (props: CustomIconProps) => (
  <MySvgIcon component={SearchSvg} viewBox='0 0 42 42' {...props} />
)

export const SettingIcon = (props: CustomIconProps) => (
  <MySvgIcon component={SettingSvg} viewBox='0 0 42 42' {...props} />
)

export const StoreIcon = (props: CustomIconProps) => (
  <MySvgIcon component={StoreSvg} viewBox='0 0 42 42' {...props} />
)

export const TrendingIcon = (props: CustomIconProps) => (
  <MySvgIcon component={TrendingSvg} viewBox='0 0 42 42' {...props} />
)

export const StickerPackIcon = (props: CustomIconProps) => (
  <MySvgIcon component={StickerPackSvg} viewBox='0 0 42 42' {...props} />
)
