import MJTStickerPack from '../model/outter/MJTStickerPack'

export default {
  data: <MJTStickerPack[]>[
    {
      id: 'e3e23eb8-0488-4577-9158-a4e30318044c',
      name: [
        { language: 'en', content: 'ZOOKIZ' },
        { language: 'ko', content: 'ZOOKIZ' }
      ],
      description: [
        {
          language: 'en',
          content: 'My best friend! daily life! # cute # funny'
        },
        {
          language: 'ko',
          content: 'My best friend! daily life! # cute # funny'
        }
      ],
      thumbnails: [
        {
          spec: 'w250',
          url:
            'https://sdk.mojitok.im/99beeb3a3583253857ef130ac7368737_d0f9cd1034.png'
        }
      ],
      creator: {
        id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
        nickname: [
          { language: 'en', content: 'zookiz' },
          { language: 'ko', content: 'zookiz' }
        ],
        SNSs: [
          {
            type: 'INSTAGRAM',
            url:
              'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
            priority: 0
          }
        ]
      },
      product: { productType: 'STICKER_PACK', priceType: 'TIER_2' },
      stickers: [
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '77d7e647-ceea-428c-a322-14d210a331ea',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/4d6ad66c8a_v0pNQDdDft.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '38d2a8e0-d8cf-4d41-9382-ab7b9e9b650e',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/c1f6514eba_ZgDyyvZg5d.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'fb3fbba2-0b4f-4453-9a8f-963edcd1f29b',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/694bc579cd9b5a3509f4f4c9938ad454_0rqXuRgGDj_ikZjCI2U0Y_1DiABENGbP_%E1%84%88%E1%85%A1%E1%86%A8%E1%84%8E%E1%85%B5%E1%86%B7%20.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'a1d0f6a1-ea32-4cd4-98fc-707f7d6d0e45',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/b198d945ee_hgml6Wn12A.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '01900b18-549e-4436-884a-33b7088f11fc',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/6b92c4aff2_C75FMzHuTc.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'cff59888-9840-435c-aaf5-070fb4c78f7a',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/8a7ba213aaf21a59ff8c5b0fb186b1ac_0rqXuRgGDj_ikZjCI2U0Y_j8yD7SFDpI_%E1%84%8B%E1%85%AE%E1%84%8B%E1%85%AE%E1%86%AF.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '665d61f0-5a9c-4b3c-8dcc-dd4d9d7eb904',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/58fb425a07_wEEy2F5Da0.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '70b38b6e-c198-44ff-8124-59455b225206',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/186703fbfd_EaUSyMArBM.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'c2623a5a-cff1-44cb-bc6f-5cf03376d75b',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/67bc464c5c_wZ78dZTMZw.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'bf91e3ea-4944-4c26-9cd4-4df0a865e956',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/68458713cf_svhuVjB4Fi.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'a5b103d5-aad9-47bc-b38d-50cf48711379',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/45e1be3cc8_tASTGBGcoZ.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '952c5cf8-e402-461e-919a-f7cf8a73d589',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/3c1d28ecde_HxhKwqo50j.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '94a92086-2843-4ba0-8904-9ff28cdc0546',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/344e2c4a15_1SKmqIOCmH.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '88da537d-5dcb-492e-9d0c-cae4c66ddd85',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/0fddaeb65a39d9001a586a671f5e671d_0rqXuRgGDj_ikZjCI2U0Y_ayUnG3DS9P_%E1%84%8F%E1%85%A3%E1%84%8B%E1%85%A9.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '506c8286-866f-42c3-9c7f-e23d9424b756',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/f372755d6e_dSBXaA72bM.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '9d71c45a-462a-4927-b6e0-381f5b115624',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/deff9a79c7100561760f7b86628e66fa_0rqXuRgGDj_ikZjCI2U0Y_5qkIaAZWWN_%3F%3F.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '3cfa9da4-755d-4e33-acdf-c183ee4e6909',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/aaf64d47d3d9f2179c3f72f7089aba2f_0rqXuRgGDj_ikZjCI2U0Y_ShBu6tk1HN_%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A7%E1%86%BC%20.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'fa90b192-3354-45cb-8730-a21f9074456b',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/e41fdda2f4_BUTvnLIY9m.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '28880394-6934-4d4b-84f2-898028eb7243',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/24428cba11_ECQcpjJ4zf.gif'
            }
          ]
        },
        {
          stickerPackId: 'e3e23eb8-0488-4577-9158-a4e30318044c',
          creator: {
            id: '85f1192c-c09a-46e8-b5d2-49cd654b17cc',
            nickname: [
              { language: 'en', content: 'zookiz' },
              { language: 'ko', content: 'zookiz' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'c9d7a3ac-ef09-452a-89db-40c88a1434e8',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/a86a41bff7_XcLwJm5nCp.gif'
            }
          ]
        }
      ]
    },
    {
      id: '2ad5b857-9053-4529-a534-498d1239b0e9',
      name: [
        { language: 'en', content: 'Hip MiMi&NeKo 03' },
        { language: 'ko', content: 'Hip MiMi&NeKo 03' }
      ],
      description: [
        {
          language: 'en',
          content:
            'A Friendly duo of a Cat & a Rabbit with a cuddly tail and cutie pie cheeks. With their cuteness, they bring laughter and joy to others ! They merrily dance and swing that tail, being cuddly dudley..... and sometime pumped up to become Macho-Macho Man !?'
        },
        {
          language: 'ko',
          content:
            'A Friendly duo of a Cat & a Rabbit with a cuddly tail and cutie pie cheeks. With their cuteness, they bring laughter and joy to others ! They merrily dance and swing that tail, being cuddly dudley..... and sometime pumped up to become Macho-Macho Man !?'
        }
      ],
      thumbnails: [
        {
          spec: 'w250',
          url:
            'https://sdk.mojitok.im/fd9f83a14626238f954ea63ddc65695b_80ed303e75.png'
        }
      ],
      creator: {
        id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
        nickname: [
          { language: 'en', content: 'Quan Inc.' },
          { language: 'ko', content: 'Quan Inc.' }
        ],
        SNSs: [
          {
            type: 'INSTAGRAM',
            url:
              'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
            priority: 0
          }
        ]
      },
      product: { productType: 'STICKER_PACK', priceType: 'TIER_2' },
      stickers: [
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '69402f41-1e8e-4a65-9f06-38b3d96f5138',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/e8585d1264_opGQxewKZK.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'af2dc968-40c6-4541-aa6c-6323de04d70b',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/73e78b46e8_ugHOPRff0S.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'bf229da6-d4e3-4061-b02a-078c0b5a9908',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/dfb4b7ba3a_eqZLNirjJ3.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'e420b2bd-5643-4ad4-a1f8-e335a95bd7f3',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/be9282e1b1_BSZ3M1VINS.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '84d1431e-3695-4908-b881-682293ecb273',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/d57bef2579_27fMyZX1vc.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '77cc1983-dfa0-44f4-b4c5-6f0c5d463578',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/783c5b9703_aDX3P63HM1.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'f794e787-9c25-40f9-870e-60a9f12428b8',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/88b0144480_93AvGi9IiQ.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'b733521b-96a7-4e8a-91e8-0541e1728ef0',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/0a8b056fea_7IM4SCAEif.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'bddbf4d3-d63b-4c2d-bedd-1fc73678125c',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/1e1dbc390a_njDQyWKlEB.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '8ce3db11-89b3-482a-b5de-827b22790029',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/cb98e88bd3_dpkE8IrfaA.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '4c35e1ec-fc37-4660-8cee-f79dba3e2be0',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/50d78788ca_DocHcdZ99I.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'cbb3a0a8-8c60-45f4-96a4-6c7a7e78c39e',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/4921d0ff4f_d1wdTgDBmM.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'c2ca687a-3de5-4f12-94ed-949e906f547c',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/4b7a460d14_6d1wxtX1CW.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '71ae1ad0-2235-404a-b624-168c5913901f',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/c8989a29d6_Tx2ojSZOrv.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: '39db1df6-5f2d-4fa6-9dbf-f2ae5942becf',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/32b3c51c86_r3OY4fSPIr.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'ddce9f23-acd2-4988-8228-2eef37d1d521',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/c5260bbca3_0XPEzMcPww.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'd30fb393-0f29-48f7-924d-5d3886aac6af',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/11a520a4bf_noNZmtRNHW.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'b5039a1a-018d-4b9e-9553-afcb7ad5d334',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/a92ec09abe_AwHbGYpI4V.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'c54c6d56-3137-4385-b034-3651697d1362',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/badde07c03_GEf8qAAx1D.gif'
            }
          ]
        },
        {
          stickerPackId: '2ad5b857-9053-4529-a534-498d1239b0e9',
          creator: {
            id: 'f552865c-9fc3-4e40-9752-f520e24dbbba',
            nickname: [
              { language: 'en', content: 'Quan Inc.' },
              { language: 'ko', content: 'Quan Inc.' }
            ],
            SNSs: [
              {
                type: 'INSTAGRAM',
                url:
                  'https://instagram.com/stickerfarm_mojitok?igshid=1maslkahleev6',
                priority: 0
              }
            ]
          },
          id: 'e986a2d4-6aac-431e-bbc7-226d2c8a8e97',
          images: [
            {
              spec: 'w300',
              url:
                'https://sdk.mojitok.im/emoticons/w300/8010500094_fz7V32UfXl.gif'
            }
          ]
        }
      ]
    }
  ],
  meta: {}
}
