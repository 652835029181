
import React from "react";
import { makeStyles } from "@material-ui/core";

import faceBookIcon from "../../img/header_icon/facebook_white.png";
import instagramIcon from "../../img/header_icon/instagram_white.png";
import linkedInIcon from "../../img/header_icon/linkedin_white.png";

const useStyles = makeStyles((theme) => ({
    container:{
        marginBottom:'10px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    line:{
        marginTop:'15px',
        fontFamily: 'Gmarket Sans TTF',
        fontSize:'11px',
        fontWeight:400,
        lineHeight:'19px',
        color:'#233653'
    },
    iconContainer:{
        display:'flex',
        '& a':{
          display:'block',
          marginLeft:'28px',
        },
        '& img':{
          width:'36px'
        },
      }
}));

function FooterSmallScreen() {
  const classes = useStyles();

  const onClickReport = () => {
    window.open(
      "https://mojitok.com/privacy")
    
  };
  return (
     <div className={classes.container}>
         <div className={classes.line}><span>email us <span style={{borderBottom:'0.5px solid #233653'}}>business@mojitok.com</span></span></div>
         <div className={classes.line}>      <span>visit </span> <span style={{cursor:'pointer',borderBottom:'0.5px solid #233653'}} onClick={()=>{window.open("https://mojitok.com/")}} className={classes.borderBottom}> mojitok.com</span>
        </div>
         <div className={classes.line}>
            <div className={classes.iconContainer}>
                <a
                    underline="none"
                    target="_blank"
                    href="https://www.facebook.com/withMojitok/">
                    <img
                    src={faceBookIcon}
                    alt="facebook icon"
                    ></img>
                </a>
                <a
                    underline="none"
                    target="_blank"
                    href="https://www.instagram.com/stickerfarm_mojitok/"
                >
                    <img
                    src={instagramIcon}
                    alt="instagram icon"
                    ></img>
                </a>
                <a
                    underline="none"
                    target="_blank"
                    href="https://www.linkedin.com/company/mojitok-inc/"
                >
                    <img
                    src={linkedInIcon}
                    alt="linkdin icon"
                    ></img>
                </a>
            </div>
         </div>
         <div className={classes.line}><span>© 2021 Platfarm Inc. All rights reserved.</span></div>
         <div onClick={onClickReport} className={classes.line}><span>Privacy Policy</span></div>
     </div>
  );
}

export default FooterSmallScreen;
