import React, { useReducer, useContext, createContext, Dispatch } from "react";

type State = {
  index: number;
};

type Action =
  | { type: "HOME"; index: number }
  | { type: "CATEGORY"; index: number };

type TabDispatch = Dispatch<Action>;

const TabIndexStateContext = createContext<State | null>(null);
const TabIndexDispatchContext = createContext<TabDispatch | null>(null);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "HOME":
      return {
        ...state,
        index: 0,
      };
    case "CATEGORY":
      return {
        ...state,
        index: 1,
      };
    default:
      throw new Error("Unhandled action");
  }
}

export function TabIndexProvider({ children,firstIndex }: { children: React.ReactNode,firstIndex?:number }) {
  const [state, dispatch] = useReducer(reducer, {
    index: firstIndex?firstIndex:0,
  });

  return (
    <TabIndexStateContext.Provider value={state}>
      <TabIndexDispatchContext.Provider value={dispatch}>
        {children}
      </TabIndexDispatchContext.Provider>
    </TabIndexStateContext.Provider>
  );
}

export function useTabIndexContext() {
  const state = useContext(TabIndexStateContext);
  if (!state) throw new Error("Cannot find SessionProvider");
  return state;
}

export function useTabIndexDispatch() {
  const dispatch = useContext(TabIndexDispatchContext);
  if (!dispatch) throw new Error("Cannot find SessionProvider");
  return dispatch;
}
