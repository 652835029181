import React, { useEffect } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import MainView from './components/Mainview'
import { MJTOnStickerChoice } from '../types'

import { useTranslation } from 'react-i18next'
// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from './styles.module.css'
import getMJTStyleTheme from './utils/getMJTStyleTheme'
import MJTContext from '../Core/MJTContext'
import { SessionProvider } from './SessionStateContext'

export interface MJTMainViewProps {
  width: string | number
  height: string | number
  onStickerChoice: MJTOnStickerChoice
}

export default function MJTMainView({
  width,
  height,
  onStickerChoice
}: MJTMainViewProps) {
  const mjtTheme = getMJTStyleTheme()
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(MJTContext.getInstance().uiLang)
  }, [])

  return (
    <div style={{ width, height }} className={styles['main-wrapper']}>
      <ThemeProvider theme={mjtTheme}>
        <CssBaseline />
        <SessionProvider>
          <MainView onStickerChoice={onStickerChoice} />
        </SessionProvider>
      </ThemeProvider>
    </div>
  )
}
