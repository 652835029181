import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../styles/Tag.css';

const tagInfo=[
        ["HAPPY", require('../../img/tag_emoji/img-happy.svg') ],
        ["IN LOVE", require('../../img/tag_emoji/img-loving.svg')],
        ["HELLO", require('../../img/tag_emoji/img-hi.svg') ],
        ["WOW", require('../../img/tag_emoji/img-wow.svg')],
        ["HBD", require('../../img/tag_emoji/img-birthday@2x.png')],
        ["SAD", require('../../img/tag_emoji/img-sad.svg') ],
        ["OK", require('../../img/tag_emoji/img-ok.svg') ],
        ["NO", require('../../img/tag_emoji/img-no.svg') ],
        ["TIRED", require('../../img/tag_emoji/img-tired.svg') ],
        ["SHY", require('../../img/tag_emoji/img-shy.svg') ],
        ["CHEERING", require('../../img/tag_emoji/img-cheer.svg') ],
        ["SORRY", require('../../img/tag_emoji/img-sorry.svg')],
        ["LAUGH", require('../../img/tag_emoji/img-lol.svg') ],
        ["BORING", require('../../img/tag_emoji/img-boring.svg')],
        ["THANKS", require('../../img/tag_emoji/img-thanks.svg') ],
        ["VERY GOOD", require('../../img/tag_emoji/img-good.svg') ],
        ["ANNOYED", require('../../img/tag_emoji/img-annoyed.svg') ],
        ["ANGRY", require('../../img/tag_emoji/img-angry.svg') ],
        ["GOODNIGHT", require('../../img/tag_emoji/img-goodnight.svg') ],
        ["BYEBYE", require('../../img/tag_emoji/img-bye.svg') ]
      ];

class Tag extends Component {

  constructor(props){
    super(props);
    this.state={
      tagInfo
    };
  }

  render() {
    return (
      <div className="tag-box">
      <div>
        {
          this.state.tagInfo.map((tagInfo, index) =>{
              return(
                <Link to={{pathname:"/demo/taglist/findtagsticker", state:tagInfo[0]}} key={index}>
                  <div className="tag-btn">
                    <object data={tagInfo[1]} alt="tag emotion" className="tag-btn-img"/>
                    <span className="tag-btn-name"> {tagInfo[0]}</span>
                  </div>
                </Link>
              );
            })
        }
        </div>

      </div>
    );
  }

}//tag component end

export default Tag;
