import _ from 'lodash';
import { separateTextAndEmojiBlobs, getGrams, findStopwordCandidate } from './text-utils';
import STOP_WORDS from '../res/stopwords.json';
import MJTSearch from '../MJTSearch';
import _DLog from '../DLog';

const DLog = _DLog.getInstance();

export enum LANG {
  ko = "KO",
  en = "EN",
}

export default async (input: string, lang: LANG) => {
  let emojiCategories: string[] = [];

  try {
    const { text, emojis } = separateTextAndEmojiBlobs(input);
    const [unigram, bigram, trigram] = getGrams(text);
    const ecListFromText = await findEmojiCategoryFromText(unigram, bigram, lang);
    const ecListFromEmojis = await getECListByEmojis(emojis as string[]);

    emojiCategories = combineEmojiCategories(ecListFromText, ecListFromEmojis);
  } catch (e) {
    DLog.e('', e);
  }

  return emojiCategories;
};

function combineEmojiCategories(ecListFromText: string[], ecListFromEmojis: string[]) {
  return _.chain(ecListFromEmojis.filter(ec => ecListFromText.includes(ec)))
    .union(ecListFromEmojis)
    .union(ecListFromText)
    .value();
}

async function getECListByEmojis(emojis: string[]): Promise<string[]> {
  if (!emojis) {
    return [];
  }

  return new Promise((resolve, reject) => {
    searchDBIndexesFromEmojis(emojis, (result) => {
      searchDBEmojisFromIndex(result, (result) => {
        resolve(result)
      })
    })
  })
}

function searchDBIndexesFromEmojis(emojis: string[], callback: (result: number[]) => void) {
  const request = indexedDB.open(MJTSearch.getInstance().dbName)
  let result: any[] = []
  request.onsuccess = (e) => {
    try {
      const db = request.result
      const transaction = db.transaction(["EMOJI_TO_EC_IDX"])
      const objStore = transaction.objectStore("EMOJI_TO_EC_IDX")
      const storeIndex = objStore.index("emoji_str")
      emojis.forEach((item) => {
        const searchRequest = storeIndex.getAll(item)
        searchRequest.onsuccess = (e) => {
          result = searchRequest.result
        }
      })
      transaction.oncomplete = (e) => {
        const resultEmojis = result.map((item) => item.ec_index)
        callback(resultEmojis)
      }

      transaction.onerror = (e) => {
        callback([])
      }
    }
    catch (e) {
      callback([])
    }
  }
}

async function findEmojiCategoryFromText(unigram: string, bigram: string, lang: LANG) {
  if (!lang) {
    return [];
  }

  let foundEmojiCateogry: string[] = [];

  try {
    foundEmojiCateogry = await findEmojiCategoryFromNgram(bigram, lang);
    if (!foundEmojiCateogry || (foundEmojiCateogry.length === 0)) {
      if (lang.toUpperCase() === 'EN' && bigram && bigram.length > 0) {
        //언어 : 영어 & bigram 이 존재
        //-> stopword 필터 적용하여 검색
        foundEmojiCateogry = await findEmojiCategoryByStopWordFilter(unigram, bigram, lang);
      } else {
        //언어 : 영어 & bigram 이 존재 X
        //언어 : 그 외
        //-> unigram 검색
        foundEmojiCateogry = await findEmojiCategoryFromNgram(unigram, lang);
      }
    }
  } catch (e) {
    DLog.e('', e);
  }

  return foundEmojiCateogry;
}

async function findEmojiCategoryFromNgram(nGram: string, lang: LANG): Promise<string[]> {
  if (!nGram || !lang) {
    return [];
  }

  return new Promise((resolve, reject) => {
    searchDBIndexesFromNgram(nGram, lang, (result) => {
      searchDBEmojisFromIndex(result, (result) => {
        resolve(result)
      })
    })
  })
}

function searchDBIndexesFromNgram(nGram: string, lang: LANG, callback: (result: number[]) => void) {
  const request = indexedDB.open(MJTSearch.getInstance().dbName)
  let result: any[] = []
  request.onsuccess = (e) => {
    try {
      const db = request.result
      const transaction = db.transaction([`TOKEN_TO_EMOJI_${lang.toUpperCase()}`])
      const objStore = transaction.objectStore(`TOKEN_TO_EMOJI_${lang.toUpperCase()}`)
      const storeIndex = objStore.index("keytoken")

      const searchRequest = storeIndex.getAll(nGram)
      searchRequest.onsuccess = (e) => {
        result = searchRequest.result
      }

      transaction.oncomplete = (e) => {
        const resultIndexes = result.map((item) => item.ec_index)
        callback(resultIndexes)
      }

      transaction.onerror = (e) => {
        callback([])
      }
    }
    catch (e) {
      callback([])
    }
  }
}

function searchDBEmojisFromIndex(indexes: number[], callback: (result: string[]) => void) {
  const request = indexedDB.open(MJTSearch.getInstance().dbName)
  let result: any[] = []
  request.onsuccess = (e) => {
    try {
      const db = request.result
      const transaction = db.transaction(["EC_IDX_TO_EMOJICATEGORY"])
      const objStore = transaction.objectStore("EC_IDX_TO_EMOJICATEGORY")
      const storeIndex = objStore.index("ec_index")
      indexes.forEach((item) => {
        const searchRequest = storeIndex.getAll(item)
        searchRequest.onsuccess = (e) => {
          result = searchRequest.result
        }
      })
      transaction.oncomplete = (e) => {
        const resultEmojis = result.map((item) => item.emoji_category)
        callback(resultEmojis)
      }
      transaction.onerror = (e) => {
        callback([])
      }
    }
    catch (e) {
      callback([])
    }
  }
}

async function findEmojiCategoryByStopWordFilter(unigram: string, bigram: string, lang: LANG) {
  if (STOP_WORDS[lang].includes(findStopwordCandidate(bigram))) {
    return await findEmojiCategoryFromNgram(unigram, lang);
  }
  return [];
}
