import crypto from 'crypto-js';
import { SourceType, isSourceTypeKey,EventType } from "./model/inner/MJTAnalyticsEvent";
import MJTAnalyticsData from './model/outter/MJTAnalyticsData';
import MJTAnalyticsEvent from './model/inner/MJTAnalyticsEvent';
import { MJTViewType } from '../types';
import MJTContext from './MJTContext';
import request, { HttpOptions, HTTP_METHOD } from './utils/https';
import Response from './model/outter/Response';
import MJTAuth from './MJTAuth';
import MJTException from './model/error/MJTException';
import _DLog from './DLog';
// 루트 디렉터리의 package.json 을 직접 import 하면 빌드시 디렉터리 구조가 깨진다.
// res 디렉터리에 package.json 으로의 심볼릭 링크를 만든 후 해당 링크를 import 하면 구조가 깨지지 않는다.
// json 전체를 가져오는 경우 내부 정보가 노출될 수 있으니 선택적으로 import 해야 한다.
import { version as _SDK_VERSION } from './res/packageInfo.json';

const DLog = _DLog.getInstance();

const INSTANT_SECONDS = 3;
const ANALYTICS_VERSION = '1';
const PLATFORM = 'web';
const THRESHOLD_EVENT_COUNT = 10;
export const SDK_VERSION = _SDK_VERSION;

export default class MJTAnalytics {
  private static instantMilliseconds = INSTANT_SECONDS * 1000;

  private static instance: MJTAnalytics;

  private _eventLogStorages = [new Map<string, MJTAnalyticsEvent>(), new Map<string, MJTAnalyticsEvent>()];
  private _eventLogStorageIdx = 0;
  /** 데이터 쌓는 곳. 데이터 추가가 발생*/
  private _activeEventLogStorage = this._eventLogStorages[this._eventLogStorageIdx];
  /** 데이터를 전송하거나 비우는 곳 */
  private _frozenEventLogStorage = this._eventLogStorages[this._eventLogStorageIdx^1];

  private constructor() { }

  public static getInstance(): MJTAnalytics {
    if (!MJTAnalytics.instance) {
      MJTAnalytics.instance = new MJTAnalytics();
    }

    return MJTAnalytics.instance;
  }

  /**
   * 밀리초 단위는 모두 0으로 맞추고, 가장 가까운 INSTANT_SECONDS 의 배수로 시간을 맞춘다.
   */
  private static getSquashedTimestamp() {
    return (
      new Date(
        Math.round(Date.now() / MJTAnalytics.instantMilliseconds)
        * MJTAnalytics.instantMilliseconds
    )).toISOString();
  }

  /**
   * MJTViewType -> SourceType 으로 변환. 알 수 없는 타입은 undefined 를 반환
   *
   * @param viewType
   * @returns Analytics 이벤트 전송에 사용하는 SourceType 의 키
   */
  private static convertViewTypeToSourceType(viewType: MJTViewType): keyof typeof SourceType | undefined {
    switch (viewType) {
      case MJTViewType.EMOTION_TAG:
        return 'EMOTION_TAG';
      case MJTViewType.SEARCH:
        return 'SEARCH';
      case MJTViewType.OWNERSHIP:
        return 'DOWNLOADED_PACK';
      case MJTViewType.DOWNLOADED_PACK:
        return 'DOWNLOADED_PACK';
      case MJTViewType.RECENT:
        return 'RECENT';
      case MJTViewType.RTS:
        return 'RTS';
      case MJTViewType.TRENDING:
        return 'TRENDING';
      default:
        return undefined;
    }
  }

  /**
   *  비슷한 시간대의 Analytics resource 데이터의 merge 를 용이하게 하면서 발생 순서를 지키기 위해(map 사용) 필요.
   * @param data Analytics Event 객체
   * @returns (timestamp, sourceType, eventType) 으로 Hash 된 문자열
   */
  private static getEventKeyHash(data: MJTAnalyticsEvent) {
    return crypto.enc.Hex.stringify(crypto.MD5(data.ts+data.s+data.et));
  }

  public async addEvent(sourceViewType: keyof typeof SourceType, eventType: keyof typeof EventType, resources: Array<string>): Promise<void>;
  public async addEvent(sourceViewType: MJTViewType, eventType: keyof typeof EventType, resources: Array<string>): Promise<void>;
  public async addEvent(sourceViewType: any, eventType: keyof typeof EventType, resources: Array<string>): Promise<void> {
    const sourceType = isSourceTypeKey(sourceViewType) ? sourceViewType : MJTAnalytics.convertViewTypeToSourceType(sourceViewType);

    // 알 수 없는(undefined) sourceType 인 경우 아무런 동작도 하지 않는다.
    if (!sourceType) return;

    const newEvent: MJTAnalyticsEvent = {
      ts: MJTAnalytics.getSquashedTimestamp(),
      s: SourceType[sourceType],
      et: EventType[eventType],
      r: resources
    };

    const eventKeyHash = MJTAnalytics.getEventKeyHash(newEvent);

    // mutex 필요 없음
    if (this._activeEventLogStorage.has(eventKeyHash)) {
      // Risk -> hash collision
      const existingEvent = this._activeEventLogStorage.get(eventKeyHash)!;
      existingEvent.r = existingEvent.r.concat(newEvent.r);
      this._activeEventLogStorage.set(eventKeyHash, existingEvent);
    } else {
      this._activeEventLogStorage.set(eventKeyHash, newEvent);
    }

    // Analytics 데이터 개수 확인 -> 이벤트가 THRESHOLD_EVENT_COUNT 개가 넘으면 전송 시도
    // TODO: 나중에 용량 기반 확인으로 변경
    if (this._activeEventLogStorage.size > THRESHOLD_EVENT_COUNT) {
      await this.sendAnalyticsData();
    }
  }

  /**
   * Analytics 데이터 전송
   */
  public async sendAnalyticsData(): Promise<void> {
    // 비어 있는 경우 전송하지 않는다.
    if (this._activeEventLogStorage.size === 0) {
      DLog.d('Analytics Logs Empty!');
      return;
    }

    try {
      // 다른 event log 에 데이터를 저장한다.
      DLog.d('Sending Analytics Data...');
      this.swapEventLogsMaps();
      await this.request(`${MJTContext.getInstance().baseUrl}/c/a`, {
        body: this.convertMJTAnalyticsToJson(
          SDK_VERSION!,
          ANALYTICS_VERSION,
          PLATFORM
        ),
        cacheMode: 'no-cache'
      });
      DLog.d('Sent Analytics Data');
    } catch (err) {
      // 실패 시 전송을 시도했던 map 뒤에 데이터를 붙인다.
      DLog.e('Failed to send Analytics Data.');
      this._activeEventLogStorage.forEach((v, k) => {
        if (this._frozenEventLogStorage.has(k)) {
          const existingEvent = this._frozenEventLogStorage.get(k)!;
          existingEvent.r = existingEvent.r.concat(v.r)
          this._frozenEventLogStorage.set(k, existingEvent);
        } else {
          this._frozenEventLogStorage.set(k, v);
        }
      });
      this.swapEventLogsMaps();
      // Analytics 전송 실페에 대한 예외는 밖으로 던지지 않는다.
    }

    // Analytics 전송 성공 시: 데이터를 보내는 저장소(_frozenEventLogStorage) 의 이벤트 지우기
    // Analytics 전송 실패 시: catch 블록 내에서 데이터를 쌓는 저장소의 데이터 (최신 데이터)를 데이터 보내는 저장소의 데이터 뒤에 이어붙인 후 swap 하였기 때문에, 데이터를 보내는 저장소(_frozenEventLogStorage) 의 이벤트를 지워도 된다.
    DLog.d('Clear Events');
    this.clearEvents();
  }

  private async request(url: string, options?: HttpOptions): Promise<any> {
    let response: Response
    try {
      const headers = new Headers();
      const accessToken = await MJTAuth.getInstance().getValidAccessToken();
      headers.append("Mojitok-User-Access-Token", accessToken);
      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json")
      response = await request(url, HTTP_METHOD.POST, headers, options);
    }
    catch (e) {
      if (window.navigator.onLine === false) {
        throw new MJTException(4008, "Network connection failed.")
      }
      throw new MJTException(4100, e.message ? e.message : "Failed to Call API");
    }
    const { status, payload } = response;

    switch (true) {
      case (status === 201 || status === 200):
        return payload.data
      case (status >= 400 && status <= 499):
        throw new MJTException(4101, payload.data.message)
      case (status >= 500 && status <= 599):
        throw new MJTException(5000, payload.data.message)
      default:
        throw new MJTException(9000, payload.data.message)
    }
  }

  /**
   * 데이터를 보내는 저장소의 이벤트를 지운다.
   */
  private clearEvents() {
    this._frozenEventLogStorage.clear();
  }

  /**
   * Analytics Request 에 보낼 Body json 생성
   *
   * @param sdkVersion SDK 버전
   * @param analyticsVersion Analytics Schema 버전
   * @param platform 플랫폼 종류
   */
  private convertMJTAnalyticsToJson(sdkVersion: string, analyticsVersion: string, platform: string): MJTAnalyticsData | undefined {
    if (this._frozenEventLogStorage.size === 0) {
      // 빈 analytics 의 경우 undefined 응답
      return undefined;
    }

    const analyticsJson: MJTAnalyticsData = {
      sdk: sdkVersion,
      plf: platform,
      scm: analyticsVersion,
      e: []
    };

    // 백업된 로그에서 가져온다
    this._frozenEventLogStorage.forEach((v, k) => {
      analyticsJson.e.push(
        {
          ts: v.ts,
          s: v.s,
          et: v.et,
          r: v.r
        }
      )
    });

    return analyticsJson;
  }

  /**
   * 데이터를 쌓는 저장소와, 데이터를 보네는 저장소의 역할을 swap
   */
  private swapEventLogsMaps() {
    this._frozenEventLogStorage = this._eventLogStorages[this._eventLogStorageIdx];
    this._eventLogStorageIdx ^= 1;
    this._activeEventLogStorage = this._eventLogStorages[this._eventLogStorageIdx];
  }
}
