import React from 'react'
import { makeStyles, Typography, Button } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { useHistory } from '../../../myRouter'

const useStyles = makeStyles((theme) => ({
  myPackEmptyBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    width: '100%',
  },
  copyrightText: {
    ...theme.customTypography.body4,
    color: 'rgba(151, 151, 151, 1)',
    marginTop: '8px',
  },
  title: {
    color: 'rgba(16, 16, 16, 1)',
    marginTop: '32px',
    marginBottom: '8px',
  },
  description: {
    ...theme.customTypography.body3,
    color: 'rgba(151, 151, 151, 1)',
  },
  tourButton: {
    width: '328px',
    height: '48px',
    marginTop: '32px',
    backgroundColor: theme.brandColorTheme.mojitokBlue.main,
    borderRadius: 0,
  },
  tourButtonText: {
    color: theme.greyscaleColorTheme.white.main,
  },
}))

function MyPackEmpty() {
  const { t } = useTranslation('store');
  const classes = useStyles()
  const history = useHistory()
  return (
    <div className={classes.myPackEmptyBox}>
      <img src={require('../../img/empty-my-pack.svg')} />
      <Typography className={classes.copyrightText}>© beabae</Typography>
      <Typography className={classes.title} variant="body2">{t('my_stickers_no_history_title')}</Typography>
      <Typography className={classes.description}>{t('my_stickers_no_history_dsc_01')}</Typography>
      <Typography className={classes.description}>{t('my_stickers_no_history_dsc_02')}</Typography>
      <Button className={classes.tourButton} onClick={() => history.push('/')}>
        <Typography className={classes.tourButtonText} variant="subtitle1">{t('my_stickers_go_to_see_stickers')}</Typography>
      </Button>
    </div>
  )
}

export default MyPackEmpty