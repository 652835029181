import _ from 'lodash';

export function separateTextAndEmojiBlobs(input: string) {
  const regex = /\p{Emoji_Presentation}/gu;
  const emojis = input.match(regex);
  const text = input.replace(regex, '').trim();

  return { text, emojis };
}

export function normalizeText(text: string) {
  //Step1. 문장 맨 우측에 마침표 지우기
  //Step2. 소문자로 바꾸기
  //Step3. 1개이상인 whitespace(space, tab, lf) 를 1개의 스페이스로
  //Step4. 바깥쪽 whitespace 없애기

  return removeDotFromRight(text)
    .toLowerCase()
    .replace(/[\s]+/gi, ' ')
    .trim();
}

function removeDotFromRight(text: string) {
  const regex = /(\。+$)|(\.+$)/gi;
  return text.replace(regex, '').trim();
}

export function getGrams(text: string) {
  if (!text) {
    return [];
  }

  return _.chain(text)
    .split(' ')
    .reduceRight((data, token) => {
      data.accum = `${token} ${data.accum}`;
      data.grams.push(data.accum.trim());

      return data;
    }, { accum: '', grams: Array<string>() })
    .value().grams;
}

export function findStopwordCandidate(bigram: string) {
  if (!bigram || bigram.split(' ').length != 2) {
    return '';
  }

  if (bigram.length > 0) {
    const firstUnigram = bigram.split(' ')[0];
    const splitWithQuotes = firstUnigram.split('\'');

    return (splitWithQuotes.length === 2) ? splitWithQuotes[1] : firstUnigram;
  }

  return '';
}