import React from "react";

export default ({ color, fill }) => (
  <svg
    color={color}
    viewBox="0 0 42 42"
    width="42" height="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.26 12C17.17 11.86 13 15.95 13 21H11.21C10.76 21 10.54 21.54 10.86 21.85L13.65 24.65C13.85 24.8499 14.16 24.8499 14.36 24.65L17.15 21.85C17.46 21.54 17.24 21 16.79 21H15C15 17.1 18.18 13.95 22.1 14C25.82 14.05 28.95 17.1799 29 20.9C29.05 24.8099 25.9 28 22 28C20.39 28 18.9 27.45 17.72 26.52C17.32 26.21 16.76 26.24 16.4 26.6C15.98 27.02 16.01 27.73 16.48 28.09C18 29.29 19.91 30 22 30C27.05 30 31.14 25.83 31 20.74C30.87 16.05 26.95 12.13 22.26 12ZM21.75 16.9999C21.34 16.9999 21 17.34 21 17.7499V21.43C21 21.78 21.19 22.11 21.49 22.29L24.61 24.14C24.97 24.35 25.43 24.23 25.64 23.88C25.85 23.52 25.73 23.06 25.38 22.85L22.5 21.14V17.7399C22.5 17.3399 22.16 16.9999 21.75 16.9999Z" fill={fill} />

  </svg>
);