import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import callApi from '../utils/callAPI'
import { LanguageName } from '../../Locales/i18n'
import MJTContext from '../../Core/MJTContext'
import MJTRecentStickers from '../../Core/MJTRecentStickers'
import StickersView from './StickersView'
import { MJTViewType } from '../../types'
import { MJTOnStickerChoice } from '../../types'

import styles from '../styles.module.css'

export interface RecentProps {
  onStickerChoice: MJTOnStickerChoice
}

export default function Recent(props: RecentProps) {
  const { t } = useTranslation()

  return (
    <div
      className={
        MJTRecentStickers.getInstance().get().length > 0
          ? styles['recent-wrapper']
          : styles['recent-wrapper-empty']
      }
    >
      {MJTRecentStickers.getInstance().get().length > 0 ? (
        <StickersView
          stickers={MJTRecentStickers.getInstance().get()}
          onStickerChoice={props.onStickerChoice}
          viewType={MJTViewType.RECENT}
        />
      ) : (
        <Typography
          variant='h2'
          component='h2'
          className={styles['recent-no-history-text']}
        >
          {t('recent_no_history_caption')}
        </Typography>
      )}
    </div>
  )
}
