import React, {Component} from 'react';
import { Input } from 'antd';

import '../styles/Mojitok.css';

class InputTextView extends Component {

  constructor(props){
    super(props);
    this.state = {
      inputValue : ''
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.props.defaultValue !== nextProps.defaultValue){
      this.setState({inputValue : nextProps.defaultValue})
    }
  }

  render(){
    const { onClickBtnFunc, btnImg, placeholder } = this.props;
    return(
      <div className="mojitok-search-box">
        <Input
          className="mojitok-search-input"
          placeholder={placeholder}
          value={this.state.inputValue}
          onChange={(e)=>this.setState({inputValue: e.target.value})}
          onPressEnter={() => onClickBtnFunc(this.state.inputValue)}
          />
        <button className="mojitok-search-btn" onClick={() => onClickBtnFunc(this.state.inputValue)}>
          <img src={btnImg} alt="search_icon" className="mojitok-search-btn-icon"/>
        </button>
      </div>
    );
  }
}

export default InputTextView;
