import React from 'react'
import './MyTabs.css'
function MyTabs({value,onChange}) {
    console.log(value)
    const handleClick = (e)=>{
        onChange(e,Number(e.currentTarget.id))
    }
  return (
    <div className="my-tab-wrapper">
        <button onClick={handleClick} id='0' className={`my-tab-button ${value===0?'active':''}`}>
            <span className='my-tab-text'>Home</span>
        </button>
        <button onClick={handleClick} id='1' className={`my-tab-button ${value===1?'active':''}`}>
            <span className='my-tab-text'>Category</span>
        </button>
        <div style={value===1?{left:'50%'}:{left:'0%'}} className='border-effect'></div>
    </div>

 );
}

export default MyTabs;
