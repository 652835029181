import React from "react";
import { MyHistory } from "../redux/history/historySlice";
import { useHistory } from "./hooks";


interface BrowserRouterProp{
    children:JSX.Element|JSX.Element[]
}
export default function BrowserRouter({children}:BrowserRouterProp){
    const history = useHistory()
    return <>{children}</>
}