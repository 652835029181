let openStoreListener = null
export const windowOpenWrap = ()=>{
    if(window){
        const _open = window.open
        window.open = function(...prop){
            // open(...prop)
            const url = prop[0]
            if(url.includes('cloud.mojitok-api.com/store')){
                openStoreListener(prop) 
            }
            else{
                _open(prop)
            }
            
        }
    }
}

export const addCallbackWhenWindowOpenStore= (f)=>{
    openStoreListener=f
}