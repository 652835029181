/**
 * 클라이언트에서 subdomain 생성하는 로직
 */
import crypto from 'crypto-js';

export default function generateSubdomain(applicationId: string): string {
  const SALT = '8uMkWAq3SiQLquBY'
  const toHash = applicationId.toLowerCase() + SALT
  const subdomain = crypto.MD5(toHash)
    .toString(crypto.enc.Hex)
    .slice(0, 16)

  return subdomain
}
