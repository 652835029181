import React from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core";

import "../../styles/SDKDemo.css";

const theme = createMuiTheme({
  typography: {
    caption: {
      fontFamily: [
        "'Gmarket Sans TTF'",
      ].join(','),
      fontSize: '18px'
    },
  },
});

const useStyles = makeStyles((theme) => ({
  footerBottomWrapper: {
    display: "flex",
    minWidth:'550px',
    marginLeft: "10vw",
    marginTop:'50px',
    justifyContent: "center",
    color: "#919BA9",
    fontSize:'20px',
    fontFamily: 'Gmarket Sans TTF',
    width:'80vw',
    flexWrap:'wrap',
    marginBottom:'10px',

  },
  footerBottomSectionWrapper:{
    display:'flex',
    flexWrap:'wrap',
    marginLeft:'30px'
  },
  footerBottomElem:{
    marginLeft:'20px',
    marginRight:'20px',
    marginBottom:'10px',


  },
  footerBottomMenuWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "auto",
    marginTop: "auto",
    color: "#919BA9",
    fontFamily: 'Gmarket Sans TTF',
    fontSize:'18px'

  },

  footerBottomIconWrapper: {
    marginBottom: "auto",
    marginTop: "auto",
  },
  footerBottomIcon: {
    marginLeft: "30px",
    width: "30px",
    height: "30px",
  },
  footerBottomIconBox: {
    minWidth: "160px",
  },
  borderBottom:{
    borderBottom:'1px solid #919BA9'
  },
  bottomLinkContainer:{
    display:'flex',
    flexWrap:'wrap'
  },
  privacyPolicyWrapper:{
    "&:hover": {
      color: 'lightGrey'
    }
  },
}));

function FooterBottom() {
  const classes = useStyles();

  const onClickReport = () => {
    window.open(
      "https://mojitok.com/privacy")
    
  };
  return (
    <div className={classes.footerBottomWrapper}>
      <div className={classes.footerBottomSectionWrapper}>

      <div 
        style={{size:'18px'}}
        className={classes.footerBottomElem}>
              © 2021 Platfarm Inc. All rights reserved.
      </div>
      <div
        style={{size:'18px'}}
            id="privacy policy"
            className={classes.footerBottomElem+' '+classes.privacyPolicyWrapper}
            style={{cursor: 'pointer'}}
            onClick={onClickReport}
          >
              Privacy Policy

          </div>
      </div>

      <div className={classes.footerBottomSectionWrapper}>
        
      <div className={classes.footerBottomElem}>

      <span>email us </span><span className={classes.borderBottom}> business@mojitok.com</span>
      </div>
      <div className={classes.footerBottomElem}>

      <span>visit </span> <span style={{cursor:'pointer'}} onClick={()=>{window.open("https://mojitok.com/")}} className={classes.borderBottom}> mojitok.com</span>
      </div>
      </div>
    </div>
  );
}

export default FooterBottom;
