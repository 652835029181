// react polyfill
// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
// antd polyfill
// https://github.com/ant-design/ant-design/issues/3400
import 'core-js/es6/string';
import './utils/windowWrapper'
import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import './index.css';
// import './reset.css'
import App from './App';
import theme from './StoreView/theme'

ReactDOM.render(
    <ThemeProvider theme={theme}>
          <App />
    </ThemeProvider>
, document.getElementById('root'));
