import MJTContent from '../../Core/MJTContent'
import MJTContext from '../../Core/MJTContext'
import MJTSticker from '../../Core/model/outter/MJTSticker'

/**
 * RTS 기능
 * @param text
 * @returns
 */
export async function searchStickerByText(
  text: string
): Promise<MJTSticker[] | undefined> {
  if (!text) {
    return
  }

  const language = MJTContext.getInstance().rtsLangs[0]
  const searchedData = await MJTContent.getInstance().getRTSStickers(
    text,
    language
  )

  return searchedData
}
