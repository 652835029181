import React, { useEffect, useState } from "react";
import { osName, isBrowser, osVersion } from "react-device-detect";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

import "./App.css";
import { Route, useLocation, useHistory } from "../myRouter";
import history from "./utils/history";

import Header from "./components/common/Header";

import MainTab from "./components/tab/MainTab";
import SearchView from "./layouts/SearchView";
import SearchResult from "./layouts/SearchResult";
import PackView from "./layouts/PackView";
import ProductInfo from "./layouts/ProductInfo";
import RefundInfo from "./layouts/RefundInfo";
import CategorySearchResult from "./layouts/CategorySearchResult";
import MyPacks from "./layouts/MyPacks";
import CategoryView from "./layouts/CategoryView";




function ContentApp() {
  const theme = useTheme();
  const matchPC = false;
  const location = useLocation();
  const [isSupport, setIsSupport] = useState<boolean>(true);
  const history = useHistory()
  const { i18n } = useTranslation();
  useEffect(()=>{
    history.push('/')
  }
  ,[])
  return (
    <>
      <Header
      onlyCategory={true}
        path={location.pathname}
        onClose={closeViewer}
        isSupport={isSupport}
        isPC={matchPC}
      />
      <Route path='/'  render={()=><CategoryView/>}/>
      <Route path="/pack" render={() => <PackView isPC={matchPC} />} />

      <Route path="/search" render={() => <SearchView isPC={matchPC} />} />
      <Route
            path="/packs/search"
            render={() => <SearchResult isPC={matchPC} />}
      />
      <Route path="/product-info" render={() => <ProductInfo />} />
      <Route path="/refund-info" render={() => <RefundInfo />} />
      <Route
            exact
            path="/packs/catalogue-category"
            render={() => <CategorySearchResult />}
      />
      <Route path="/mypacks" render={() => <MyPacks isPC={matchPC} />} />
    </>
  );
}
function closeViewer() {
  if (osName.toUpperCase() === "ANDROID") {
    (window as any).android
      ? (window as any).android.onClose()
      : console.log(osName, "may not set properly.");
  } else if (osName.toUpperCase() === "IOS") {
    (window as any).webkit
      ? (window as any).webkit.messageHandlers.onClose.postMessage("")
      : console.log(osName, "may not set properly.");
  } else {
    document.dispatchEvent(new Event('closestore'))
    console.log(osName);
  }
}


export default ContentApp;
