export const SourceType = <const> {
  RECENT: 'RC',
  DOWNLOADED_PACK: 'DP',
  TRENDING: 'TR',
  EMOTION_TAG: 'ET',
  SEARCH: 'SR',
  RTS: 'RT',
};

export function isSourceTypeKey(i: any): i is keyof typeof SourceType {
  return Object.keys(SourceType).includes(i);
}

export const EventType = <const> {
  VIEW: 'VW',   // view, 사용자가 스티커들을 본 경우
  CHOOSE: 'CH'  // choose, 사용자가 스티커를 선택한 경우
};

export default interface MJTAnalyticsEvent {
  /**
   * (timestamp 의 줄임) event 이 발생한 UTC 시간. ISO 8601 형식을 따른다
   */
  ts: string;
  /**
   * (sourceType 의 줄임) event 가 발생한 UI.
   */
  s: typeof SourceType[keyof typeof SourceType];
  /**
   * (eventType 의 줄임) 발생한 event 의 event 종류
   */
  et: typeof EventType [keyof typeof EventType];
  /**
   * (resources 의 줄임) event 에 해당하는 리소스들의 구분자. 스티커 id 를 비롯해 다양한 값들이 여러 개 들어올 수 있다. 하나의 값만 들어있더라도 array 에 넣어서 보낸다.
   */
  r: Array<string>;
}
