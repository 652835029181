/**
 * UIKit 데모 페이지
 */
import React from 'react';
import UIKitView from '../components/UIKitView';
import '../styles/UIKit.css'


const UIKitDemo = (props) => {

  return(
    <div className="sdk-demo-wrapper">
      <h1 className="mojitok-api-title">mojitok SDK demo page</h1>
      <div className="mojitok-sdk-demo-mockup">
        <div>
          <p className="mojitok-sdk-demo-mock-type">iOS</p>
          <div className="sdk-demo">
            <img src={require('../img/sdk_demo/phone_mockup_iphone_X.png')} alt="phone-mockup" className="uikit-phone-mockup"/>
            <div className="uikit-wrapper mockup-iphone">
              {/* <iframe src="/uikit" title="mojitok-uikit" scrolling="auto" className= "uikit-iframe"/> */}
              <UIKitView mockType="ios"/>
            </div>
          </div>
        </div>
        <div>
          <p className="mojitok-sdk-demo-mock-type">Android</p>
          <div className="sdk-demo">
            <img src={require('../img/sdk_demo/phone_mockup_glx_s20.png')} alt="phone-mockup" className="uikit-phone-mockup"/>
            <div className="uikit-wrapper mockup-glx">
              {/* <iframe src="/uikit" title="mojitok-uikit" scrolling="auto" className= "uikit-iframe"/> */}
              <UIKitView mockType="glx"/>
            </div>
          </div>
        </div>
      </div>
      <p className="mojitok-sdk-precaution">As this is a demo of the UI, please note that it has <strong>NOT</strong> been optimized for speed and content.</p>
    </div>
  )
}

export default UIKitDemo