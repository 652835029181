import React, { Component } from 'react';
import { Alert } from 'antd';
import ApiExplainView from './ApiExplainView';
import '../styles/Partner.css';

class PartnerProviceFunc extends Component{

  render(){
      return (
        <div className="mojitok-demo-wapper">
        {
                /* 현재 브라우저를 검사하여 IE 10이하 버전에서는 크롬에 최적화 되어있다고 안내해줌 */
                isAvailableBrowser() ?
                 null :
                 <Alert message={'This website is optimized for Chrome. (Difficult to use below Internet Explorer 10)'} type="warning" showIcon />
        }
          <p className = "mojitok-api-title">mojitok API demo page</p>
          <div className="partner-fb-extension-wapper">
            <div className="partner-fb-extension">
              <div className="partner-fb-extension-test">
                <img src={require('../img/mockup_phone.svg')} alt="phone-mockup" className="partner-fb-extension-test-mockup"/>
                <div className="partner-fb-extension-test-view-wapper">
                  <iframe src="/demo" title="fb-extension" scrolling="auto" className= "partner-fb-extension-test-view"/>
                </div>
              </div>
              <div className="partner-fb-extension-arrow-img">
                <img src={require('../img/partner_arrow.png')} alt="arrow"/>
              </div>
              <div className="partner-fb-extension-explain">
                <p className="partner-fb-extension-explain-title">
                  Try these features now.<br/>
                  We provide these functions.
                </p>
                <div className="partner-fb-extension-explain-description">
                  <ApiExplainView
                    link="https://docs.mojitok.com/#8a826cde-ed2d-414e-80bc-cbc0fdf77da8"
                    icon={require('../img/header_icon/discover.svg')}
                    title="Smart Search API"
                    titleEmoji="🤖"
                    description={[
                      {title:'Stickers', explain: 'recommends the stickers that fit the text.'},
                      {title:'Packages', explain: 'find the bundle of stickers by the name of package or creator.'}
                    ]}
                    />
                  <ApiExplainView
                    link="https://docs.mojitok.com/#c80036d8-6411-4437-9658-af032d369408"
                    icon={require('../img/header_icon/tag.svg')}
                    title="Tagged Stickers API"
                    titleEmoji="🔖"
                    description="categorized stickers under 20 emotions."
                    />
                  <ApiExplainView
                    link="https://docs.mojitok.com/#0ce5ddb4-7bd8-4cdd-aaff-3f5cec37b7e9"
                    icon={require('../img/header_icon/trending.svg')}
                    title="Trending Stickers API"
                    titleEmoji="📈"
                    description="provides the most trendy stickers."
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}//end PartnerProviceFunc class

const isAvailableBrowser = () => {
  // 브라우저 및 버전을 구하기 위한 변수들.
  var agent = navigator.userAgent.toLowerCase();

  //IE 10이하 버전일 경우
  if(agent.indexOf("msie") !== -1) {
    return false;
  } else{
    return true;
  }

}

export default PartnerProviceFunc;
