import MJTContent from '../../Core/MJTContent'

function returnAPICall(type: string) {
  switch (type) {
    case 'TRENDING':
      return MJTContent.getInstance().getTrendingStickers()
    case 'EMOTION_TAG':
      return MJTContent.getInstance().getEmotionTag2Stickers()
    case 'SEARCH':
      return MJTContent.getInstance().getTop20KeywordTags()
    case 'OWNERSHIP':
      return MJTContent.getInstance().getOwnership()
    default:
      break
  }
}

export default async function callAPI(type: string) {
  try {
    let resData = await returnAPICall(type)

    return resData
  } catch (err) {
    //TODO: 에러 발생 시 view단 처리 필요
    throw err
  }
}
