import React from "react";
import { makeStyles } from "@material-ui/core";

import "../../styles/SDKDemo.css";
import logoMojitok from "../../img/logo2021.png";
import faceBookIcon from "../../img/header_icon/facebook_white.png";
import instagramIcon from "../../img/header_icon/instagram_white.png";
import linkedInIcon from "../../img/header_icon/linkedin_white.png";
import SearchIcon from "./icons/SearchIcon";


const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    marginLeft:'10vw',
    width:'80vw',
    height:'100px',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    marginRight:'10vw',
    [theme.breakpoints.down('xs')]: {
      height:'56px',
    justifyContent:'start',

    },
  },
  mainHeaderText:{
    fontFamily:'Gmarket Sans TTF',
    lineHeight:'1.714em',
    color:'#233653',
    fontWeight:700,
    marginLeft:'34px',
    fontSize:'1.286em',
    [theme.breakpoints.down('xs')]: {
      fontSize:'1em',
    },
  },
  mojitokLogo: {
    height: "52px",
    width:'189px',
    [theme.breakpoints.down('xs')]: {
      width:'100px',
      height:'auto'
    },
  },
  headerText:{
    marginLeft:'10px',
    fontWeight:500,
    fontSize:'1.142em',
    // fontSize:'16px',
    fontFamily:'Gmarket Sans TTF',
    lineHeight:'1.714em',
    color:'#233653',
    [theme.breakpoints.down('xs')]: {
      display:'none'
    },
  },
  active:{
    borderBottom:'1px solid #233653'
  },
  activeInnerText:{
    borderBottom:'1px solid #3278FF',
    color:'#3278FF'
  },
  iconContainer:{
    display:'flex',
    '& a':{
      display:'block',
      marginLeft:'28px',
    },
    '& img':{
      width:'36px'
    },
    [theme.breakpoints.down('xs')]: {
      display:'none'
    },
  }
}));

function Header({tab,setTab}) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headerWrapper}>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <a href="https://mojitok.com/#home" target="_blank">
          <img className={classes.mojitokLogo} src={logoMojitok}></img>
        </a>
        <div className={classes.mainHeaderText} style={{}}>Product Demo</div>
        </div>
        
        <div style={{cursor:"pointer"}} className={classes.headerText} onClick={()=>{setTab('main')}}>
          <span className={tab==='main'?classes.active:''}>mojitok Sticker </span>
          <span style={{color:'#3278FF'}}className={tab==='main'?classes.activeInnerText:''}>Store</span>
        </div>
        <div style={{cursor:"pointer"}} className={classes.headerText} onClick={()=>{setTab('cate')}}>
          <span className={tab!=='main'?classes.active:''}>mojitok Sticker </span>
          <span style={{color:'#3278FF'}}className={tab!=='main'?classes.activeInnerText:''}>Stream</span>
        </div>
        <div className={classes.iconContainer}>
        <a
            underline="none"
            target="_blank"
            href="https://www.facebook.com/withMojitok/">
            <img
              src={faceBookIcon}
              alt="facebook icon"
            ></img>
          </a>
          <a
            underline="none"
            target="_blank"
            href="https://www.instagram.com/mojitok_official/ "
          >
            <img
              src={instagramIcon}
              alt="instagram icon"
            ></img>
          </a>
          <a
            underline="none"
            target="_blank"
            href="https://www.linkedin.com/company/mojitok-inc/"
          >
            <img
              src={linkedInIcon}
              alt="linkdin icon"
            ></img>
          </a>

        </div>

      </div>
    </>
  );
}

export default Header;
