import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import '../styles/Header.css'

class Header extends Component {

  constructor(props){
    super(props);
    this.state={
    };
  }

  render() {
    //링크 주소 받아오기
    const link = window.location.pathname;

    return (
      <div className="header-box">
          <NavLink to={'/demo/discover'} className="header-link-wapper">
            <img
              src={this.determineIconName('discover', link.startsWith('/demo/discover'))}
              alt="discover page"
              className="header-link"
            />
          </NavLink>
          <NavLink to={'/demo/taglist'} className="header-link-wapper">
            <img
              src={this.determineIconName('tag', link.startsWith('/demo/tag'))}
              alt="tag page"
              className="header-link"
            />
          </NavLink>
          <NavLink to={'/demo/trending'} className="header-link-wapper">
            <img
              src={this.determineIconName('trending', link.startsWith('/demo/trending'))}
              alt="trending page"
              className="header-link"
            />
          </NavLink>
      </div>
    );
  }

  determineIconName = (iconName, isActive) => {
    const selectedIconName = isActive ? `${iconName}-active.svg` : `${iconName}-unselected.svg`;
    return require(`../img/header_icon/${selectedIconName}`);
  }
}

export default Header;
