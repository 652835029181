import React from 'react'
import { makeStyles } from '@material-ui/core';
import { Provider } from "react-redux";

import StoreView from "../../StoreView";
import { makeStore } from '../../redux/store';
import { PHONE_VIEW_WIDTH } from '../../constantValue';

const useStyles = makeStyles((theme) => ({
    sdkViewWrapper:{
        borderRadius:'28px',
        backgroundColor:'white',
        position:'absolute',
        width:'100%',
        height:'100%',
        overflowY:'scroll',
        zIndex:99,
    },
}))

function StoreViewInPhone(){
    const classes = useStyles()

    return  <Provider store={makeStore()}>
    <div className={classes.sdkViewWrapper}>
        <div className='store-view-wrapper' style={{position:'relative',width:'100%'}}>
            <StoreView startTab={1}/> 
        </div>
    </div>
</Provider>
}
export  default React.memo(StoreViewInPhone)