import React from "react";
import { MyHistory } from "../redux/history/historySlice";
import { useHistory } from "./hooks";


interface RouteProp{
    path:string
    // component:()=>JSX.Element
    exact?:boolean|undefined
    render:()=>JSX.Element
}

export default function Route({exact ,path,render:Component}:RouteProp){
    const history = useHistory()
    if(exact && path===history.location.pathname){
        return <Component/>
    }   
    if(!exact){
        const currentPaths = history.location.pathname.split('/')
        const routePaths = (path.split('/'))
        // currentPaths.splice(0,1)
        for(let i=0;i<routePaths.length;++i){
            if(routePaths[i]!==currentPaths[i]){
                return <></>
            }
        }
        return <Component/> 
    }

    return <></>

}