import React, { useEffect, useState } from "react";
import {
  AppBar,
  IconButton,
  makeStyles,
  Input,
  Toolbar,
  Typography,
  Snackbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import { Search } from "@material-ui/icons/";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

import { useHistory, useLocation } from "../../../myRouter";
import GreyCancle from "../../img/grey_cancle.svg";

import MyDrawer from '../MyDrawer'
import WrapAbsolute from "../WrapAbsolute";
export interface HeaderProps {
  path: string;
  isSupport: boolean;
  isPC: boolean;
  onlyCategory:boolean;
  onClose: () => void;
}

interface SearchLocationState {
  query: string;
  language: string;
  catalogue_category?: string;
}

const useStyles = makeStyles((theme) => ({
  appbarWrapper:{
    position:'absolute',
    width:'100%',
    height:'100%'
  },
  toolbar: {
    minHeight: "56px",
    height: "56px",
    padding: 0,
  },
  titleLine: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  title: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    marginLeft: "32px",
    ...theme.typography.h6,
  },
  backButton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
  },
  menubutton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
    width: "24px",
    height: "24px",
    marginLeft: "16px",
  },
  searchMenuButton: {
    padding: 0,
    color: theme.greyscaleColorTheme.grey2.main,
    width: "24px",
    height: "24px",
    marginLeft: "16px",
  },
  searchBox: {
    marginLeft: "auto",
  },
  searchbutton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
    marginRight: "24px",
  },
  closebutton: {
    padding: 0,
    color: theme.greyscaleColorTheme.darkGrey.main,
    width: "24px",
    height: "24px",
    marginRight: "16px",
  },
  searchTextField: {
    marginLeft: "20px",
    width: "100%",
    margin: "auto",
  },
  searchResultBox: {
    display: "flex",
  },
  searchGoBackTextButton: {
    color: theme.greyscaleColorTheme.darkGrey.main,
    marginRight: "16px",
  },
  categoryTitle: {
    marginLeft: "20px",
    width: "100%",
    margin: "auto",
  },
}));

function Header({ path, isSupport, isPC, onClose,onlyCategory }: HeaderProps) {
  const classes = useStyles();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [query, setQuery] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('store');

  const getTitle = (path: string, isPC: boolean) => {
    if (isPC) {
      return "STORE";
    } else {
      switch (path) {
        case "/mypacks":
          return t("nav_drawer_menu_my_stickers");
        case "/pack":
          return "";
        case "/product-info":
          return "";
        case "/refund-info":
          return "";
        case "/payment-fail":
          return "";
        default:
          return t("home_title_store");
      }
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      if (query.length === 0) {
        handleClick();
      } else {
        setQuery(query);
        history.replace({
          pathname: "/packs/search",
          state: { query: query, lang: "en" },
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<any>) => {
    setQuery(event.target.value);
  };

  const openDrawer = () => {
    setIsDrawerOpened(true);
  };

  const onDrawerClose = () => {
    setIsDrawerOpened(false);
  };

  const onBack = () => {
    setQuery("");
    if(history.length>1){
      history.goBack();
    }
  };
  const onClear = () => {
    setQuery("");
  };

  useEffect(() => {
    if (path === "/packs/search") {
      if (location.state && location.state.query) {
        setQuery(location.state.query);
      }
    } else if (path === "/packs/catalogue-category") {
      if (location.state && location.state["catalogue_category"]) {
        setQuery(location.state["catalogue_category"]);
      }
    }
  }, [location]);

  function searchToolbar() {
    return (
      <div className={classes.titleLine}>
        {path === "/search" ? (
          <IconButton className={classes.menubutton} onClick={onBack}>
            <ArrowBack />
          </IconButton>
        ) : (
          <IconButton className={classes.searchMenuButton}>
            <Search />
          </IconButton>
        )}
        <Input
          className={classes.searchTextField}
          value={query}
          placeholder={t("searching_by_pack_or_creator")}
          disableUnderline={true}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        ></Input>
        <div className={classes.searchResultBox}>
          {query.length > 0 ? (
            <IconButton className={classes.closebutton} onClick={onClear}>
              <img src={GreyCancle} alt={"GreyCancle"} />
            </IconButton>
          ) : null}
          <Typography
            className={classes.searchGoBackTextButton}
            variant="body1"
            onClick={onBack}
          >
            {t("searching_close")}
          </Typography>
        </div>
      </div>
    );
  }
  function categoryToolbar({onlyCategory}:{onlyCategory:boolean}) {
    return (
      <div className={classes.titleLine}>
        <IconButton className={classes.menubutton} onClick={onBack}>
          <ArrowBack />
        </IconButton>
        <Typography className={classes.categoryTitle} variant="body1">
          {query}
        </Typography>
        {!onlyCategory &&<IconButton className={classes.closebutton} onClick={onClose}>
          <CloseIcon />
        </IconButton>}
      </div>
    );
  }

  return (
    <>
    <WrapAbsolute>

      <AppBar style={{
        position:'sticky',
        pointerEvents:'auto'
      }}>
        {path === "/search" || path === "/packs/search" ? (
          <Toolbar className={classes.toolbar}>{searchToolbar()}</Toolbar>
        ) : path === "/packs/catalogue-category" ? (
          <Toolbar className={classes.toolbar}>{categoryToolbar({onlyCategory})}</Toolbar>
        ) : (
          <Toolbar className={classes.toolbar}>
            {isSupport ? (
              <div className={classes.titleLine}>
                {!onlyCategory && path === "/" ? (
                  <IconButton
                    className={classes.menubutton}
                    onClick={openDrawer}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : (
                  <IconButton className={classes.menubutton} onClick={onBack}>
                    <ArrowBack />
                  </IconButton>
                )}
                <Typography variant="h2" className={classes.title}>
                  {!onlyCategory && getTitle(path, isPC)}
                </Typography>
                <div className={classes.searchBox}>
                  {!onlyCategory &&( isPC || path === "/") ? (
                    <IconButton
                      className={classes.searchbutton}
                      onClick={() => history.push("/search")}
                    >
                      <Search />
                    </IconButton>
                  ) : null}
                     {!onlyCategory && !isPC &&
                    (RegExp("-info").test(path) || path === "/payment-fail") !==
                      true && (
                      <IconButton
                        className={classes.closebutton}
                        onClick={onClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                </div>
              </div>
            ) : (
              <div className={classes.titleLine}>
                {!onlyCategory && !isPC && (
                  <IconButton className={classes.closebutton} onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            )}
          </Toolbar>
        )}
        <MyDrawer  open={isDrawerOpened} onClose={onDrawerClose} />
      </AppBar>
    
    </WrapAbsolute>
   
      <Toolbar />
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert icon={false} severity="error">
          Try searching a keyword!
        </Alert>
      </Snackbar>
    </>
    
  );
}

export default Header;
