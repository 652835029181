//pkgUI 보여주는 컴포넌트
import React from 'react';
import packBg from '../img/pack_background.svg';

const imgStyle = {
  backgroundImage: `url(${packBg})`,
  objectFit: 'contain',
  backgroundSize: 'cover',
  padding: 15,
  width: '100%',
  maxWidth:160,
  margin:'auto'
}

const boxStyle = {
  maxWidth:160,
  margin: 'auto',
  display:'inline-flex',
  justifyContent : 'center',
  alignItems:'center'
}


const  PkgView = (props) => {

  const { thumbnail, name, creator } = props.pkgInfo;
  const { showPkgStickerFunc } = props;

  return(
    <div style={{display:'inline-flex', margin:'0px 2.5%', width:'45%'}}>
      <div onClick={showPkgStickerFunc} style={boxStyle}>
        <div style={{display:'inline-block'}}>
          <img
            src={require('../img/pack_tag.svg')}
            alt="package_tag"
            style={{width:'100%', maxWidth: 160, position: 'relative', top: 22, margin:'auto' }}
          />
          <img
            src={thumbnail}
            alt="package_image"
            style={imgStyle}
          />
          <p style={{color: '#000000', marginTop:11, marginBottom:0}}>{name}</p>
          <p style={{color: '#bababa'}}>{creator}</p>
        </div>
      </div>
    </div>
  );

}

export default PkgView
