import React from 'react';
import '../styles/Partner.css';

const ApiExplainView = (props) => (
  <a href={props.link} target="_blank" rel="noopener noreferrer">
    <div className="partner-description-wapper">
      <div className="partner-description-subtitle">
        <img src={props.icon} style={{ marginRight: "2.3%"}} alt="icon"/>
        <div style={{display: 'inline-block', width:'100%'}}>
          <div>
          <span style={{fontWeight: 'bold'}}>{props.title}</span>
          <span role="img" aria-label={props.titleEmoji}>{props.titleEmoji}</span>
          <span className="view-docs-link" style={{fontSize:"12px", marginTop: 5}}>view docs</span>
          </div>
          {
            Array.isArray(props.description)?
            props.description.map((val, idx) =>
              <div key={idx} style={{fontSize: 18}}>
                <p style={{marginTop:15, marginBottom:0, fontWeight:'bold'}}>{val.title}</p>
                <p className="partner-description-explain">{val.explain}</p>
              </div>
            )
            :<p className="partner-description-explain">{props.description}</p>
          }
        </div>
      </div>
    </div>
  </a>
)

export default ApiExplainView;
