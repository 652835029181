
import { configureStore } from '@reduxjs/toolkit'
import historySlice from './history/historySlice'
// ...

export const store = configureStore({
  reducer: {
    history:historySlice
  },
})
export const makeStore = ()=>(configureStore({
  reducer: {
    history:historySlice
  },
}))
// type St = ReturnType<typeof makeStore>
// type X = ReturnType<typeof St.getState>
// // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
// ReactDOM.render(<Provider store={store}><TestCom /></Provider>, document.getElementById('root'));

