import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  makeStyles, Typography,
} from "@material-ui/core";

import { Link } from "../../myRouter";
import { PHONE_VIEW_HEIGHT } from "../../constantValue";

export interface MyDrawerProp {
    open:boolean;
    onClose: () => void;
}
const useStyles = makeStyles((theme)=>({
    modalWrpper:{
        width:'100%',
        position:'absolute',
        top:'0px',
        backgroundColor:'rgba(0,0,0,1)',
        zIndex:99998,
        WebkitTransition:'0.5s background-color',

    },
    leftBar:{
        position:'absolute',
        top:'0px',
        width:'0px',
        backgroundColor:'white',
        zIndex:99999,
        transtion:'width 0.5s',
        WebkitTransition:'width 0.5s',
    },
    category: {
        marginTop: "29px",
        marginLeft: "24px",
      },
      categoryTitle: {
        color: theme.greyscaleColorTheme.grey2.main,
        display: "none",
      },
      categoryItemTitle: {
        marginTop: "18px",
        color: theme.greyscaleColorTheme.darkGrey.main,
        ...theme.typography.body1,
      },
      divider: {
        marginTop: "13px",
        backgroundColor: theme.greyscaleColorTheme.lightGrey3.main,
        height: "0.5px",
      },
}))
const LeftBarWidthPercentage = '40%'
const MyModalSideBarId = 'myModalSideBar'
const MyModalBackId = 'myModalBack'
function MyDrawer({open,onClose}:MyDrawerProp){
    const [readyToClose,setReadyToClose] = useState<null|boolean>(null)
    const [leftBarWidth,setLeftBarWidth] = useState('0%')
    const [backgroundOpacity,setBackgroundOpacity] = useState('rgba(0,0,0,1)')
  const ref = useRef<HTMLDivElement|null>(null)
  const classes = useStyles();
  const handleModalClick = useCallback((e:Event)=>{
    const el = e.target as HTMLElement
    if(el.id === MyModalSideBarId){
      return
    }
      onClose()
    return
  },[])
  const blockWheel = useCallback((e:Event)=>{
    e.preventDefault()
  },[])

  const { t } = useTranslation('store');
    useEffect(()=>{
        if(open){
            if(ref.current){
                let motherCom = ref.current.closest('.uikit-inner-wrapper')
                if(!motherCom){
                  motherCom = ref.current.closest('.store-view-wrapper')
                }
                motherCom?.addEventListener('click',handleModalClick)
                motherCom?.addEventListener('wheel',blockWheel)
            }
            setLeftBarWidth(LeftBarWidthPercentage)
            setBackgroundOpacity('rgba(0,0,0,0.5)')
             
        }else{
            if(ref.current){
              let motherCom = ref.current.closest('.uikit-inner-wrapper')
              if(!motherCom){
                motherCom = ref.current.closest('.store-view-wrapper')
              }
                motherCom?.removeEventListener('click',handleModalClick)
                motherCom?.removeEventListener('wheel',blockWheel)
            }
   
        }
    },[open])
  useLayoutEffect(()=>{
    if(open){

    }
    if(!open){
      if(readyToClose!==null){
        setReadyToClose(true)
      }else{
        setReadyToClose(false)
      }
      setLeftBarWidth('0')
      setBackgroundOpacity('rgba(0,0,0,0)')
    }
  },[open])
  return (<div id={MyModalBackId} 
    onTransitionEnd={(e)=>{
      if(readyToClose){
        setReadyToClose(false)
      }
    }}
    style={{
      display:(open||readyToClose) ?'block':'none',
      backgroundColor:backgroundOpacity,
      height:PHONE_VIEW_HEIGHT,
      }} 
      className={classes.modalWrpper} ref={ref}>
      <div style={{
        width:leftBarWidth,
        height:PHONE_VIEW_HEIGHT,
      }} id={MyModalSideBarId} className={classes.leftBar}>
      <div className={classes.category}>
        <Typography
          className={classes.categoryTitle}
          variant="subtitle1"
          noWrap
        >
          {t("nav_drawer_menu_my_page")}
        </Typography>
        <Link
          to="/mypacks"
          style={{ textDecoration: "none" }}
        >
          <Typography className={classes.categoryItemTitle} variant="h6" noWrap>
            {t("nav_drawer_menu_my_stickers")}
          </Typography>
        </Link>
      </div>
     
     
      </div>
  </div>)
}
export default MyDrawer