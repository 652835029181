import React, { Component } from 'react';
import StickerListView  from '../../components/StickerListView';
import callGalaxyAPI from '../../utils/callGalaxyAPI';

import '../../styles/Trending.css';

class Popular extends Component {
  constructor(props){
    super(props);
    this.state={
      populars:[]
    };
  }
  async componentDidMount(){
    //popular api 가져오기
    const populars=await callGalaxyAPI('/popular');
    this.setState({populars:populars.data})

    return Promise.resolve();
  }
  render() {
    return (
      <div>
        {
          this.state.populars.length > 0 ?
            this.state.populars.map((list, index)=>{
                return(
                  <div className="trending-box" key={index}>
                    <p className="trending-title">{list.title}</p>
                    <div className="trending-sticker-box">
                      {
                        list.stickers.map((item, index)=>{
                          const changeUrl = item.c.replace("http://", "https://");
                          return(
                            <StickerListView
                              imageUrl={changeUrl}
                              stickerId = {item.id}
                              key={index}
                              />
                          );
                        })
                      }
                    </div>
                  </div>
                )
            })
            :null
        }
      </div>
    );
  }
}


export default Popular;
