import React, { Component } from 'react';
import StickerListView  from '../../../components/StickerListView';
import callGalaxyAPI from '../../../utils/callGalaxyAPI';

class Tags extends Component {

  constructor(props){
    super(props);
    this.state={
      findTagStickerList:[]
    };
  }
  async componentDidMount(){

    const tagName = this.props.match.params.name;
    const findTagStickerList=await callGalaxyAPI(`/tags/${tagName}?include=stickers`);
    this.setState({findTagStickerList:findTagStickerList.data});

    return Promise.resolve();
  }

  render() {
    return (
      <div style={{width:'90%', margin:'5%'}}>
        <p>II-2. Tag with Stickers</p>
        {
            this.state.findTagStickerList.map((item, index)=>{
              const changeUrl = item.c.replace("http://", "https://");
              return(
                <StickerListView
                  imageUrl={changeUrl}
                  stickerId = {item.id}
                  key={index}
                  />
              );
            })
        }
      </div>
    );
  }

}//tag component end

export default Tags;
