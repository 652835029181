import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import StickersView from './StickersView'
import { MJTOnStickerChoice, MJTViewType } from '../../types'
import MJTTag2Stickers from '../../Core/model/outter/MJTTag2Stickers'

// TODO: Material UI 의 makeStyles 를 사용하도록 수정
import styles from '../styles.module.css'

const emotionTabData = [
  '😍 Loving',
  '😄 Happy',
  '🙏 Thanks',
  '😭 Sad',
  '😆 Excited',
  '😢 Sorry',
  '☺️ Shy',
  '🤣 LOL',
  '😮 Wow',
  '👌 OK',
  '😦 Embarrassed',
  '👋 Hi',
  '👍 Good',
  '😴 Sleepy',
  '🎂 Birthday',
  '😡 Angry',
  '😨 Scared',
  '🚫 No',
  '🍔 Hungry',
  '🤔 Curious'
]

const StyledTabs = withStyles({
  root: {
    height: '36px',
    alignItems: 'center',
    background: '#fff',
    minHeight: 36,
    boxShadow: 'inset 0px -0.5px 0px #E5E5E5'
  },
  flexContainer: {
    justifyContent: 'space-between'
  },
  indicator: {
    height: '2px'
  },
  scrollButtons: {
    width: '16px'
  },
  scrollable: {
    height: 'inherit',
    display: 'flex',
    alignItems: 'center'
  }
})(Tabs)

const StyledTab = withStyles({
  root: {
    minWidth: 0
  }
})(Tab)

export interface EmotionTagViewProps {
  emotionTags: MJTTag2Stickers[]
  onStickerChoice: MJTOnStickerChoice
}

export default function EmotionTagView({ emotionTags, onStickerChoice }: EmotionTagViewProps) {
  const [selectedTag, setSelectedTag] = useState('😍 Loving')
  const [stickers, setStickers] = useState(
    emotionTags.find((data) => data.tag === 'LOVING')?.stickers
  )

  useEffect(() => {
    const stickerData = emotionTags.find((tagData) =>
      selectedTag.toUpperCase().includes(tagData.tag)
    )

    if (!stickerData) {
      //TODO: 에러 처리
    } else {
      setStickers(stickerData.stickers)
    }
  }, [selectedTag])

  return (
    <div className={styles['emotion-tag-tab']}>
      <AppBar position='absolute' color='default'>
        <StyledTabs
          value={selectedTag}
          onChange={(e, newValue) => setSelectedTag(newValue)}
          variant='scrollable'
          indicatorColor='primary'
          textColor='primary'
        >
          {emotionTabData.map((tab, idx) => (
            <StyledTab label={tab} value={tab} key={tab} />
          ))}
        </StyledTabs>
      </AppBar>
      <div style={{ marginTop: 34 }}>
        <StickersView
          stickers={stickers!}
          onStickerChoice={onStickerChoice}
          viewType={MJTViewType.EMOTION_TAG}
        />
      </div>
    </div>
  )
}
