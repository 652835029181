import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Header from './Header';
import Trending from './Trending';
import Tag from './Tag';
import Discover from './Discover';

import '../App.css';


class Demo extends Component {

  constructor(props){
    super(props);
    this.state={
      featured:[]
    };
  }

  render() {
    return (
      <Router>
        <div style={{height: 'inherit'}}>
          <Header/>
          <Switch>
            <Redirect exact from="/demo/" to="/demo/discover/stickers"/>
            <Route path="/demo/trending" component={Trending} />
            <Route path="/demo/taglist" component={Tag} />
            <Route path="/demo/discover" component={Discover} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Demo;
