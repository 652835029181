import React from 'react'
import { MyHistory } from '../redux/history/historySlice'
import { useHistory } from './hooks'

interface LinkProp{
    to:string|MyHistory
    onClick?:()=>void
    style?:{}
    children:string|JSX.Element|JSX.Element[]
}
export default function Link({to,onClick,style,children}:LinkProp){
    const history = useHistory()
    const handleClick = ()=>{
        if(onClick){
            onClick()
        }
        history.push(to)
    }
    return <a style={style&&style} onClick={handleClick}>{children}</a>
}