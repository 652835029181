import React, { Component } from 'react';
import { Spin } from 'antd';

class StickerListView extends Component{

  constructor(props){
    super(props);
    this.state={
      loading :false
    };
  }

  render(){
    const { imageUrl } = this.props;
    return(
      <span>
      {
        this.state.loading?
        <span>
            <img
              src={imageUrl}
              alt="sticker_image"
              style={{width:'28.5%', margin:'2.4%'}}/>
              <span style={{
                width: '100%',
                minHeight: '100%',
                background: 'rgba(100,100,100,0.2)',
                position: 'fixed',
                left:'0',
                top:'0'
              }}/>
              <Spin tip="Loading..." spinning={this.state.loading} style={{position:'fixed', top:'45%', left:'40%'}} />
        </span>:
        <img
          src={imageUrl}
          alt="sticker_image"
          style={{width:'28.5%', margin:'2.4%'}}
          />
      }
      </span>
    );
  }


}

export default StickerListView
