import crypto from 'crypto-js';
import MJTUserToken, { IMJTUserToken } from './model/outter/MJTUserToken';
import MJTException from './model/error/MJTException';
import Response from './model/outter/Response';
import MJTContext from './MJTContext';
import MJTUserAccessTokenPayload from './model/inner/MJTUserAccessTokenPayload';
import request, { HttpOptions, HTTP_METHOD } from './utils/https';
import decodeUrlSafeBase64 from './utils/decodeUrlSafeBase64';

export type MJTReplaceTokenListener = () => void;

export default class MJTAuth {
  private static instance: MJTAuth;
  static localStorageTokenKeyPrefix: string = 'MJTUserToken';
  private _replaceTokenListener: MJTReplaceTokenListener = () => { };

  set replaceTokenListener(listener: MJTReplaceTokenListener) {
    this._replaceTokenListener = listener;
  }

  private localStorageTokenKey: string = '';

  private constructor() { }

  public static getInstance(): MJTAuth {
    if (!MJTAuth.instance) {
      MJTAuth.instance = new MJTAuth();
    }

    return MJTAuth.instance;
  }

  static getParsedPayload(jwt: string): MJTUserAccessTokenPayload {
    const tokenParts = jwt.split('.');
    const tokenPayloadPart = tokenParts[1];
    const parsedTokenPayloadPart = decodeUrlSafeBase64(tokenPayloadPart);
    const tokenPayload: MJTUserAccessTokenPayload = JSON.parse(parsedTokenPayloadPart);

    return tokenPayload;
  }

  static isExpiredAccessToken(accessToken: string): boolean {
    const tokenPayload: MJTUserAccessTokenPayload = MJTAuth.getParsedPayload(accessToken);
    const currentUnixTimeMs = Date.now();
    const tokenExpiredUnixTimeMs = tokenPayload.exp * 1000;

    return tokenExpiredUnixTimeMs <= 0 || tokenExpiredUnixTimeMs <= currentUnixTimeMs;
  }

  async getValidAccessToken(): Promise<string> {
    const localToken = this.loadTokenFromLocalStorage();
    if (localToken) {
      if (localToken.mojitokUserAccessToken && localToken.mojitokUserRefreshToken) {
        if (MJTAuth.isExpiredAccessToken(localToken.mojitokUserAccessToken) === false) {
          return localToken.mojitokUserAccessToken;
        }
      }
    }

    let newToken: MJTUserToken;

    try {
      newToken = await this.issueUserToken();
    } catch (err) {
      throw err;
    }
    if (newToken.mojitokUserAccessToken === "" || newToken.mojitokUserRefreshToken === "") {
      throw new MJTException(4011, "Invalid UserToken")
    }
    this.saveTokenToLocalStorage(newToken);

    return newToken.mojitokUserAccessToken;
  }

  clearUserToken() {
    this.deleteTokenFromLocalStorage();
  }

  private async issueUserToken(): Promise<MJTUserToken> {
    let response: Response;

    try {
      const headers = new Headers();
      headers.append("Mojitok-Application-Id", MJTContext.getInstance().applicationId);
      headers.append("Mojitok-Application-Token", MJTContext.getInstance().applicationToken);

      const bodyInfo = {
        "User-Identifier": MJTContext.getInstance().userId,
      }
      const options: HttpOptions = {
        body: bodyInfo
      }
      response = await request(`${MJTContext.getInstance().baseUrl}/auth-tokens/issue`,
        HTTP_METHOD.POST,
        headers,
        options
      );
    } catch (e) {
      if (window.navigator.onLine === false) {
        throw new MJTException(4008, "Network connection failed.")
      }
      if(e instanceof MJTException){
        throw e
      }else{
        throw new MJTException(4100, e.message ? e.message : "Failed to Call API");
      }
    }

    const { status, payload } = response;

    switch (true) {
      case (status == 201):
        const receivedMojitokUserToken: MJTUserToken =
          new MJTUserToken(payload["Mojitok-User-Access-Token"], payload["Mojitok-User-Refresh-Token"]);
        return receivedMojitokUserToken;
      case (status == 401):
        throw new MJTException(4001, "Invalid MojitokApplicationToken or Invalid MojitokApplicationID");
      case (status == 403):
        throw new MJTException(4003, "MojitokApplicationToken has expired.");
      case (status >= 400 && status <= 499):
        throw new MJTException(4101, payload.data.message);
      case (status >= 500 && status <= 599):
        throw new MJTException(5000, payload.data.message);
      default:
        throw new MJTException(9000, payload.data.message);
    }
  }

  private loadTokenFromLocalStorage(): MJTUserToken | undefined {
    const userTokenStr = localStorage.getItem(this.getLocalStorageTokenKey());
    if (!userTokenStr) {
      return undefined;
    }

    const userToken: IMJTUserToken = JSON.parse(userTokenStr);
    return new MJTUserToken(userToken._mojitokUserAccessToken, userToken._mojitokUserRefreshToken);
  }

  private deleteTokenFromLocalStorage(): void {
    localStorage.removeItem(this.localStorageTokenKey);
  }

  private saveTokenToLocalStorage(token: MJTUserToken) {
    this.localStorageTokenKey = this.getLocalStorageTokenKey();

    // 다른 로컬스토리지 키들은 지운다.
    for (let idx = localStorage.length - 1; idx >= 0; idx--) {
      const localStorageKey = localStorage.key(idx);
      if (localStorageKey?.startsWith(MJTAuth.localStorageTokenKeyPrefix) && localStorageKey !== this.localStorageTokenKey) {
        localStorage.removeItem(localStorage.key(idx) ?? '');
      }
    }

    const jsonStr = JSON.stringify(token);
    localStorage.setItem(this.localStorageTokenKey, jsonStr);
  }

  private getLocalStorageTokenKey() {
    const tokenKeyPostfix = crypto.enc.Hex.stringify(
      crypto.MD5(
        (MJTContext.getInstance().applicationId ?? '')
        + (MJTContext.getInstance().userId ?? '')
      )
    ).slice(0, 9);

    return MJTAuth.localStorageTokenKeyPrefix + tokenKeyPostfix;
  }
}

export enum MJTAppTokenState {
  LOADING = 0,
  SUCCESS = 1,
  FAIL = 2
}
